function getSelectedIds(scope = null) {
  scope = scope ? document.querySelector(scope) : document
  const selections = scope.querySelectorAll(".bulk-select:checked")
  return [...selections].map(selection => selection.dataset.id).join(", ")
}

function getSelectedIdsCount(scope = null) {
  scope = scope ? document.querySelector(scope) : document
  const selections = scope.querySelectorAll(".bulk-select:checked")
  return selections.length
}

function getSelectedRows(scope = null) {
  scope = scope ? document.querySelector(scope) : document
  const selections = scope.querySelectorAll(".bulk-select:checked")
  return [...selections].map(selection => selection.closest("tr"))
}

export {
  getSelectedIds,
  getSelectedIdsCount,
  getSelectedRows
}
