import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectButton",
    "checkbox",
    "unselectedText",
    "selectedText",
  ]

  static values = {
    product: String,
  }

  toggleProduct() {
    const panel = this.element
    const checkbox = this.checkboxTarget
    const selectButton = this.selectButtonTarget
    const unselectedText = this.unselectedTextTarget
    const selectedText = this.selectedTextTarget

    if(checkbox.disabled) {
      return
    }

    checkbox.checked = !checkbox.checked

    if (checkbox.checked) {
      panel.classList.add("selected-panel")
      selectButton.classList.add("selected-button")

      unselectedText.hidden = true
      selectedText.hidden = false
    } else {
      panel.classList.remove("selected-panel")
      selectButton.classList.remove("selected-button")

      selectedText.hidden = true
      unselectedText.hidden = false
    }

    this.dispatch("productToggled", { detail: { product: this.productValue } })
  }
}
