import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unloadWarning", "unsavedChanges"]

  preventUnload = false
  forcePreventUnload = false

  connect() {
    this.beforeUnloadHandler = this.beforeUnload.bind(this)
    window.addEventListener("beforeunload", this.beforeUnloadHandler)

    if (this.hasUnloadWarningTarget && ("unsavedChanges" in this.unloadWarningTarget.dataset)) {
      this.disallowUnload()
    }
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler)
  }

  confirmIfChanges() {
    if (this.preventUnload) {
      const confirmation = confirm("Are you sure you want to leave? You will lose unsaved changes.")
      if (!confirmation) {
        event.preventDefault()
      }
    }
  }

  allowUnload() {
    if (this.forcePreventUnload === true) {
      return
    }

    if (this.hasUnloadWarningTarget) {
      this.unloadWarningTarget.classList.add("hide")
    }
    this.preventUnload = false
  }

  disallowUnload() {
    if (this.hasUnloadWarningTarget) {
      this.unloadWarningTarget.classList.remove("hide")
    }
    this.preventUnload = true
  }

  persistDisallowUnload() {
    this.forcePreventUnload = this.preventUnload
  }

  beforeUnload(event) {
    if (this.preventUnload === true || this.forcePreventUnload === true) {
      event.preventDefault()
    }
  }
}
