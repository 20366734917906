import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["arguments", "argumentsContainer", "subcommands"]

  connect() {
    super.connect()

    this.populateSubcommandDropdown()

    $(this.subcommandsTarget).select2({ width: "100%" })
    $(this.subcommandsTarget).on("select2:select select2:unselect", e => { this.handleSubcommandSelected(e) })
  }

  populateSubcommandDropdown() {
    this.subcommandsByInvocation = {}
    const topLevelSubcommands = this.createSubcommandOptions(this.subcommands)

    topLevelSubcommands.forEach(subcommand => {
      this.subcommandsTarget.add(subcommand)
    })

    const selectedSubcommand = this.subcommandsByInvocation[this.subcommandsTarget.selectedOptions[0].value]
    this.setArgumentsForSubcommand(selectedSubcommand)
  }

  createSubcommandOptions(subcommands) {
    return subcommands.map(subcommand => {
      if (subcommand.children != null) {
        return this.createOptionGroup(subcommand)
      } else {
        this.registerSubcommand(subcommand)

        const fullTitle = `${subcommand.title} — mdatp ${subcommand.subcommand}`
        return new Option(fullTitle, subcommand.subcommand)
      }
    })
  }

  createOptionGroup(section) {
    const optionGroup = document.createElement("optgroup")
    optionGroup.label = section.title
    optionGroup.append(...this.createSubcommandOptions(section.children))

    return optionGroup
  }

  handleSubcommandSelected(e) {
    this.setArgumentsForSubcommand(this.subcommandsByInvocation[e.target.value])
  }

  setArgumentsForSubcommand(subcommand) {
    const container = this.argumentsTarget
    while (container.firstChild != null) {
      container.removeChild(container.firstChild)
    }

    const showOptions = subcommand.options != null && subcommand.options.length > 0
    this.argumentsContainerTarget.style.display = showOptions ? "block" : "none"

    subcommand.options?.forEach(optionName => {
      const optionDiv = document.createElement("div")
      optionDiv.className = "flex-container flex-align-center flex"

      const input = document.createElement("input")
      input.type = "text"
      input.name = `tasks_macos_run_defender_command[options][${optionName}]`
      input.id = `defender_option_${optionName}`
      input.className = "ml-1 flex"

      const label = document.createElement("label")
      label.htmlFor = input.id
      label.textContent = optionName
      label.className = "flex"

      optionDiv.append(label, input)

      container.appendChild(optionDiv)
    })
  }

  registerSubcommand(subcommand) {
    this.subcommandsByInvocation[subcommand.subcommand] = subcommand
  }

  get subcommands() {
    return JSON.parse(this.element.dataset.subcommands)
  }
}
