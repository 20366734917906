import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

export default class extends Controller {
  static targets = ["installedChart"]

  connect() {
    let chartData =  huntressData.sorted_count_hash
    let rmmNames = Object.keys(chartData)
    let rmmCounts = Object.values(chartData)
    let rmmPercentages = this.convertValueIntegersToPercentage(Object.values(chartData))
    let backgroundColors = this.determineBarColors(rmmPercentages)
    let pathMap = huntressData.details_url

    let chart = new Chart($(this.installedChartTarget), {
      type: "horizontalBar",
      data: {
        labels: rmmNames,
        datasets: [{
          counts: rmmCounts,
          data: rmmPercentages,
          backgroundColor: backgroundColors,
        }],
      },
      options: {
        onClick: (evt)=> {
          let rmmName = this.getRmmName(evt, chart)
          if (rmmName) {
            window.location.href = pathMap[rmmName]
          }
        },
        onHover: (evt, chartElements) => {
          let canvas = chart.canvas

          if (chartElements.length > 0 && chartElements[0]._datasetIndex === 0) {
            canvas.style.cursor = "pointer"
          } else {
            var yAxis = chart.scales["y-axis-0"]
            var canvasPosition = Chart.helpers.getRelativePosition(evt, chart)

            let onYAxisLabel = yAxis.ticks.some((value, index) => {
              let labelPixel = yAxis.getPixelForTick(index)
              return (canvasPosition.y <= labelPixel + 5 && canvasPosition.y >= labelPixel - 5)
            })

            if (onYAxisLabel) {
              canvas.style.cursor = "pointer"
            } else {
              canvas.style.cursor = "default"
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex]
              let percentValue = dataset.data[tooltipItem.index]
              let countValue = dataset.counts[tooltipItem.index]
              return countValue + " (" + percentValue + "%)"
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return value
              }
            },
          }],
          xAxes: [{
            ticks: {
              min: 0,
              max: 100,
              stepSize: 25,
              callback: function(value) {
                return value + "%"
              }
            }
          }],
        },
      }
    })
  }

  getRmmName(evt, chart) {
    let activeElement = chart.getElementAtEvent(evt)
    let rmmName = ""

    if (activeElement.length === 0) {
      let canvasPosition = Chart.helpers.getRelativePosition(evt, chart)
      let yAxis = chart.scales["y-axis-0"]
      let yScaleTop = yAxis.top
      let yScaleBottom = yAxis.bottom

      if (canvasPosition.y >= yScaleTop && canvasPosition.y <= yScaleBottom) {
        yAxis.ticks.forEach(function (value, index) {
          let labelPixel = yAxis.getPixelForTick(index)

          if (canvasPosition.y <= labelPixel + 5 && canvasPosition.y >= labelPixel - 5) {
            rmmName = value
          }
        })
      }
    } else {
      rmmName = activeElement[0]._model.label
    }

    return rmmName
  }

  convertValueIntegersToPercentage(array) {
    let total = array.reduce((acc, val) => acc + val, 0)
    let percentages = array.map(value => {
      const percentage = (value / total) * 100
      return percentage.toFixed(2)
    })

    return percentages
  }

  determineBarColors(values) {
    // Sort the values to find the largest (for comparing which is largest)
    let sortedValues = [...values].sort((a, b) => b - a)
    let largestValue = sortedValues[0]

    // Map each value to a color
    return values.map(value => {
      if (values.length === 1) {
        return "rgba(54, 162, 235, 0.6)"  // blue
      } else {
        if (value === largestValue) {
          return "rgba(54, 162, 235, 0.6)"  // blue
        } else if (value > 10) {
          return "rgba(137, 207, 240, 0.6)"  // light blue
        } else if (value > 2) {
          return "rgba(255, 165, 0, 0.6)"  // orange
        } else {
          return "rgba(139, 0, 0, 0.6)"  // red
        }
      }
    })
  }
}
