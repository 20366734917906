import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["hidePopupCheck"]

  connect() {
    if(this.element.dataset.showonload === "1") {
      this.showModal()
    }
  }

  showModal() {
    $(this.element).modal("show")
  }

  hidePopup(event) {
    if(this.element.dataset.modalKey && this.hidePopupCheckTarget.checked) {
      $.ajax({
        url: Routes.users_disable_modal_account_path(),
        method: "PATCH",
        data: {
          modal_type: this.element.dataset.modalKey
        },
        error: (xhr, ajaxOptions, thrownError) => {
          console.error("An error occurred while marking these feature as read.")
          console.error(thrownError)
        }
      })
    }
  }
}
