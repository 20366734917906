import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resolveReportCheckbox", "resolveButton"]

  connect() { 
    if(this.hasResolveReportCheckboxTarget){
      $(this.resolveReportCheckboxTarget).on("change", (e) => {
        this.resolveReportChecked(e)
      })
    }
  }

  toggleComplete(event) {
    $.ajax({
      method: "PATCH",
      url: event.currentTarget.dataset.url,
      dataType: "json",
      success: (data) => {
        $(event.target).parents("tr").replaceWith(data.content)
      }
    })
  }

  resolveReportChecked(e) {
    let checkbox = $(this.resolveReportCheckboxTarget)
    if(checkbox.prop("checked")){
      $(this.resolveButtonTarget).attr("disabled", false)
    }
    else{
      $(this.resolveButtonTarget).attr("disabled", true)
    }
  }
}
