import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["sourceAccount", "accountOrganizations", "targetAccount", "submitButton"]

  connect() {
    $(this.sourceAccountTarget).on("select2:select", this.enableOrganizationsDropdown.bind(this))
    $(this.sourceAccountTarget).on("select2:unselecting", this.disableOrganizationsDropdown.bind(this))
    $(this.accountOrganizationsTarget).on("select2:select", this.enableTargetAccountDropdown.bind(this))
    $(this.accountOrganizationsTarget).on("select2:unselecting", this.disableTargetAccountDropdown.bind(this))
    $(this.targetAccountTarget).on("change", this.toggleSubmitButton.bind(this))
  }

  enableOrganizationsDropdown() {
    $(this.accountOrganizationsTarget).val(null).trigger("change")
    const accountId = this.sourceAccountTarget.value

    $(this.accountOrganizationsTarget).select2({
      theme: "bootstrap",
      placeholder: "Select organizations...",
      allowClear: true,
      ajax: {
        url: Routes.move_select2_admin_account_organizations_path(accountId),
        dataType: "json",
        delay: 150,
        error: this.handleAjaxError
      },
      width: "100%",
      cache: true,
    })

    this.accountOrganizationsTarget.disabled = false
  }

  disableOrganizationsDropdown() {
    this.accountOrganizationsTarget.disabled = true
  }

  enableTargetAccountDropdown() {
    this.targetAccountTarget.disabled = false
  }

  disableTargetAccountDropdown() {
    $(this.targetAccountTarget).val(null).trigger("change")
    this.targetAccountTarget.disabled = true
  }

  toggleSubmitButton() {
    let targetAccountValue = $(this.targetAccountTarget).val()
    this.submitButtonTarget.disabled = !targetAccountValue
  }
}
