import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "blockedIPsList",
    "blockedIpOption",
    "subtext",
  ]

  static values = {
    invalidIps: Object
  }

  connect() {
    if (!this.hasBlockedIPsListTarget)
      return

    $(this.blockedIPsListTarget).select2({
      theme: "bootstrap",
      width: "resolve",
      multiple: true,
      allowClear: true,
      placeholder: "Add IPs here.",
      tags: true,
      templateSelection: this.ipOptionTemplate.bind(this),
      tokenSeparators: [",", " "],
    })

    const input = $(".blocked-ip-list input")[0]
    if (input !== undefined)
      input.style["width"] = ""

    $(this.blockedIPsListTarget).on("select2:select", async function(e) {
      let ip = e.params.data.id

      const resp = await fetch(
        `/admin/blockable_ip_addresses/${encodeURIComponent(ip)}`,
        {
          method: "GET",
          headers: {"Accept": "application/json"},
        }
      )

      if (resp.ok) {
        let json = await resp.json()
        let ipRes = json[ip]
        if (!ipRes.blockable) {
          this.addInvalidIp(ipRes.ip, ipRes.reason)
        }

        const event = new CustomEvent("ip-address:select", { detail: ipRes })
        this.blockedIPsListTarget.dispatchEvent(event)
      }
    }.bind(this))

    $(this.blockedIPsListTarget).on("select2:unselect", function(e) {
      let ip = e.params.data.id
      this.removeInvalidIp(ip)
      const event = new CustomEvent("ip-address:unselect", { detail: { ip: ip } })
      this.blockedIPsListTarget.dispatchEvent(event)
    }.bind(this))
  }

  ipOptionTemplate(v) {
    let $option = $(`<span data-${this.identifier}-target="blockedIpOption">${v.text}</span>`)
    if (Object.hasOwn(this.invalidIpsValue, v.text))
      $option.addClass("border-danger-on-parent-error")
    return $option
  }

  addInvalidIp(ip, reason) {
    let tmp = this.invalidIpsValue
    tmp[ip] = reason
    this.invalidIpsValue = tmp
  }

  removeInvalidIp(ip) {
    let tmp = this.invalidIpsValue
    delete tmp[ip]
    this.invalidIpsValue = tmp
  }

  invalidIpsValueChanged() {
    this.blockedIpOptionTargets.forEach((el) => {
      if (Object.hasOwn(this.invalidIpsValue, el.innerText))
        el.classList.add("border-danger-on-parent-error")
    })
    this.updateSubtext()
  }

  updateSubtext() {
    if (!this.hasSubtextTarget)
      return

    if (Object.values(this.invalidIpsValue).length == 0) {
      this.subtextTarget.innerText = "Required"
      this.element.classList.remove("has-error")
    } else {
      this.subtextTarget.innerText = "Invalid IP address. Verify that this is an external IP address or check formatting."
      this.element.classList.add("has-error")
    }
  }
}
