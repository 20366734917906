import { Controller } from "@hotwired/stimulus"
import {formValidation, plugins} from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "achElement",
    "achText",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipCode",
    "country",
    "cardholderName",
    "ccText",
    "creditCardForm",
    "cloneBillingAddress",
    "cloneBillingAddressGroup",
    "form",
    "physicalAddressCountry",
    "selectedPaymentMethod",
    "shippingAddressFields",
    "stripeForm",
    "stripeToken"
  ]

  connect() {
    if (this.hasStripeFormTarget) {
      this.setupStripe()

      if (this.hasFormTarget)
        this.setupFormValidation()

      this.updateAllowedPaymentTypes(null, false)
    }
  }

  get selectedPaymentMethod() {
    if (!this.hasSelectedPaymentMethodTarget)
      return null

    return this.selectedPaymentMethodTarget.value
  }

  set selectedPaymentMethod(newValue) {
    if (!this.hasSelectedPaymentMethodTarget)
      return

    this.selectedPaymentMethodTarget.value = newValue
  }

  get canSelectACHPayment() {
    return huntressData.allow_ach_payments &&
      (huntressData.current_unit_count >= huntressData.minimum_unit_count_for_ach)
  }

  revalidateField(event) {
    this.fv.revalidateField(event.target.getAttribute("data-field"))
  }

  showPaymentOptions(newValue, revalidate = true) {
    if (!this.hasSelectedPaymentMethodTarget)
      return

    const hidden = newValue === "ach_credit_transfer"

    this.creditCardFormTarget.hidden = hidden

    if (hidden) {
      this.shippingAddressFieldsTarget.hidden = false
      this.cloneBillingAddressTarget.checked = false
    }

    this.achElementTargets.forEach(element => {
      element.hidden = !hidden
    })

    if (revalidate)
      this.fv.validate()
  }

  updatePaymentMethod() {
    this.showPaymentOptions(this.selectedPaymentMethod)
  }

  changeCloneBilling(event) {
    this.shippingAddressFieldsTarget.hidden = event.target.checked
  }

  updateAllowedPaymentTypes(_, revalidate = true) {
    if (!this.canSelectACHPayment)
      this.selectedPaymentMethod = "credit_card"

    this.showProperTextElements()
    this.showPaymentOptions(this.selectedPaymentMethod, revalidate)
  }

  showProperTextElements() {
    if (this.hasCcTextTarget)
      this.ccTextTarget.hidden = this.canSelectACHPayment

    if (this.hasAchTextTarget)
      this.achTextTarget.hidden = !this.canSelectACHPayment
  }

  setupFormValidation() {
    this.fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "blur"
        })
      },
      fields: {
        "cardholder-name": {
          validators: {
            callback: {
              message: "Cardholder name is required.",
              callback: this.validateBillingAddressField.bind(this)
            }
          }
        },
        "cardholder-phone": {
          validators: {
            callback: {
              message: "Cardholder phone number is required.",
              callback: this.validateBillingAddressField.bind(this)
            }
          }
        },
      }
    })
  }

  validateBillingAddressField(input) {
    if (this.selectedPaymentMethod === "ach_credit_transfer")
      return true

    return input.value !== ""
  }

  setupStripe() {
    this.stripe = Stripe(huntressData.stripe_pk)
    this.card = this.stripe.elements().create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#3498db",
          color: "#32315E",
          // TODO: from the stripe console:
          // The use of the style property lineHeight is discouraged, because
          // it can lead to visual inconsistencies among various browsers.
          // Consider using a padding on the Element’s container instead.
          lineHeight: huntressData.stripe_cc_line_height,
          fontWeight: 400,
          fontSize: "15px",

          "::placeholder": {
            color: "#CFD7DF",
          }
        },
      }
    })
    this.card.mount("#card-element")

    this.card.on("change", function (event) {
      var errorField = $("#card-element-error")
      var submitButton = $("#subscribe-confirm-show")

      if (event.error) {
        errorField.removeClass("hidden").text(event.error.message)
        submitButton.addClass("disabled").attr("disabled", true)
      } else {
        errorField.addClass("hidden").text("")
        submitButton.removeClass("disabled").attr("disabled", false)
      }
    })
  }

  async validatePaymentDetails(callback) {
    if (!this.fv && this.hasStripeFormTarget) {
      callback("Valid")
      return
    }

    // If the clone address target isn't there we can return because there is nothing to validate.
    // In the scenario where we need a payment source, this field will be present.
    // In the scenario where we only need an address, this field will also be present.
    // Any other scenario we already have what we need and can return valid.
    if (!this.hasCloneBillingAddressTarget) {
      callback("Valid")
      return
    }

    let billingAddressPromise
    let shippingAddressPromise
    const event = new CustomEvent("validateAddressFields", {
      detail: {
        billingAddressPassingValidationCb: promise => billingAddressPromise = promise,
        shippingAddressPassingValidationCb: promise => shippingAddressPromise = promise,
        skipShippingValidation: this.cloneBillingAddressTarget.checked,
      }
    })

    window.dispatchEvent(event)
    const paymentInfoStatus = this.fv ? await this.fv.validate() : "Valid"
    const shippingAddressStatusResult = await shippingAddressPromise
    const billingAddressStatusResult = await billingAddressPromise
    const validationsPassing = paymentInfoStatus === "Valid" && shippingAddressStatusResult === "Valid" && billingAddressStatusResult === "Valid"
    callback(validationsPassing ? "Valid" : "Invalid")
  }

  createStripePaymentMethodToken(event) {
    let form = this.formTarget

    let extraDetails = {
      name: this.cardholderNameTarget.value,
      address_line1: this.addressLine1Target.value,
      address_line2: this.addressLine2Target.value,
      address_city: this.cityTarget.value,
      address_state: this.stateTarget.value,
      address_zip: this.zipCodeTarget.value,
      address_country: this.countryTarget.value
    }

    // Pass the details to Stripe
    this.createStripeToken(extraDetails).then(function (result) {
      if (result.error) {
        $("#card-element-error").removeClass("hidden").text(result.error.message)
        $("#subscribe-confirm-show").addClass("disabled").attr("disabled", true)
        $("#subscribe-confirm").modal("hide")
        $("#subscribe-confirm-submit").removeClass("disabled").attr("disabled", false)
      } else {
        this.stripeTokenTarget.value = result.token.id
        form.submit()
      }
    }.bind(this))
  }

  createStripeToken(cardholderInformation) {
    if (this.stripeTestToken) {
      // This prevents system tests from calling out to Stripe for a token
      return Promise.resolve({ token: { id: this.stripeTestToken }})
    } else {
      return this.stripe.createToken(this.card, cardholderInformation)
    }
  }

  get stripeTestToken() {
    return this.data.get("stripeTestToken")
  }
}
