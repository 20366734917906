import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable", "modal", "modalContent", "modalLoading", "modalHeader"]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    this.buildDataTables()

    $("#datatable table").on("click", "a.detection-details", this.loadDetectionDetailsModal.bind(this))
  }

  buildDataTables() {
    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: 2, orderable: true },
        { targets: "_all", orderable: false, searchable: false }
      ],
      order: [[2, "desc"]],
      searching: false,
      deferRender: true,
      responsive: true,
      ajax: huntressData.admin_threatops_detector_detections_path,
    })
  }

  loadDetectionDetailsModal(event) {
    this.modalHeaderTarget.innerHTML = ""
    $(this.modalContentTarget).hide()
    $(this.modalLoadingTarget).show()
    $(this.modalTarget).modal("show")

    $.get(event.currentTarget.dataset.modalpath, (data) => {
      this.modalContentTarget.innerHTML = data
      this.modalHeaderTarget.innerHTML = $(this.modalContentTarget).find("#modalTitle").html()
      $(this.modalContentTarget).find("#modalTitle").remove()
    }).fail(() => {
      this.modalContentTarget.innerHTML = "An error occurred while loading the detection."
    }).always(() => {
      $(this.modalLoadingTarget).hide()
      $(this.modalContentTarget).show()
    })
  }
}
