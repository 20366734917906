import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#delete-contact-modal").on("show.bs.modal", function (e) {
      var data = $(e.relatedTarget).data()
      $("form", this).attr("action", data.path)
    })
  }
}
