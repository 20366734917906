import { Controller } from "@hotwired/stimulus"

const disabledStrings = ["Disabled", "Hidden"]

export default class extends Controller {
  static targets = ["parentSetting", "dependentSetting", "popover"]

  connect() {
    const mavSettingTitleText = $(this.parentSettingTarget).find(".mav-setting-title").text()

    if (this.textDisabled(mavSettingTitleText)) {
      this.hideDependentSetting()
    } else {
      this.showDependentSetting()
    }
  }

  changed(ev) {
    const selectedText = ev.target.options[ev.target.selectedIndex].text
    const mavSettingTitleText = $(this.parentSettingTarget).find(".mav-setting-title").text()
    const text = selectedText.startsWith("Inherit Setting") ? mavSettingTitleText : selectedText

    if (this.textDisabled(text)) {
      this.hideDependentSetting()
    } else {
      this.showDependentSetting()
    }
  }

  textDisabled(text) {
    return disabledStrings.some(str => text.startsWith(str))
  }

  hideDependentSetting() {
    const el = $(this.dependentSettingTarget)

    el.find(".field-title").addClass("text-muted")
    el.find(".mav-setting-title").addClass("text-muted")
    el.find(".mav-setting-desc").addClass("text-muted")
    this.muteDescription()
    this.disableDropDown()
    this.showPopover()
  }

  showDependentSetting() {
    const el = $(this.dependentSettingTarget)

    el.find(".field-title").removeClass("text-muted")
    el.find(".mav-setting-title").removeClass("text-muted")
    el.find(".mav-setting-desc").removeClass("text-muted")
    this.showDescription()
    this.enableDropDown()
    this.hidePopover()
  }

  muteDescription() {
    const description = $(this.dependentSettingTarget).find(".description-finder")
    description.removeClass("field-description")
    description.addClass("text-muted")
  }

  disableDropDown() {
    const dropDown = $(this.dependentSettingTarget).find(".form-control.select.required")
    dropDown.addClass("inherit-font-family")
    dropDown.prop("disabled", true)
  }

  showPopover() {
    $(this.popoverTarget).css({"display": "block"})
  }

  showDescription() {
    const description = $(this.dependentSettingTarget).find(".description-finder")
    description.addClass("field-description")
    description.removeClass("text-muted")
  }

  enableDropDown() {
    const dropDown = $(this.dependentSettingTarget).find(".form-control.select.required")
    dropDown.prop("disabled", false)
  }

  hidePopover() {
    $(this.popoverTarget).css({"display": "none"})
  }
}
