import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["path", "taskForm", "task", "taskSelect"]

  createTask(event) {
    event.preventDefault()
    event.stopPropagation()

    const task = event.currentTarget.dataset.task
    const key = event.currentTarget.dataset.key

    $(this.pathTarget).attr({name:`[${task}]${key}`})
    $(this.taskFormTarget).append($("<input>").attr({
      type: "hidden",
      name: "type",
      value: task
    }))

    this.taskTarget.value = task
    this.taskFormTarget.submit()
  }
}
