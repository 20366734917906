import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = ["bulkIsolateForm", "isolationComment", "isolationCommentRequirement"]

  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    formValidation(this.bulkIsolateFormTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        submitButton: new plugins.SubmitButton(),
        defaultSubmit: new plugins.DefaultSubmit()
      },
      fields: {
        "task[task_params][comment]": {
          validators: {
            regexp: {
              regexp: /\w+.*\s+.*\w+/
            },
            notEmpty: {}
          }
        }
      }
    })
  }
}
