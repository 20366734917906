import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.scrollToTop = this.scrollToTop.bind(this)
    document.addEventListener("turbo:load", this.scrollToTop)
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.scrollToTop)
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
}
