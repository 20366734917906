import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.currentPage = parseInt(this.element.dataset.startPage) || 0
    this.url = this.element.dataset.url || "#"
    this.load()
  }

  load() {
    const url = new URL(this.url, window.location.origin)
    url.searchParams.set("page", this.currentPage)

    fetch(url.href)
      .then(response => response.text())
      .then(body => {
        this.element.innerHTML = body
        $(this.element).tooltip({selector: '[data-toggle="tooltip"]'})
      })
      .catch(error => {
        alert("Unable to load records")
      })
  }

  prevPage() {
    this.currentPage -= 1
    if (this.currentPage < 0)
      this.currentPage = 0

    this.load()
  }

  nextPage() {
    this.currentPage += 1
    this.load()
  }
}
