import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable", "toggleTable", "overview"]

  toggleTableVisibility() {
    this.datatableTarget.classList.toggle("hidden")
    this.overviewTarget.classList.toggle("hidden")
  }

  tableClick(e) {
    e.stopPropagation()
  }
}
