import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable"]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: [1, -1], orderable: false }
      ],
      searching: false,
      order: [[0, "desc"]],
      responsive: true,
      ajax: huntressData.url_for_status,
      drawCallback: function() {
        var table = $(this).dataTable().api()
        var pageInfo = table.page.info()
        if (pageInfo.pages > 0 && pageInfo.page >= pageInfo.pages) {
          setTimeout(function(){
            table.page(0).draw()
          }, 100)
        }
      },
    })
  }
}
