import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "toggleOn", "toggleOff"]

  connect() {
    this.render()
    $(this.toggleTarget).on("change", () => this.render())
  }

  render() {
    if (this.toggleTarget.checked) {
      $(this.toggleOnTarget).removeClass("hidden")
      $(this.toggleOffTarget).addClass("hidden")
    } else {
      $(this.toggleOnTarget).addClass("hidden")
      $(this.toggleOffTarget).removeClass("hidden")
    }
  }
}
