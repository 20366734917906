import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from "../../../helpers/moneyHelpers"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "additionalCost", 
    "additionalUnits", 
    "explanation", 
    "form",
    "submitButton", 
    "minimumWarning", 
    "subtotal"
  ]

  static values = {
    currentMinimum: Number,
    newMinimum: Number,
    unitCost: Number,
    monthsRemaining: Number,
    billingInterval: String,
    currency: String
  }

  connect() {
    this.updateExplanation()
    this.setupFormValidation()
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3({ rowValidClass: "" }),
          trigger: new plugins.Trigger({
            event: "blur"
          }),
          submitButton: new plugins.SubmitButton({}),
          defaultSubmit: new plugins.DefaultSubmit()
        },
        fields: {
          purchaseOrder: {
            selector: '[name="subscription[purchase_order]"]',
            validators: {
              notEmpty: { message: "Purchase order is required." }
            }
          },
        }
      }
    )
  }

  minChanged(e) {
    this.newMinimumValue = e.target.value
    this.updateTotal()
    this.updateWarning()
    this.updateExplanation()
    this.updateSubmitButton()
  }

  // Force the user to click the button to prevent accidental submission by pressing the 'enter' key.
  minKeydown(event) {
    if (event.keyCode === 13){
      event.preventDefault()
      return false
    }
  }

  updateTotal() {
    this.subtotalTarget.value = this.newTotal
  }

  updateWarning() {
    this.minimumWarningTarget.classList.toggle("hidden", this.newMinimumValue >= this.currentMinimumValue)
  }

  updateExplanation() {
    if (this.billingIntervalValue === "annual") {
      this.additionalUnitsTarget.innerText = this.newUnits
      const newTotal = formatCurrency(this.currencyValue, this.newUnits * this.unitCostValue * this.monthsRemainingValue)
      this.additionalCostTarget.innerText = newTotal
    } else if (this.billingIntervalValue === "monthly") {
      this.additionalUnitsTarget.innerText = this.newMinimumValue
      this.additionalCostTarget.innerText = this.newTotal
    }
    this.explanationTarget.classList.toggle("hidden", this.newUnits < 1)
  }

  updateSubmitButton() {
    this.submitButtonTarget.disabled = this.newUnits < 1
  }

  get newUnits() {
    return this.newMinimumValue - this.currentMinimumValue
  }

  get newTotal() {
    return formatCurrency(this.currencyValue, this.newMinimumValue * this.unitCostValue)
  }
}
