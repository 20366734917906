import { Controller } from "@hotwired/stimulus"
import { isTouchDevice, isMobileDevice } from "../helpers/deviceHelpers"

export default class extends Controller {
  static targets = ["productSubMenu", "dashboardLink", "menuWrapper"]
  static values = { dashboardPath: String }

  connect() {
    this.menuOpen = false
    this.isMobileDevice = isMobileDevice()
    this.isTouchScreen = isTouchDevice()

    if (this.isTouchScreen) {
      this.removeHrefFromDashboardLink()
    }
  }

  showMenu() {
    this.menuOpen = true
    this.element.classList.add("open")
    this.element.parentElement.classList.add("open")
    this.productSubMenuTarget.classList.remove("hidden")

    if (this.isTouchScreen) {
      this.addHrefToDashboardLink()
    }
  }

  hideMenu() {
    this.menuOpen = false
    this.element.classList.remove("open")
    this.element.parentElement.classList.remove("open")
    this.productSubMenuTarget.classList.add("hidden")

    if (this.isTouchScreen) {
      this.removeHrefFromDashboardLink()
    }
  }

  desktopShowMenu() {
    if (this.isTouchScreen && this.isMobileDevice) return
    this.showMenu()
  }

  desktopHideMenu() {
    if (this.isTouchScreen && this.isMobileDevice) return
    this.hideMenu()
  }

  toggleMenuForTouchDevice(e) {
    if (!this.isTouchScreen) return

    if (!this.menuOpen && this.matchingNode(e.target)) {
      this.showMenu()
      this.addWindowListener()
    } else if (this.menuOpen && !this.matchingNode(e.target)) {
      this.hideMenu()
      this.removeWindowListener()
    }
  }

  matchingNode(target) {
    return this.element.isSameNode(target) || this.element.contains(target)
  }

  subMenuClick(e) {
    e.stopPropagation()
  }

  addWindowListener() {
    // This is crappy, but otherwise the window also catches the event for mobile devices.
    setTimeout(() => {
      this.handleWindowClick = this.toggleMenuForTouchDevice.bind(this)
      window.addEventListener("click", this.handleWindowClick)
    }, 1)
  }

  removeWindowListener() {
    window.removeEventListener("click", this.handleWindowClick)
  }

  removeHrefFromDashboardLink() {
    if (!this.isMobileDevice) {
      this.menuWrapperTarget.classList.remove("highlightable")
    }

    this.dashboardLinkTarget.href = "#"
  }

  addHrefToDashboardLink() {
    if (!this.isMobileDevice) {
      this.menuWrapperTarget.classList.add("highlightable")
    }

    setTimeout(() => {
      this.dashboardLinkTarget.href = this.dashboardPathValue
    }, 10)
  }
}
