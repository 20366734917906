import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select2Wrapper", "select2"]

  connect() {
    this.select2Element = $(this.select2Target).select2({
      placeholder: "Select an option...",
      allowClear: false,
      minimumResultsForSearch: -1,
      theme: "bootstrap",
      width: "100%",
    })
      .on("select2:select", this.setAssumedRole.bind(this))


    this.windowHandlerFunc = this.hideDropdown.bind(this)
  }

  addWindowHandler() {
    window.addEventListener("click", this.windowHandlerFunc)
  }

  removeWindowHandler() {
    window.removeEventListener("click", this.windowHandlerFunc)
  }

  showDropdown(e) {
    this.select2WrapperTarget.classList.remove("hidden")
    this.select2Element.select2("open")
    this.addWindowHandler()
    e.stopPropagation()
  }

  hideDropdown() {
    console.log("hiding dropdown")
    this.select2WrapperTarget.classList.add("hidden")
    this.removeWindowHandler()
  }

  setAssumedRole(event) {
    window.location = `/account/users/assume_role?role=${event.target.value}`
  }
}
