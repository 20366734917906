import PaymentDetailsController from "controllers/layouts/payment_details_controller"

export default class extends PaymentDetailsController {
  static targets = ["subscribeConfirmButton", "confirmModal"]

  connect() {
    super.connect()
  }

  get has_subscription() {
    return this.data.get("hasSubscription") == "1"
  }

  revalidateZip() {
    this.fv.revalidateField("address-zip")
  }

  validate() {
    this.fv.validate()
  }

  validateAndShowModal() {
    this.validatePaymentDetails((status) => {
      if (status === "Valid") {
        $(this.confirmModalTarget).modal("show")
      }
    })
  }

  validateAndSubscribe() {
    $(this.subscribeConfirmButtonTarget)
      .removeClass("disabled")
      .attr("disabled", false)

    this.validateAndShowModal()
  }

  subscribe(event) {
    // Disable the submit button so the form isn't accidentally submitted twice
    this.subscribeConfirmButtonTarget.disabled = true
    this.subscribeConfirmButtonTarget.setAttribute("disabled", true)

    // This form should only be submittable if the subscription-confirm modal is
    // visible. If the user presses 'enter' in the Stripe form, it will be
    // submitted and we'll catch it here that the modal isn't open
    if (!$(this.confirmModalTarget).hasClass("in")) {
      return false
    }

    // No need to create a Stripe token since a Stripe subscription already exists
    if (this.has_subscription) {
      return true
    }

    // When the form is submitted and passes all validations, this callback gets
    // called. Submission is blocked and the credit card details are submitted
    // to Stripe. If that succeeds, the returned token from Stripe gets added to
    // the form and it is submitted again. This time after passing validations,
    // the form contains the Stripe token so we're done here.
    if (this.stripeTokenTarget.value.length > 0) {
      return true
    }

    // If using ACH, payment details are delegated to the invoicing process,
    // so we're allowing submission without any validation.
    if (this.selectedPaymentMethod === "ach_credit_transfer") {
      return true
    }

    this.createStripePaymentMethodToken()

    event.preventDefault()

    return false
  }

  createStripeToken(cardholderInformation) {
    if (this.stripeTestToken) {
      // This enables system tests from calling out to Stripe for a token
      return new Promise(resolve => {
        resolve({ token: { id: this.stripeTestToken } })
      })
    } else {
      return this.stripe.createToken(this.card, cardholderInformation)
    }
  }

  keydown(event) {
    // Since we want to force the user to view the modal dialog to ensure they
    // understand what they are agreeing to, we're blocking form submission by the
    // 'enter' key.
    if (event.keyCode == 13){
      event.preventDefault()
      return false
    }
  }

  get stripeTestToken() {
    return this.data.get("stripeTestToken")
  }
}
