import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    [
      "datatable",
      "reportType",
      "datesContainer",
      "dateRange",
      "customDatesContainer",
      "customStartDate",
      "customEndDate",
    ]

  connect() {
    this.setDateRangeVisibility()
    this.setCustomDateRangeVisibility()

    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: [4,5], orderable: false },
      ],
      order: [[2, "desc"]],
      searching: false,
      deferRender: true,
      responsive: true,
      ajax: {
        url: this.data.get("reportPath"),
        dataType: "json"
      },
    })
  }

  setCustomDateRangeVisibility() {
    if (this.dateRangeTarget) {
      let hide = this.dateRangeTarget.value !== "Custom"
      this.customDatesContainerTarget.classList.toggle("hidden", hide)
      this.customStartDateTarget.disabled = hide
      this.customEndDateTarget.disabled = hide
    }
  }

  setDateRangeVisibility() {
    if (this.reportTypeTarget) {
      let hide = this.reportTypesWithoutDateRange.includes(this.reportTypeTarget.value)
      this.datesContainerTarget.classList.toggle("hidden", hide)
      this.dateRangeTarget.disabled = hide
    }
  }

  get reportTypesWithoutDateRange() {
    try {
      return JSON.parse(this.data.get("reportTypesWithoutDateRange")) || []
    } catch (err) {
      return []
    }
  }
}
