(function ($) {
  // Reload page if datatable error was caused by expired session, otherwise do datatable default
  $.fn.dataTable.ext.errMode = function(e, settings, message) {
    if (e && e.jqXHR && e.jqXHR.status === 401) {
      window.location.reload()
    } else {
      alert(message)
    }
  }
})(jQuery)
