import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "label"]

  connect() {
    this.inputTarget.addEventListener("change", (event) => {
      let filename = this.inputTarget.value.replace(/\\/g, "/").replace(/.*\//, "")
      this.labelTarget.value = filename
    })
  }
}
