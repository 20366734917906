Bugsnag.start({
  apiKey: "bf9cda70424f685296e6d17885b84a0b",
  appVersion: document.querySelector("meta[name=application_version]")?.content,
  onError: (event) => {
    // Skip error if this is a 401 this is handled elsewhere
    if(event.breadcrumbs){
      const logoutBreadcrumb = event.breadcrumbs.find(breadcrumb =>
        breadcrumb.type == "request" && breadcrumb.metadata && breadcrumb.metadata.status == 401
      )

      if (logoutBreadcrumb !== undefined) {
        return false
      }
    }

    // Check for a current user and report the details if found
    var userId = document.querySelector("meta[name=user_id]")?.content
    if (userId !== undefined) {
      event.setUser(
        userId,
        document.querySelector("meta[name=user_email]")?.content,
        document.querySelector("meta[name=user_name]")?.content
      )
    }
  }
})
