import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  save(event) {
    this.selectionStart = event.target.selectionStart
    this.selectionEnd = event.target.selectionEnd
    this.currentInputId = event.target.id
  }

  inputTargetConnected(target) {
    if (target.id === this.currentInputId) {
      target.focus()
      target.selectionStart = this.selectionStart
      target.selectionEnd = this.selectionEnd
    }
  }
}

