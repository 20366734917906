import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
import FieldStatus from "formvalidation/plugins/FieldStatus"
import { setButtonAvailability } from "../../../src/devise/helper"

export default class extends Controller {
  static targets = ["form", "submitButton"]

  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "input"
        }),
        fieldStatus: new FieldStatus({
          onStatusChanged: (areFieldsValid) => {
            setButtonAvailability(this.submitButtonTarget, areFieldsValid)
          }
        })
      },
      fields: {
        "user[email]": {
          validators: {
            notEmpty: {
              message: "E-mail address is required."
            },
            emailAddress: {
              message: "Please enter a valid email address."
            }
          }
        }
      }
    })
  }
}
