(function ($) {
  "use-strict"

  var DataTable = $.fn.dataTable

  class Realtime {
    static defaults = {
      dom: {
        container: {
          tag: "div",
          attr: {
            class: "dt-realtime"
          }
        },
        loadNew: {
          tag: "a",
          attr: {
            class: "p-2",
            href: "#",
            hidden: true
          },
          count: 0
        },
        toggle: {
          tag: "div",
          attr: {
            style: "display:inline-block;"
          },
          label: {
            tag: "span",
            text: "Real-time Updates",
            attr: {
              class: "p-1"
            }
          },
          input: {
            tag: "input",
            attr: {
              id: "dt-realtime-checkbox",
              type: "checkbox",
              "data-toggle": "toggle",
              "data-size":"small"
            }
          }
        }
      }
    }

    constructor(dt, config) {
      // If there is no config set it to an empty object
      if (typeof (config) === "undefined") {
        config = {}
      }

      // Allow a boolean true for defaults
      if (config === true) {
        config = {}
      }

      let dtApi = DataTable.Api(dt)

      // Set config
      this.c = $.extend(true, {}, Realtime.defaults, config)

      // Set setting
      this.s = {
        dtApi: dtApi,
        dtSettings: dtApi.settings()[0]
      }

      this.dom = {
        container: this._buildElement(this.c.dom.container)
          .append(this._buildLoadNew(), this._buildToggle())
      }

      // Attach the instance to the DataTables settings instance
      if ( this.s.dtSettings.realtime ) {
        return
      }

      this.s.dtSettings.realtime = this
    }

    /**
     * Get the container node for the Realtime component
     * @return {jQuery} Realtime node
     */
    container() {
      return this.dom.container
    }

    updateNewRowsText(num) {
      this.loadNew.text(`Load new signals (${num})`)
    }

    hideLoadNewRows() {
      this.loadNew.prop("hidden", true)
    }

    showLoadNewRows() {
      this.loadNew.prop("hidden", false)
    }

    _buildLoadNew() {
      this.loadNew = this._buildElement(this.c.dom.loadNew)
      this.updateNewRowsText(0)

      return this.loadNew
    }

    _buildToggle() {
      let toggle = this._buildElement(this.c.dom.toggle)
      let label = this._buildElement(this.c.dom.toggle.label)
      let input = this._buildElement(this.c.dom.toggle.input)

      toggle.append(label)
      toggle.append(input)

      return toggle
    }

    _buildElement(config) {
      let element =  $("<" + config.tag + "/>", config.attr)
      if (config.text) {
        element.text(config.text)
      }
      return element
    }
  }

  DataTable.Api.register("realtime.updateNewRowsCount()", function(count) {
    this.iterator("table", (ctx) => {
      if (ctx.realtime) {
        ctx.realtime.updateNewRowsText(count)
      }
    })

    return this
  })

  DataTable.Api.register("realtime.hideLoadNewRows()", function() {
    this.iterator("table", (ctx) => {
      if (ctx.realtime) {
        ctx.realtime.hideLoadNewRows()
      }
    })

    return this
  })

  DataTable.Api.register("realtime.showLoadNewRows()", function() {
    this.iterator("table", (ctx) => {
      if (ctx.realtime) {
        ctx.realtime.showLoadNewRows()
      }
    })

    return this
  })

  // Attach to DataTables objects for global access
  $.fn.dataTable.Realtime = Realtime
  $.fn.DataTable.Realtime = Realtime

  function _init (settings) {
    let api = new DataTable.Api(settings)
    let options = settings.oInit.realtime
  
    return new Realtime(api, options).container()
  }

  // Add real time toggle option to datatable dom
  DataTable.ext.feature.push({
    fnInit: _init,
    cFeature: "R"
  })
// eslint-disable-next-line no-undef
})(jQuery)
