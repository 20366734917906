import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class ConfigureController extends Controller {
  static targets = [
    "billingProduct",
    "billingSync",
    "defaultType",
    "defaultSubtype",
    "defaultItem",
    "defaultStatus",
    "serviceBoard",
    "syncResultsTable"
  ]

  selectOptions(url) {
    return {
      placeholder: "Select an option...",
      allowClear: true,
      theme: "bootstrap",
      width: "100%",
      ajax: {
        url: url,
        dataType: "json",
        data: {
          service_board_id: this.serviceBoardTarget.value,
          type_id: this.defaultTypeTarget.value,
          subtype_id: this.defaultSubtypeTarget.value,
          complete_status_id: this.defaultStatusTarget.value
        },
        delay: 150,
        error: this.handleAjaxError
      }
    }
  }

  setupSelects() {
    $(this.defaultTypeTarget).select2(this.selectOptions(Routes.types_account_integrations_connect_wise_path(huntressData.connect_wise)))
    $(this.defaultSubtypeTarget).select2(this.selectOptions(Routes.subtypes_account_integrations_connect_wise_path(huntressData.connect_wise)))
    $(this.defaultItemTarget).select2(this.selectOptions(Routes.items_account_integrations_connect_wise_path(huntressData.connect_wise)))
    $(this.defaultStatusTarget).select2(this.selectOptions(Routes.statuses_account_integrations_connect_wise_path(huntressData.connect_wise)))
  }

  connect() {
    this.setupSelects()
    $(this.serviceBoardTarget).select2({
      placeholder: "Select an option...",
      allowClear: true,
      theme: "bootstrap",
      width: "100%",
    })
    $(this.serviceBoardTarget).on("select2:select select2:unselecting", this.handleChange.bind(this))
    $(this.defaultTypeTarget).on("select2:select select2:unselecting", this.handleChange.bind(this))
    $(this.defaultSubtypeTarget).on("select2:select select2:unselecting", this.handleChange.bind(this))
    if (this.hasBillingProductTarget) {
      this.billingProductTargets.forEach((target) => {
        $(target).select2({
          theme: "bootstrap",
          placeholder: "Select a product...",
          allowClear: true,
          ajax: {
            url: Routes.products_account_integrations_connect_wise_path(huntressData.connect_wise),
            dataType: "json",
            delay: 150,
            error: this.handleAjaxError
          },
          width: "100%",
          cache: true,
        })
      })
    }

    if (this.hasBillingSyncTarget) {
      $(this.billingSyncTarget).select2({
        theme: "bootstrap",
        allowClear: false,
        width: "100%"
      })
    }

    $(this.syncResultsTableTarget).DataTable()
  }

  handleChange(event) {
    const HIERARCHY_MAPPING = {
      1: this.serviceBoardTarget,
      2: this.defaultTypeTarget,
      3: this.defaultSubtypeTarget,
      4: this.defaultItemTarget,
      5: this.defaultStatusTarget
    }

    let hierarchy_id = parseInt(event.currentTarget.dataset.hierarchyId)
    for (let [position, target] of Object.entries(HIERARCHY_MAPPING)) {
      const pos = parseInt(position)
      target.disabled = false
      if (pos > hierarchy_id) {
        $(target).empty()
        if (pos > (hierarchy_id + 1) && (pos != 5)) {
          target.disabled = true
        }
      }
    }
    this.setupSelects()
  }

  handleAjaxError(xhr, status, error) {
    if (status === "abort") { return }

    let message = ""
    if (status === "timeout") {
      message = "Request timed out"
    } else {
      message = (xhr.responseJSON && xhr.responseJSON.error && xhr.responseJSON.error.message) ||
        ("An unknown error occurred: " + status + " (" + xhr.status + ")")
    }

    $("main").prepend(
      $("<div/>", {class: "alert alert-danger alert-dismissible animated fadeIn"})
        .append(
          $("<button>", {class: "close", "data-dismiss": "alert"}).text("×"),
          $("<span>").text(message)
        )
    )
  }
}
