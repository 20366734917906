import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "hostLevelTable",
    "globalTable",
    "globalInvestigationRadio",
    "autorunCountDisplay",
    "category",
    "autorunSelectTd", "autorunCount",
    "persistenceMechanismSelectTd", "persistenceMechanismCount",
    "binarySelectTd", "binaryCount",
    "totalAffectedCount"
  ]

  connect() {
    this.disableTableOptions(this.globalTableTarget)
    this.autorunCountDisplayTarget.style.display = "none"

    $(this.categoryTarget).on("change", this.updateAffectedCount.bind(this))
    $(this.autorunSelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))
    $(this.persistenceMechanismSelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))

    if (this.hasBinarySelectTdTarget) {
      $(this.binarySelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))
    }
  }

  hostLevelInvestigationSelected() {
    this.disableTableOptions(this.globalTableTarget)
    this.enableTableOptions(this.hostLevelTableTarget)
    this.autorunCountDisplayTarget.style.display = "none"
  }

  globalInvestigationSelected() {
    this.disableTableOptions(this.hostLevelTableTarget)
    this.enableTableOptions(this.globalTableTarget)
    this.autorunCountDisplayTarget.style.display = "block"
  }

  disableTableOptions(tableTarget) {
    let childElements = tableTarget.querySelectorAll("*")
    childElements.forEach(element => element.disabled = true)
    tableTarget.classList.add("investigation-modal-disabled-options-overlay")
  }

  enableTableOptions(tableTarget) {
    let childElements = tableTarget.querySelectorAll("*")
    childElements.forEach(element => element.disabled = false)
    tableTarget.classList.remove("investigation-modal-disabled-options-overlay")
  }

  presentConfirmation() {
    if (this.globalInvestigation()) {
      this.submitButtonTarget.dataset.confirm = "This global investigation could potentially affect many host autoruns. Please confirm before clicking \"OK\"."
    } else {
      // do not present the confirmation if there is no global investigation
      this.submitButtonTarget.removeAttribute("data-confirm")
    }
  }

  globalInvestigation() {
    return this.globalInvestigationRadioTarget.checked
  }

  updateAffectedCount() {
    let highestCount = 0

    if(this.autorunSelectTdTarget.querySelector("select").value) {
      const autorunCount = this.parseCount(this.autorunCountTarget.textContent)

      if(!isNaN(autorunCount)) {
        highestCount = autorunCount
      }
    }

    if(this.persistenceMechanismSelectTdTarget.querySelector("select").value) {
      const persistenceMechanismCount = this.parseCount(this.persistenceMechanismCountTarget.textContent)

      if(!isNaN(persistenceMechanismCount) && persistenceMechanismCount > highestCount) {
        highestCount = persistenceMechanismCount
      }
    }

    if(this.hasBinarySelectTdTarget && this.binarySelectTdTarget.querySelector("select").value) {
      const binaryCount = this.parseCount(this.binaryCountTarget.textContent)

      if(!isNaN(binaryCount) && binaryCount > highestCount) {
        highestCount = binaryCount
      }
    }

    if(this.categoryTarget.dataset["original"] != this.categoryTarget.value) {
      // Category uses the same count as autorun as it is an autorun field
      const categoryCount = this.parseCount(this.autorunCountTarget.textContent)

      if(!isNaN(categoryCount) && categoryCount > highestCount) {
        highestCount = categoryCount
      }
    }

    if(highestCount >= this.countLimit) {
      highestCount = `${this.countLimit}+`
    }

    this.totalAffectedCountTarget.textContent = highestCount.toString()
  }

  parseCount(text){
    const count = parseInt(text)

    if(isNaN(count)) {
      return 0
    }

    return count
  }

  get countLimit() {
    const defaultCountLimit = 1000
    const setCountLimit = parseInt(this.data.get("countLimit"))

    if(isNaN(setCountLimit)) {
      return defaultCountLimit
    }

    return setCountLimit
  }
}
