import * as Routes from "routes"
import { RuleBlock } from "ruleHelpers"
import CreateRuleController from "../../admin/rules/create_rule_controller"

export default class extends CreateRuleController {
  ruleActionButtonClasses = ".ruleAndBtn, .ruleOrBtn, .removeRuleSegment, .or_value, .expression_delete, .removeRuleBlock"
  ruleBlocks = {}
  ruleCounter = 0

  connect() {
    this.submitClicked = false
    this.attachEventListeners()
    this.setupEndDatePicker()
    this.fillRule()
    this.updateScopeWarning()
    this.setSummaryField()
    this.updateRuleFromSelectedScope()
    this.previewComplete = false
  }

  fillRule(){
    const rule = JSON.parse(this.data.get("rule"))
    const filter = rule.filter.condition

    $(this.nameFieldTarget).val(`Copy of ${rule.name}`)

    if(filter.operator == "OR"){
      for(let i = 0; i < filter.expressions.length; i++){
        let expression = filter.expressions[i]
        let new_rule_block = new RuleBlock(++this.ruleCounter, this, false, expression)
        this.ruleBlocks[this.ruleCounter] = new_rule_block
        new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
      }
    }
    else{
      let new_rule_block = new RuleBlock(++this.ruleCounter, this, false, filter)
      this.ruleBlocks[this.ruleCounter] = new_rule_block
      new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
    }

    $(this.modalBodyTarget).data("record", JSON.parse(this.data.get("ruleableData")))
  }

  attachScopeRadioListener() {
    this.updateScopeWarning()
    this.showPreviewRule()
    this.setSummaryField()

    if (this.endDateFieldTarget.value) {
      this.showClearEndDate()
    }

    if ($("input.scope_radio:checked")[0].dataset.endDateRequired) {
      this.setEndDateToRequired()
    }
  }

  submit() {
    $.ajax({
      url: Routes.admin_rules_path(),
      method: "POST",
      data: JSON.stringify(this.buildRule()),
      dataType: "json",
      contentType: "application/json",
      complete: (xhr) => {
        if(xhr.responseJSON.rule_link){
          window.location = xhr.responseJSON.rule_link
        }
        else{
          location.reload()
        }
      }
    })
  }

  setRetroactive(retroactive) {
    let rule = JSON.parse(this.data.get("rule"))
    rule.details["retroactive"] = retroactive
    this.data.set("rule", JSON.stringify(rule))
  }

  isRetroactive() {
    try {
      let rule = JSON.parse(this.data.get("rule"))
      return rule.details["retroactive"] === true
    } catch (error) {
      return true
    }
  }

  getScopeExpression() {
    let scope = JSON.parse(this.data.get("scope"))
    switch($(".scope_radio:checked").val()) {
    case "entity":
      return {
        scope_type: scope.entityable_type,
        scope_id: scope.entityable_id
      }
    case "organization":
      return {
        scope_type: "Organization",
        scope_id: scope.organization_id
      }
    case "account":
      return {
        scope_type: "Account",
        scope_id: scope.account_id
      }
    }
  }

  buildRule(){
    let condition
    if(Object.keys(this.ruleBlocks).length > 1){
      condition = {
        operator: "OR",
        expressions: []
      }
      for(let index in this.ruleBlocks){
        if(this.ruleBlocks[index].value)
          condition.expressions.push(this.ruleBlocks[index].value)
      }
    }
    else if (Object.keys(this.ruleBlocks).length === 1){
      for(let index in this.ruleBlocks){
        let val = this.ruleBlocks[index].value
        if(val.operator)
          condition = val
        else
          condition = {
            operator: "OR",
            expressions: [val]
          }
      }
    }
    else {
      return
    }
    let rule_data = JSON.parse(this.data.get("rule"))
    let rule = {
      name: $(this.nameFieldTarget).val(),
      ends_at: $(this.endDateFieldTarget).val(),
      ruleable_type: rule_data.ruleable_type,
      ruleable_id: rule_data.ruleable_id,
      signal_source_type: rule_data.signal_source_type,
      filter_attributes: {
        condition: condition,
        event: rule_data.filter.event,
        model: rule_data.filter.model
      },
      action_attributes: {
        name:  rule_data.action.name,
        model: rule_data.filter.model
      },
      scopes_attributes: [this.getScopeExpression()],
      comments_attributes: [{
        body: $(this.commentFieldTarget).val()
      }],
      details: rule_data.details,
      starts_at: rule_data.starts_at,
      related_rule_id: rule_data.id,
      reasons: $(this.reasonFieldTarget).val(),
    }
    delete rule.details.applied_retroactively
    delete rule.details.applied_once

    return rule
  }
}
