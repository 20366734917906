import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "syncDay",
    "frequency",
    "status",
    "integrationId",
    "syncNowButton",
    "syncResultsTable",
    "edrServiceMappingName",
    "m365ServiceMappingName",
    "edrServiceMappingId",
    "m365ServiceMappingId"
  ]

  connect() {
    $(this.statusTarget).on("change", this.handleStatusChange.bind(this))
    $(this.frequencyTarget).on("select2:select select2:unselecting", this.handleFrequencyChange.bind(this))

    $(this.edrServiceMappingIdTarget).on("select2:select select2:unselecting", this.handleServiceNameChange.bind(this, "edr"))
    $(this.m365ServiceMappingIdTarget).on("select2:select select2:unselecting", this.handleServiceNameChange.bind(this, "m365"))

    $(this.syncResultsTableTarget).DataTable()
  }

  handleFrequencyChange() {
    if (this.frequencyTarget.value == "Daily") {
      this.syncDayTarget.disabled = true
      this.syncDayTarget.value = ""
    } else {
      this.syncDayTarget.disabled = false
      this.syncDayTarget.value = "1"
    }
  }

  handleStatusChange() {
    this.frequencyTarget.disabled = !this.statusTarget.checked
    if (this.frequencyTarget.disabled) {
      this.syncDayTarget.disabled = true
      this.syncDayTarget.value = ""
    } else if (this.frequencyTarget.value === "Monthly") {
      this.syncDayTarget.disabled = false
      this.syncDayTarget.value = "1"
    }
  }

  emulateFlash(message, status) {
    const innerElement = `<div class='alert alert-${status} alert-dismissible animated fadeIn' style='margin:10px'><button class='close' data-dismiss='alert'>×</button>${message}</div>`

    $(".billing-sync-alert").empty().append(innerElement)
  }

  syncNow(e) {
    this.syncNowButtonTarget.classList.add("disabled")

    $.ajax({
      url: Routes.sync_billing_account_integrations_autotask_path(this.integrationIdTarget.textContent),
      method: "PATCH",
      contentType: "application/json",
      dataType: "json",
      success: (response) => {
        e.preventDefault()
        if (response.result) {
          this.emulateFlash("Billing will be synced. Check the billing status table for results.", "success")
        } else {
          this.emulateFlash("Cannot sync billing", "danger")
        }
      }
    })
  }

  handleServiceNameChange(product, event) {
    this[`${product}ServiceMappingNameTarget`].value = event.target.selectedOptions[0].textContent
  }
}
