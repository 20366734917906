addParamToURL= function(url, param, value) {
    if (url.startsWith("/")) {
        let tempUrl = new URL(window.origin + url)
        tempUrl.searchParams.set(param, value)
        return `${tempUrl.pathname}${tempUrl.search}`
    } else {
        let tempUrl = new URL(url)
        tempUrl.searchParams.set(param, value)
        return tempUrl.href
    }
}

navigateSelectOnChange = function(selector) {
    let el = $(selector)
    window.location = addParamToURL(el.attr("href"), el.attr("param"), el.val())
}

module.exports = {
    addParamToURL,
    navigateSelectOnChange,
}
