import FeatureUpdateController from "../../components/feature_update_controller"

export default class extends FeatureUpdateController {
  initializeSelect2() {
    this.initializeEntitySelect2()  
  }

  removeSelect2() {
    $(this.entitySelectTarget).select2("destroy")
    $(this.entitySelectTarget).val(null)
  }

  registerListeners() {
    $(this.entitySelectTarget).on("select2:select select2:clear", this.onEntitySelect.bind(this))
  }

  removeListeners() {
    $(this.entitySelectTarget).off("select2:select select2:clear")
  }
}
