import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

export default class extends Controller {
  static targets = ["classificationsChart", "investigationsChart", "infectionsChart",
    "surveysChart", "newAgentsChart", "removedAgentsChart"]

  connect() {
    new Chart($(this.classificationsChartTarget), {
      type: "horizontalBar",
      data: {
        datasets: [{
          data: huntressData.classification_values,
          backgroundColor: ["#ecf0f1", "#f39c12", "#e74c3c", "#3498db", "#18bc9c"]
        }],
        labels: huntressData.classification_labels,
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            type: "logarithmic"
          }]
        }
      }
    })

    new Chart($(this.investigationsChartTarget), {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.investigation_values,
          backgroundColor: ["#18BC9C"]
        }],
        labels: huntressData.investigation_months
      },
      options: {
        legend: {
          display: false
        },
      }
    })

    new Chart($(this.infectionsChartTarget), {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.infection_values,
          backgroundColor: ["#18BC9C"]
        }],
        labels: huntressData.infection_months
      },
      options: {
        legend: {
          display: false
        },
      }
    })

    new Chart($(this.surveysChartTarget), {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.survey_values,
          backgroundColor: "#18BC9C",
          borderColor: "#18BC9C",
          fill: false
        }],
        labels: huntressData.survey_hours
      },
      options: {
        legend: {
          display: false
        },
      }
    })

    new Chart($(this.newAgentsChartTarget), {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.new_agent_values,
          backgroundColor: "#18BC9C",
          borderColor: "#18BC9C",
          fill: false
        }],
        labels: huntressData.new_agent_hours
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
            }
          }]
        },
      }
    })

    new Chart($(this.removedAgentsChartTarget), {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.removed_agent_values,
          backgroundColor: "#18BC9C",
          borderColor: "#18BC9C",
          fill: false
        }],
        labels: huntressData.removed_agent_hours
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
            }
          }]
        },
      }
    })
  }
}
