import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customDateRangeField"]

  connect() {
    this.filterByType()
  }

  filterByType() {
    let selectedReportType = this._selectedType()

    this._toggleDateRangeFields(selectedReportType)

    this._reports().forEach((report_row, idx) => {
      let rowInformation = [...report_row.cells].map(cell => cell.innerText.toLowerCase())
      if (rowInformation.includes(selectedReportType)) {
        report_row.classList.remove("hidden")
      } else {
        report_row.classList.add("hidden")
      }
    })
  }

  _toggleDateRangeFields(selectedReportType) {
    if (selectedReportType == "custom summary") {
      this.customDateRangeFieldTargets.forEach(dateRangeField => {
        dateRangeField.classList.remove("hidden")
      })
    } else {
      this.customDateRangeFieldTargets.forEach(dateRangeField => {
        dateRangeField.classList.add("hidden")
      })
    }
  }

  _reports() {
    // get all rows in the table body
    return this.element.querySelectorAll("tbody tr")
  }

  _selectedType() {
    let selectedReportType = this.element.querySelector("select#period").value.toLowerCase()
    return selectedReportType
  }
}
