import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
import FieldStatus from "formvalidation/plugins/FieldStatus"
import { updateStrengthBar, setButtonAvailability } from "../../../src/devise/helper"

export default class extends Controller {
  static targets = ["form", "submitButton", "password", "passwordConfirmation"]

  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    const fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "input"
        }),
        fieldStatus: new FieldStatus({
          onStatusChanged: (areFieldsValid) => {
            setButtonAvailability(this.submitButtonTarget, areFieldsValid)
          }
        })
      },
      fields: {
        "user[first_name]": {
          validators: {
            notEmpty: {
              message: "Your first name is required."
            },
            stringLength: {
              min: 2,
              max: 50,
              message: "Your first name must be between 2 and 50 characters."
            }
          }
        },
        "user[last_name]": {
          validators: {
            notEmpty: {
              message: "Your last name is required."
            },
            stringLength: {
              min: 2,
              max: 50,
              message: "Your last name must be between 2 and 50 characters."
            }
          }
        },
        "user[password]": {
          validators: {
            notEmpty: {
              message: "The password is required."
            },
            stringLength: {
              min: this.data.get("minPasswordLength"),
              message: "Your password must be at least " + this.data.get("minPasswordLength").toString() + " characters."
            },
            callback: {
              callback: (input) => {
                // Returns the appropriate state and message
                return updateStrengthBar(input, "#strengthBar")
              }
            }
          }
        },
        "user[password_confirmation]": {
          validators: {
            identical: {
              compare: () => {
                return this.passwordTarget.value
              },
              message: "The password and its confirm are not the same."
            }
          }
        }
      }
    })

    if (this.hasPasswordTarget) {
      // Revalidate the password confirmation when changing the password input field
      this.passwordTarget.addEventListener("input", function() {
        fv.revalidateField("user[password_confirmation]")
      })
    }
  }
}
