import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["footer"]

  connect(){

  }

  addToRemoved(e){
    const target = $(e.target)
    const id = target.data("id").toString()

    $(this.footerTarget).prepend($(`<input type="hidden" id="antivirus-exclusions-removed-${id}" name="antivirus_exclusions_form[antivirus_exclusions_removed][]" value="${id}"/>`))
    this.showRemovedExclusion(id)
  }

  addToVerified(e){
    const target = $(e.target)
    const id = target.data("id").toString()
    $(this.footerTarget).prepend($(`<input type="hidden" id="antivirus-exclusions-verified-${id}" name="antivirus_exclusions_form[antivirus_exclusions_verified][]" value="${id}"/>`))
    this.showVerifiedExclusion(id)
  }

  undo(e){
    const target = $(e.target)
    const id = target.data("id").toString()

    $(`#antivirus-exclusions-verified-${id}`).remove()
    $(`#antivirus-exclusions-removed-${id}`).remove()

    $(`#exclusion-decision-${id}`).fadeOut(()=>{
      $(`#decision-btns-${id}`).fadeIn()
    })
  }

  showRemovedExclusion(id){
    const decisionDiv = $(`#exclusion-decision-${id}`)
    decisionDiv.find(".exclusion-removed").show()
    decisionDiv.find(".exclusion-verified").hide()
    $(`#decision-btns-${id}`).fadeOut(()=> {
      decisionDiv.fadeIn()
    })
  }

  showVerifiedExclusion(id){
    const decisionDiv = $(`#exclusion-decision-${id}`)
    decisionDiv.find(".exclusion-removed").hide()
    decisionDiv.find(".exclusion-verified").show()
  
    $(`#decision-btns-${id}`).fadeOut(()=> {
      decisionDiv.fadeIn()
    })
  }
}
