import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["popover", "template", "newUserNotificationsAlert"]

  connect() {
    let template = this.templateTarget.innerHTML

    $(this.popoverTarget).popover({
      placement: "bottom",
      template: template,
      toggle: "popover",
      trigger: "manual",
    }).on("click", () => {
      if(this.hasNewUserNotificationsAlertTarget) {
        $(this.newUserNotificationsAlertTarget).remove()
        this.markUserNotifcationsRead()
      }

      if($(this.popoverTarget).parent().children(".in").length > 0) {
        $(this.popoverTarget).popover("hide")
      } else {
        $(this.popoverTarget).popover("show")
      }
    })

    $("body").on("click", (evt) => {
      if($(this.popoverTarget).parent().children(".in").length > 0 &&
        !(evt.target == $(this.popoverTarget).parent() || $(evt.target).parents("#user-notifications").length)) {
        $(this.popoverTarget).popover("hide")
      }
    })
  }

  markUserNotifcationsRead() {
    if(!this.element.dataset.userNotifications){
      return
    }

    let userNotificationIds = JSON.parse(this.element.dataset.userNotifications)

    $.ajax({
      url: Routes.mark_notifications_read_user_notifications_path(),
      method: "POST",
      data: JSON.stringify({ user_notification_ids: userNotificationIds }),
      dataType: "json",
      contentType: "application/json",
    })
  }
}
