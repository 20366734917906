import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = ["assignAnalystForm"]
  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    this.formValidation = formValidation(this.assignAnalystFormTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        submitButton: new plugins.SubmitButton(),
        defaultSubmit: new plugins.DefaultSubmit()
      },
      fields: {
        "assigned_to_id": {
          validators: {
            notEmpty: { }
          }
        }
      }
    })
  }

  validateForm() {
    this.formValidation.validateField("assigned_to_id")
  }
}
