// Potentially move each helper out to its own file, but good to be able to import
// multiple things from a general helper library than having to specify a few
// different imports.

function initTable(ajax_url, params) {
  var options = {
    autoWidth: false,
    destroy: true,
    processing: true,
    iDisplayLength: 25,
    stateSave: true,
    stateDuration: 300,
    serverSide: true,
    ajax: ajax_url,
    columnDefs: [
      { targets: "_all", searchable: false }
    ],
    searching: false,
    deferRender: true,
    responsive: true,
    // createdRow: function(row, data, dataIndex) {},
  }

  // Recursively merge the params into the options
  $("#datatable table").dataTable($.extend(true, {}, options, params))
}

function initTableWithChildren(datatable, params, selector) {
  let options = {
    autoWidth: false,
    destroy: true,
    processing: true,
    iDisplayLength: 25,
    lengthMenu: [10, 25, 50, 100, 200, 500],
    stateSave: true,
    stateDuration: 300,
    serverSide: true,
    columnDefs: [
      { targets: "_all", searchable: false }
    ],
    searching: false,
    deferRender: true,
    responsive: true,
    // createdRow: function(row, data, dataIndex) {},
  }
  let table = datatable.DataTable($.extend(true, {}, options, params))
  table.off("click", "tr")
  table.on("click", "tr", rowClicked)

  async function rowClicked(e){
    if(!$(e.target).is("tr") && !$(e.target).is("td"))
      return
    let tr = $(e.currentTarget)
    let row = table.row(tr)
    if (row.child.isShown()) {
      row.child.hide()
      tr.removeClass("shown")
    }
    else {
      let select = tr.find(selector)
      let url = select.data("row-expand-url")
      if(!url)
        return
      let loading = $("<div>Loading...</div>").css({"text-align": "center", "font-size": "2vmin"})
      row.child(loading).show()
      try{
        let data = await getRowData(url)
        loading.remove()
        row.child(data)
      }
      catch(err){
        console.error(err)
        loading.text("Unable to load data at this time")
      }
      tr.addClass("shown")
    }
  }

  async function getRowData(url) {
    let data = await $.ajax({
      url: url
    })
    return $(data)
  }

  return table
}

function joinWithOxford(arr, conjunction, ifEmpty) {
  const len = arr.length
  // If blank return default which can be null
  if(!len) return ifEmpty
  // If there is only one element return that
  if(len == 1) return arr[0]
  // default conjunction if it is not passed
  if(conjunction === undefined) conjunction = "and"
  // If there are only two elements use conjunction without a comma
  if(len == 2) return arr.join(` ${conjunction} `)
  // If length is greater than 2 use oxford comma
  // clone array so we are not altering actual values
  arr = arr.slice()
  // Add conjunction before last element
  arr[len-1] = `${conjunction} ${arr[len-1]}`
  // return joined array
  return arr.join(", ")
}

function flashMessage(message, level, parent, scroll = true) {
  let alert = document.createElement("div")
  alert.classList.add("alert", `alert-${level}`, "animated", "fadeIn")
  let close = document.createElement("button")
  close.classList.add("close")
  close.setAttribute("data-dismiss", "alert")
  close.innerHTML = "×"
  alert.append(close)
  alert.append(message)
  let existing = parent.querySelectorAll(".alert")
  for(let i = 0; i < existing.length; i++){
    existing[i].remove()
  }
  parent.prepend(alert)
  if (scroll)
    window.scrollTo(0, 0)
}

function attachModalLinkEvents() {
  $(this).on("click", ".modal-link", function(e) {
    e.preventDefault()
    $.ajax({
      url: $(this).attr("href"),
      dataType: "json",
      success: (data) => {
        let dialog = $("#details-modal .modal-dialog")
        dialog
          .removeClass(dialog.data("temp-class"))
          .data("temp-class", e.target.dataset.modalDialogClass)
          .html(data.content)
          .addClass(e.target.dataset.modalDialogClass)
        $("#details-modal").modal("show")
      },
      error: function(err) {
        if(err.status == 404) {
          const errorHtml = `
              <div class="modal-content">
                <div class="modal-header">
                  <a class="close" data-dismiss="modal">&times;</a>
                  <h4>Record Not Found</h4>
                </div>
                <div class="modal-body">
                  <p>This is most likely due to the referenced object having been deleted.</p>
                </div>
              </div>
            `
          $("#details-modal .modal-dialog").html(errorHtml)
          $("#details-modal").modal("show")
        }
        console.log(err)
      }
    })
  })
}

async function flashHighlightId(element, time, highlightColor = "#00aeb8") {
  return new Promise((resolve) => {
    element.get(0).style.setProperty("--flash-highlight-color", highlightColor)
    element.addClass("flash-highlight").css({"animation-duration": `${time}ms`})
    setTimeout(()=>{
      element.removeClass("flash-highlight")
      resolve()
    }, time)
  })
}

async function fadeInOut(element) {
  return new Promise((resolve) => {
    element.addClass("fade-in-out")
    setTimeout(() => {
      element.removeClass("fade-in-out")
      resolve()
    }, 5000)
  })
}

function parseNestedFormData(formData){
  const formObject = {}
  formData.forEach(function(value, key){
    if (!key.includes("[")) 
      formObject[key] = value
    else {
      const parentKey = key.slice(0, key.indexOf("["))
      const childKey = key.slice(key.indexOf("[") + 1, -1)
      formObject[parentKey] = formObject[parentKey] ?? {}
      formObject[parentKey][childKey] = value
    }
  })

  return formObject
}

export {
  initTable,
  joinWithOxford,
  initTableWithChildren,
  flashMessage,
  attachModalLinkEvents,
  flashHighlightId,
  fadeInOut,
  parseNestedFormData
}
