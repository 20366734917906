import { Controller } from "@hotwired/stimulus"

// This component represents an input that will display its "previous value"
// when its current value changes, and (eventually) will have a "revert" button
// that resets it to its previous value. Any further customization is welcome!
//
// To set up the component, define an input in HAML like usual, specifying this
// component for its controller:
//
//   = f.input :tenant_id, input_html: { data: { controller: "components--revertible-input", target: "#{controller}.tenantId" } }
//
// By default, we assume that there is no previous value. To specify one, set
// the element's data-previous-value attribute like so:
//
// this.tenantIdTarget.dataset.previousValue = this.previousData.tenant_id
//
// To clear the previous value, delete the attribute (since setting the data
// attribute to null or undefined will set "null" or "undefined" as a string
// instead):
//
// delete this.tenantIdTarget.dataset.previousValue
//
// When the user makes changes to the input, the control will show a
// "Previously: X" label beneath the input if the current value differs from
// the previous value, and hide it if they're the same.

export default class extends Controller {
  connect() {
    this.initObserver()
    this.initPreviousField()

    // Store the event handler so we can remove it later
    this.inputEventHandler = event => { this.updatePreviousValueLabel() }

    this.element.addEventListener("input", this.inputEventHandler)
  }

  initObserver() {
    this.observer = new MutationObserver((mutationList, observer) => {
      mutationList.forEach(mutation => {
        // The "previous value"  of the input changed, let's reset our current
        // value to match it
        this.resetCurrentValue()
      })
    })

    this.observer.observe(this.element, { attributes: true, attributeFilter: ["data-previous-value"] })
  }

  initPreviousField() {
    const previousValueLabel = document.createElement("small")
    previousValueLabel.className = "previous-value"

    this.previousValueLabel = previousValueLabel
    this.element.insertAdjacentElement("afterend", previousValueLabel)
  }

  disconnect() {
    this.observer.disconnect()
    this.element.removeEventListener("input", this.inputEventHandler)
  }

  resetCurrentValue() {
    this.currentValue = this.previousValue || ""
    this.updatePreviousValueLabel()
  }

  updatePreviousValueLabel() {
    if (this.previousValue == null || this.element.value === this.previousValue) {
      this.previousValueLabel.style.display = "none"
    } else {
      this.previousValueLabel.textContent = `Previously: ${this.previousValue}`
      this.previousValueLabel.style.display = null
    }
  }

  get previousValue() {
    return this.element.dataset.previousValue
  }

  set previousValue(value) {
    this.element.dataset.previousValue = value
  }

  get currentValue() {
    return this.element.value
  }

  set currentValue(value) {
    this.element.value = value
  }
}
