import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        trigger: new plugins.Trigger()
      },
      fields: this.fields()
    }).on("plugins.message.displayed", (event) => {
      this.removeServerValidationMessage(event)
    })
  }

  fields() {
    return {
      body: {
        selector: '[name="comment[body]"]',
        validators: {
          regexp: {
            message: "Enter five words or more.",
            regexp: /(\w+.*\s+.*){4,}\w/
          },
          notEmpty: {
            message: "Enter five words or more."
          }
        }
      }
    }
  }

  removeServerValidationMessage(event) {
    const helpBlocks = event.messageElement.parentNode.parentNode.querySelectorAll("span.help-block")
    helpBlocks.forEach(helpBlock => {
      helpBlock.remove()
    })

  }
}
