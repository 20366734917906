import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  turboSubmit() {
    this.formTarget.requestSubmit()
  }

  preventEnterKeySubmit(event) {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }
}
