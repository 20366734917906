import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "ajaxModal",
    "ajaxModalContent",
    "ajaxModalLoading",
    "ajaxModalError",
  ]

  connect() {
    this.element[this.identifier] = this
  }

  getModalElement() {
    return this.ajaxModalTarget
  }

  openAjaxModalEvent({ detail: { options, showLoading = true, reloadOnClose = false, modalClass = null } }) {
    this.openAjaxModal(options, showLoading, reloadOnClose, modalClass)
  }

  openAjaxModal(options, showLoading = true, reloadOnClose = false, modalClass = null) {
    this.resetModalDialogClass()

    if (reloadOnClose) {
      $(this.ajaxModalTarget).on("hidden.bs.modal", () => {
        $(window).scrollTop(0)
        location.reload()
      })
    }

    let ajaxOptions = {
      type: "get",
      success: (response) => this.showAjaxModalContent(response),
      error: () => this.showAjaxModalError()
    }

    if (options instanceof Event) {
      options.preventDefault()

      if (options.currentTarget.dataset.modalClass) {
        this.addModalDialogClass(options.currentTarget.dataset.modalClass)
      }

      ajaxOptions.url = options.currentTarget.dataset.href
      if (options.currentTarget.dataset.ajaxMethod) {
        ajaxOptions.type = options.currentTarget.dataset.ajaxMethod
      }

      try {
        ajaxOptions.data = JSON.parse(options.currentTarget.dataset.ajaxData)
      } catch {
        // Leave data empty
      }
    } else {
      if (modalClass) {
        this.addModalDialogClass(modalClass)
      }

      ajaxOptions = {
        ...ajaxOptions,
        ...options
      }
    }

    this.showAjaxModalLoading()

    if(showLoading) {
      $(this.ajaxModalTarget).modal("show")
    }


    return $.ajax(ajaxOptions)
  }

  hideAjaxModal() {
    let modal = $(this.ajaxModalTarget)

    return new Promise((resolve) => {
      if(modal.hasClass("in")) {
        modal.one("hidden.bs.modal", () => resolve())
        modal.modal("hide")
      }
      else {
        resolve()
      }
    })
  }

  showAjaxModalLoading() {
    this.ajaxModalContentTarget.style.display = "none"
    this.ajaxModalLoadingTarget.style.display = "block"
    this.ajaxModalErrorTarget.style.display = "none"
  }

  showAjaxModalError() {
    this.ajaxModalContentTarget.style.display = "none"
    this.ajaxModalErrorTarget.style.display = "block"
    this.ajaxModalLoadingTarget.style.display = "none"
  }

  showAjaxModalContent(content = null) {
    if (content) {
      this.ajaxModalContentTarget.innerHTML = content
    }
    this.ajaxModalContentTarget.style.display = "block"
    this.ajaxModalLoadingTarget.style.display = "none"
    this.ajaxModalErrorTarget.style.display = "none"
    $(this.ajaxModalTarget).modal("show")
  }

  getModalDialogContent() {
    return this.ajaxModalContentTarget
  }

  resetModalDialogClass() {
    this.ajaxModalContentTarget.setAttribute("class", "modal-dialog ajax-modal-content")
    this.ajaxModalLoadingTarget.setAttribute("class", "modal-dialog ajax-modal-loading")
    this.ajaxModalErrorTarget.setAttribute("class", "modal-dialog ajax-modal-error")
  }

  addModalDialogClass(classToAdd) {
    this.ajaxModalContentTarget.classList.add(classToAdd)
    this.ajaxModalLoadingTarget.classList.add(classToAdd)
    this.ajaxModalErrorTarget.classList.add(classToAdd)
  }

  removeModalDialogClass(classToRemove) {
    this.ajaxModalContentTarget.classList.remove(classToRemove)
    this.ajaxModalLoadingTarget.classList.remove(classToRemove)
    this.ajaxModalErrorTarget.classList.remove(classToRemove)
  }
}
