import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
  ]

  initialize() {
    this.productsSelected = new Set()
  }

  connect() {
    this.submitButtonTarget.disabled = true
  }

  productToggled(e) {
    const { product } = e.detail

    if (this.productsSelected.has(product)) {
      this.productsSelected.delete(product)
    } else {
      this.productsSelected.add(product)
    }

    this.submitButtonTarget.disabled = !this.anyOptionSelected
  }

  get anyOptionSelected() {
    return this.productsSelected.size > 0
  }
}
