import { Controller } from "@hotwired/stimulus"

export default class FilterMenuController extends Controller {
  static targets = ["filters"]

  connect() {
    $(this.filtersTarget).find("li").on("click", function (e) {
      e.stopPropagation()
    })

    this.loadPageReloadSettings()
  }

  loadPageReloadSettings() {
    this.pageReload = this.data.get("pageReload") === "true" || this.data.get("pageReload") == null
    this.tableDivElementId = this.data.get("tableDivElementId")
  }

  applyFilters() {
    if (this.pageReload || !this.tableController) {
      location.search = this.search.toString()
    } else {
      this.tableController.reload(this.filtersObject)
      $(document).trigger("filter_menu.updateCount", this.filtersCount)
    }
  }

  clearFilters() {
    this.clearCheckedFilters()
    this.applyFilters()
  }

  clearCheckedFilters() {
    $(this.filtersTarget).find("input:checked").prop("checked", false)
  }

  get search() {
    return this.searchFromFilters
  }

  get searchFromFilters() {
    let search = new URLSearchParams()
    for (let filter of this.selectedFilters) {
      if (typeof(filter) === "object")
        for (let group in filter) {
          const value = filter[group]
          if (Array.isArray(value))
            search.append(`filters[${group}][]`, value[0])
          else
            search.set(`filters[${group}]`, value)
        }
      else
        search.set(`filters[${filter}]`, "1")
    }

    return search
  }

  get selectedFilters() {
    let filters = []
    for (let div of $(this.filtersTarget).find("input:checked")) {
      filters.push($(div).data("filter"))
    }
    return filters
  }

  get currentFilters() {
    return JSON.parse(this.data.get("filters")) || {}
  }

  get tableController() {
    return document.querySelector(this.tableDivElementId)["components--table"]
  }

  get filtersObject() {
    let filters = {}

    for(let search of this.search) {
      let categoryRegex = search[0].match(/filters\[(\w+)](\[])?/)
      let filterType = categoryRegex[1]

      if (categoryRegex[2]) {
        let currentValue = filters[filterType] || []
        filters[filterType] = [...currentValue, search[1]]
      } else {
        filters[filterType] = search[1]
      }
    }

    return { filters: filters }
  }

  get filtersCount() {
    let uniqKeys = []
    let count = 0
    for(let key of this.search.keys()) {
      uniqKeys.push(key)
      count++
    }

    if (uniqKeys.includes("filters[from_date]") && uniqKeys.includes("filters[to_date]")) {
      count--
    }

    return count
  }
}
