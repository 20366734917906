import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "refresh"]

  refreshForm() {
    this.refreshTarget.value = true
    this.formTarget.requestSubmit()
  }
}
