import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectCompany",
    "companySelectError",
    "companySelect",
    "createTestTicket",
    "create",
    "find",
    "findTicket",
    "wait",
    "sendTestTicket",
    "errorResult",
    "goToIntegrationSettings",
    "modalTitle",
  ]

  initialize() {
    $("body").on("hidden.bs.modal", ".modal", () => {
      $(this).removeData("bs.modal")
    })

    if (this.data.get("loadError") != null && this.data.get("loadError").toString() == "true") {
      this.displayErrorPanel()
    }
  }

  createTestTicket() {
    const companyId = this.companySelectTarget.value
    if (companyId == null || companyId.length === 0) {
      this.companySelectErrorTarget.style.display = "block"
    } else {
      this.selectCompanyTarget.style.display = "none"
      this.createTestTicketTarget.style.display = "block"
      this.sendTestTicketTarget.classList.add("disabled")

      $.ajax({
        url: this.data.get("ticketCreatePath"),
        data: {
          company_id: companyId
        },
        success: (data) => {
          if (data.error) {
            this.displayErrorPanel(data.result)
          } else {
            this.createTarget.textContent = data.result
            this.waitTarget.style.display = "none"
            if (data.id) {
              this.findTicketTarget.style.display = "block"
              this.findTestTicket(data.id)
            }
          }
        }
      })
    }
  }

  findTestTicket(id) {
    this.waitTarget.style.display = "block"
    $.ajax({
      url: this.data.get("ticketFindPath") + "/?ticket_id=" + id,
      success: (data) => {
        if(data.error) {
          this.displayErrorPanel(data.result)
        } else {
          this.findTarget.textContent = data.result
          this.waitTarget.style.display = "none"
        }
      }
    })
  }

  displayErrorPanel(errorMessage) {
    this.selectCompanyTarget.style.display = "none"

    if (errorMessage === undefined || errorMessage === "") {
      this.errorResultTarget.querySelector("#default-error-message").style.display = "block"
      this.errorResultTarget.querySelector("#error-message").style.display = "none"
    } else {
      this.errorResultTarget.querySelector("#error-message .message").innerText = errorMessage
      this.errorResultTarget.querySelector("#default-error-message").style.display = "none"
      this.errorResultTarget.querySelector("#error-message").style.display = "block"
    }
    this.errorResultTarget.style.display = "block"

    this.sendTestTicketTarget.style.display = "none"
    this.goToIntegrationSettingsTarget.style.display = "inline-block"

    this.modalTitleTarget.textContent = "Unable to Test Integration"
  }
}
