import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "datatable",
    "deleteUserEmail",
    "deleteUserHuntressAccount",
    "deleteUserName",
    "deleteUserPrimaryRole",
    "editUserEmail",
    "editUserHuntressAccount",
    "editUserFirstName",
    "editUserLastName",
    "editUserPrimaryRole",
    "editForm",
    "newAssumableRoles",
    "editAssumableRoles",
    "deleteAssumableRoles",
    "disableUserConfirmButton"
  ]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      columnDefs: [
        // Some email addresses can be very long, so try to prevent dramatic
        // shifts in column widths when we switch pages
        { targets: ["email"], width: "33%" },
        { targets: ["second-factor", "last-sign-in"], searchable: false },
        { targets: ["actions"], sortable: false },
      ],
      destroy: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      language: {
        zeroRecords: "No matching search results available.",
      },
      stateSave: true,
      stateDuration: 300,
      order: [[0, "asc"]],
      searching: true,
      responsive: true,
    })

    $(this.newAssumableRolesTarget).select2({
      theme: "bootstrap",
      width: "100%",
      placeholder: "Role(s)",
      multiple: true
    })
  }

  showDeleteConfirmation(event) {
    const tableRow = event.target.closest("tr")
    if (tableRow == null) {
      return
    }

    const userId = tableRow.dataset["userId"]
    const spacedAssumableRoles = tableRow.dataset["assumableRoles"].replace(/,/g, ", ")

    this.deleteUserNameTarget.innerText = `${tableRow.dataset["firstName"]} ${tableRow.dataset["lastName"]}`
    this.deleteUserEmailTarget.innerText = tableRow.dataset["email"]
    this.deleteUserHuntressAccountTarget.innerText = tableRow.dataset["huntressAccount"] || "N/A"
    this.deleteUserPrimaryRoleTarget.innerText = tableRow.dataset["primaryRole"]
    this.deleteAssumableRolesTarget.innerText = spacedAssumableRoles

    const deleteForm = document.forms["delete-user-confirm"]
    deleteForm.action = `${deleteForm.dataset["baseUrl"]}/${userId}`

    $("#delete-user-modal").modal("show")
  }

  showDisableConfirmation(event) {
    const tableRow = event.target.closest("tr")
    if (tableRow == null) {
      return
    }

    const userId = tableRow.dataset["userId"]
    this.disableUserConfirmButtonTarget.href = Routes.disable_admin_user_path(userId)

    $("#disable-user-modal").modal("show")
  }

  showEditUserForm(event) {
    const tableRow = event.target.closest("tr")
    if (tableRow == null) {
      return
    }

    const userId = tableRow.dataset["userId"]
    this.editUserFirstNameTarget.value = tableRow.dataset["firstName"]
    this.editUserLastNameTarget.value = tableRow.dataset["lastName"]
    this.editUserEmailTarget.value = tableRow.dataset["email"]
    this.editUserHuntressAccountTarget.value = tableRow.dataset["huntressMembershipValue"]

    // Only show admin dropdown option if the user being edited is an admin or the editing user is an admin
    if (tableRow.dataset["primaryRole"] !== "admin" && this.element.dataset.canCreateAdmin === "false") {
      document.querySelector("#user_role option[value=admin]")?.remove()
    } else if (!document.querySelector("#user_role option[value=admin]")) {
      const adminOption = new Option("Admin", "admin", false, false)
      this.editUserPrimaryRoleTarget.add(adminOption, 1)
    }
    this.editUserPrimaryRoleTarget.value = tableRow.dataset["primaryRole"]

    $(this.editAssumableRolesTarget).select2({
      theme: "bootstrap",
      width: "100%",
      placeholder: "Role(s)",
      multiple: true,
    }).val(tableRow.dataset["assumableRoles"].split(",")).trigger("change")

    this.editFormTarget.action = `${this.editFormTarget.dataset["baseUrl"]}/${userId}`

    $("#edit-user").modal("show")
  }
}
