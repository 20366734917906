import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["focusedWindow", "focusedWindowContent"]

  initialize() {
    this.onExternalClick = this.onExternalClick.bind(this)
  }

  connect() {
    document.addEventListener("click", this.onExternalClick)
  }

  disconnect() {
    document.removeEventListener("click", this.onExternalClick)
  }

  onExternalClick(e) {
    if(this.element.contains(e.target) || this.focusedWindowTarget.contains(e.target)) {
      return
    }
    if(this.hasFocusedWindowContentTarget && this.focusedWindowContentTarget.controller) {
      this.focusedWindowContentTarget.controller.resetState()
    }
    this.focusedWindowTarget.classList.remove("active")
  }

  onActionClick() {
    this.focusedWindowTarget.classList.toggle("active")
  }
}
