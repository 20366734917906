import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.show) {
      $(this.element).modal("show")
    }
  }

  get show() {
    let value = this.data.get("show")
    return value == "1" || value == "true"
  }
}
