import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "blockIpTargetSelect",
    "blockedIPListContainer",
    "blockedIPsList",
    "ipBlockListSubtext",
    "isolateOnSendOffRadio",
    "isolateOnSendOnRadio",
    "isolationField",
    "sendBtn",
    "strictIsolationCheckbox"
  ]

  static values = {
    invalidIps: Object
  }

  connect() {
    this.registerIsolationEvents()
    this.setIsolationFieldState()
    this.setStrictIsolationCheckboxState(true)

    this.setupBlockIPSelect2()
    this.showOrHideBlockedIpsListSubtext()
  }

  registerIsolationEvents() {
    if(!this.hasIsolateOnSendOffRadioTarget || !this.hasIsolateOnSendOnRadioTarget)
      return

    $(this.isolateOnSendOffRadioTarget).on("change", () => {
      this.setStrictIsolationCheckboxState(false)
      this.enableOrDisableIpBlockElements()
    })
    $(this.isolateOnSendOnRadioTarget).on("change", () => {
      this.setStrictIsolationCheckboxState(true)
      this.enableOrDisableIpBlockElements()
    })
  }

  setStrictIsolationCheckboxState(state) {
    if (!this.hasStrictIsolationCheckboxTarget)
      return

    let checkbox = $(this.strictIsolationCheckboxTarget)
    if(state){
      checkbox.attr("disabled", false)
    }
    else{
      checkbox.attr("disabled", true)
      checkbox.prop("checked", false)
    }
  }

  enableOrDisableIpBlockElements() {
    this.setBlockedIPsEnabled()
    this.showOrHideBlockedIpsListSubtext()
    this.setSendButtonEnabled()
  }

  setBlockedIPsEnabled() {
    if (!this.hasBlockedIPsListTarget || !this.hasBlockIpTargetSelectTarget)
      return

    const disabled = this.blockIpTargetSelectTarget.value == "none"
    $(this.blockedIPsListTarget).attr("disabled", disabled)
  }

  setSendButtonEnabled() {
    if (!this.hasBlockedIPsListTarget || !this.hasBlockIpTargetSelectTarget || !this.hasSendBtnTarget)
      return

    const disabled = this.blockIpTargetSelectTarget.value != "none" && ($(this.blockedIPsListTarget).find(":selected").length == 0 || Object.keys(this.invalidIpsValue).length != 0)
    $(this.sendBtnTarget).attr("disabled", disabled)
  }

  setIsolationFieldState() {
    if(!this.hasIsolationFieldTarget || !this.eligibleForIsolation)
      return

    if (this.eligibleForIsolation) {
      this.isolationFieldTarget.classList.remove("hidden")
    } else {
      this.isolationFieldTarget.classList.add("hidden")
      $(this.isolateOnSendOffRadioTarget).prop("checked", false)
    }
  }

  get eligibleForIsolation() {
    return this.data.get("eligibleForIsolation") === "true"
  }

  setupBlockIPSelect2() {
    if (!this.hasBlockedIPsListTarget)
      return

    $(this.blockIpTargetSelectTarget).select2({
      theme: "bootstrap",
      width: "resolve",
    })

    $(this.blockIpTargetSelectTarget).on("select2:select", this.enableOrDisableIpBlockElements.bind(this))
  }

  selectIp(e) {
    if (!e.detail.blockable)
      this.addInvalidIp(e.detail.ip, e.detail.reason)

    this.enableOrDisableIpBlockElements()
  }

  removeIp(e) {
    this.removeInvalidIp(e.detail.ip)
    this.enableOrDisableIpBlockElements()
  }

  showOrHideBlockedIpsListSubtext() {
    if (!this.hasBlockIpTargetSelectTarget)
      return

    const hidden = this.blockIpTargetSelectTarget.value == "none"
    this.ipBlockListSubtextTarget.hidden = hidden
  }

  addInvalidIp(ip, reason) {
    let tmp = this.invalidIpsValue
    tmp[ip] = reason
    this.invalidIpsValue = tmp
  }

  removeInvalidIp(ip) {
    let tmp = this.invalidIpsValue
    delete tmp[ip]
    this.invalidIpsValue = tmp
  }
}
