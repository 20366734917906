import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable"]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: huntressData.admin_organizations_path,
    })
  }
}

