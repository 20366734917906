import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
export default class extends Controller {
  static targets = ["serviceBoard", "company", "submitButton", "form"]

  connect() {
    $(this.serviceBoardTarget).select2({
      theme: "bootstrap",
      width: "100%",
    })

    $(this.companyTarget).select2({
      theme: "bootstrap",
      placeholder: "Select a company...",
      ajax: {
        url: this.data.get("companiesUrl"),
        headers: {"X-CSRF-Token": $("meta[name=csrf-token]").attr("content") },
        method: "POST",
        dataType: "json",
        data: this.companiesAjaxData.bind(this),
        delay: 150,
      },
      width: "100%",
      cache: true,
    })

    this.setupFormValidation()
    $(this.companyTarget).on("select2:select select2:unselect", this.validateField.bind(this, "defaultCompany"))
  }

  companiesAjaxData(params) {
    return { term: params.term, page: params.page, integrations_connect_wise: JSON.parse(this.data.get("authenticationDetails")) }
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3(),
          trigger: new plugins.Trigger({
            event: "blur"
          })
        },
        fields: {
          defaultServiceBoard: {
            selector: '[name="integrations_connect_wise[default_service_board_id]"]',
            validators: {
              notEmpty: { message: "ConnectWise host or domain name is required." },
            }
          },
          defaultCompany: {
            selector: '[name="integrations_connect_wise[default_company_id]"]',
            validators: {
              notEmpty: { message: "Company selection is required." },
            }
          },
        }
      }
    )
  }

  validateField(field) {
    this.fv.validateField(field)
    this.toggleSubmitButtonDisabled()
  }

  toggleSubmitButtonDisabled() {
    this.fv.validate().then(res => {
      this.submitButtonTarget.disabled = res === "Inalid"
    })
  }
}
