import { Controller } from "@hotwired/stimulus"
import { getSelectedIds } from "../../../helpers/bulkActionHelpers"

export default class extends Controller {
  bulkResolve(event) {
    const url = event.target.dataset.href
    const ids = getSelectedIds()
    const options = {
      url: `${url}?infection_report_ids=${ids}`,
    }

    this.dispatch("openModal", {
      detail: {
        options: options,
      },
      prefix: false
    })
  }
}
