/* eslint-disable */

function formatCurrency(currency, cents) {
  const formatter = new Intl.NumberFormat("en-us", { style: "currency", currency: currency, currencyDisplay: "narrowSymbol" })

  return formatter.format(cents / 100)
}

export {
  formatCurrency
}
