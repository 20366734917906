import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scrollTo"]

  scrollToTargetConnected(element) {
    document.addEventListener("turbo:frame-render", (_) => {
      this.element.scrollTop = element.offsetTop - this.element.offsetTop
    }, { once: true })
  }
}
