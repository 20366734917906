import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "introPanel",
    "expandButton"
  ]

  static values = {
    product: String
  }

  toggleProduct(e) {
    e.preventDefault()
    this.introPanelTarget.hidden = true
    this.dispatch("productToggled", { detail: { product: this.productValue } })
  }

  onProductToggled(e) {
    const { product } = e.detail
    if (product !== this.productValue) this.introPanelTarget.hidden = false
  }
}
