import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]

  connect() {
    this.onLoad()
    this.imageTarget.onload = this.onLoad.bind(this)
  }

  disconnect() {
    this.imageTarget.onload = null
  }

  onLoad() {
    const { naturalWidth, naturalHeight } = this.imageTarget

    if(naturalWidth > naturalHeight) {
      this.imageTarget.style.width = "100%"
      this.imageTarget.style.height = "auto"
    }
    else {
      this.imageTarget.style.width = "auto"
      this.imageTarget.style.height = "100%"
    }
  }
}
