import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["banner"]

  disableBanner() {
    // Optimistically hide the banner before we send the request to the server
    $(this.bannerTarget).fadeOut()

    // Technically, this is not a modal, but we use the functionality provided
    // by the disable_modal endpoint to record that the user has closed the
    // banner and should not see it in the future.
    const disableModalUrl = Routes.users_disable_modal_account_path({ format: "js" })

    $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ modal_type: "neighborhood_watch_enabled" }),
      method: "PATCH",
      url: disableModalUrl,
    })
  }
}
