import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sortablePositionInput" ]

  connect() {
    $(this.element).sortable({
      handle: ".handle",
      items: "tr.sortable",
      update: () => {
        this.#updatePositionsInputs()
      }
    })
    this.#updatePositionsInputs()
  }

  sendToTop(e) {
    const row = $(e.target).closest("tr")
    row.insertBefore(this.element.querySelector("tr:first-of-type.sortable"))

    this.#updatePositionsInputs()
  }

  sendToBottom(e) { 
    const row = $(e.target).closest("tr")
    row.insertAfter(this.element.querySelector("tr:last-of-type.sortable"))

    this.#updatePositionsInputs()
  }

  #updatePositionsInputs() {
    this.sortablePositionInputTargets.forEach((element, index) => {
      let previous_element_value = element.value
      element.value = index + 1

      if (previous_element_value !== element.value) {
        element.dispatchEvent(new Event("change", { bubbles: true }))
      }
    })
  }
}
