import { Controller } from "@hotwired/stimulus"
import { attachModalLinkEvents } from "huntressHelpers"

export default class extends Controller {
  static targets = ["table"]

  connect() {
    const perPageCount = $(this.tableTarget).data("per-page") || 25
    let order = $(this.tableTarget).data("order") || [0, "asc"]
    let columnDefs = $(this.tableTarget).data("column-defs") || []

    let ordering = true
    if($(this.tableTarget).data("ordering") !== undefined) {
      ordering = $(this.tableTarget).data("ordering")
    }

    let options = this.tableTarget.dataset.options ? JSON.parse(this.tableTarget.dataset.options) : {}
    let default_options = {
      pageLength: perPageCount,
      ordering: ordering,
      order: order,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      columnDefs: columnDefs,
    }
    options = {
      ...default_options,
      ...options
    }

    $(this.tableTarget).DataTable(options)

    $(this.tableTarget).on("click", "tr[data-href]", function(e) {
      if($(e.target).closest("a, tr")[0] instanceof HTMLAnchorElement) {
        return
      }
      window.document.location = $(this).data("href")
    })

    attachModalLinkEvents.bind(this.tableTarget)()
  }
}
