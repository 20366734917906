import SimpleSelect2Controller from "controllers/components/simple_select2_input_controller"

export default class extends SimpleSelect2Controller {
  static targets = ["submitButton"]

  connect() {
    super.connect()

    this.submitButton = document.getElementById("subscribe-confirm-show")

    const data = JSON.parse(this.element.dataset.subsData)
    const $selectEl = $(this.element)
    $selectEl.val(data.map(sub => sub.id)).trigger("change")
    $selectEl.on("select2:select", this.handleSelect.bind(this))
    $selectEl.on("select2:unselect", this.handleUnselect.bind(this))
  }

  handleUnselect(event) {
    const noneSelected = $(event.target).find(":selected").length === 0
    this.toggleButtonDisabled(noneSelected)
  }

  handleSelect() {
    this.toggleButtonDisabled(false)
  }

  toggleButtonDisabled(disable) {
    if (disable) {
      this.submitButton.setAttribute("disabled", true)
    } else {
      this.submitButton.removeAttribute("disabled")
    }
  }
}
