import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable"]

  connect() {
    $(this.datatableTarget).dataTable({
      iDisplayLength: 50,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
    })
  }
}
