import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import { RuleBlock } from "ruleHelpers"
import { DATEPICKER_SETTINGS } from "../../components/datepicker_controller"

export default class extends Controller {
  static targets = [
    "modalBody",
    "nameField",
    "rulesBlock",
    "scopeContainer",
    "endDateContainer",
    "endDateRequiredStar",
    "endDateField",
    "clearEndDate",
    "ruleSubmit",
    "rulePreview",
    "rulePreviewLoading",
    "retroactiveRuleLabel",
    "applyRetroactiveInput",
    "rulePreviewTimeout",
    "rulePreviewError",
    "rulePreviewResults",
    "rulePreviewResultCount",
    "previewRuleTrigger",
    "rulePreviewContainer",
    "reasonField",
    "commentField",
    "summaryField"
  ]

  ruleActionButtonClasses = ".ruleAndBtn, .ruleOrBtn, .removeRuleSegment, .or_value, .expression_delete, .removeRuleBlock"
  ruleBlocks = {}
  ruleCounter = 0
  currentPreviewRequest = null

  connect() {
    this.submitClicked = false
    this.ruleableData = JSON.parse(this.data.get("ruleableData"))
    this.attachEventListeners()
    this.setupEndDatePicker()
    this.prepopulateRule(this.data.get("type"), this.ruleableData)
    this.showPreviewRule()
    this.updateScopeWarning()
    this.updateRuleFromSelectedScope()
    this.previewComplete = false
  }

  attachEventListeners() {
    $(this.modalBodyTarget).on("click", this.ruleActionButtonClasses, (e) => {
      this.showPreviewRule()
    })

    $(this.modalBodyTarget).on("change", "input, select, textarea", (e) => {
      if ((e.target !== this.nameFieldTarget) &&
        (e.target !== this.reasonFieldTarget) &&
        (e.target !== this.commentFieldTarget) &&
        (e.target !== this.endDateFieldTarget)) {
        this.showPreviewRule()
      } else {
        this.updateErrorClasses()
      }
      this.setSummaryField()
    })

    $(this.modalBodyTarget).on("click", ".ruleOrBtn", (e) => {
      this.addRuleBlock()
    })

    $(this.modalBodyTarget).on("click", ".removeRuleBlock", (e) => {
      this.deleteRuleBlock(e)
    })

    $(this.ruleSubmitTarget).on("click", (e) => {
      this.ruleSubmit(e)
    })
  }

  get selectedScope() {
    return this.scopeContainerTarget.querySelector(".scope_radio:checked")
  }

  updateRuleFromSelectedScope() {
    this.previewComplete = false
    let record = $(this.modalBodyTarget).data("record")
    let type = this.data.get("type")
    let scope = this.selectedScope.value

    this.resetPreviewTimeout()

    if (this.selectedScope.dataset.endDateRequired) {
      this.setEndDateToRequired()
    } else {
      this.setEndDateToOptional()
    }

    this.setupEndDatePicker()
    this.showRulePreviewContainer()

    switch (scope){
    case "entity":
      this.setRuleName(type, record, record["entityable_display"])
      break
    case "organization":
      this.setRuleName(type, record, record["organization_name"])
      break
    case "account":
      this.setRuleName(type, record, record["account_name"])
      break
    case "global":
      this.setRuleName(type, record, "Global")
      break
    }
    this.updateScopeWarning()
    this.showPreviewRule()
  }

  showRulePreviewContainer() {
    $(this.rulePreviewContainerTarget).show()
  }

  ruleSubmit(event) {
    this.submitClicked = true
    this.updateErrorClasses()

    if (this.isRuleValid()) {
      $(event.currentTarget).attr("disabled", true).css("pointer-events", "none")
      this.submit()
    }
  }

  submit() {
    $.ajax({
      url: Routes.admin_rules_path(),
      method: "POST",
      data: JSON.stringify(this.buildRule()),
      dataType: "json",
      contentType: "application/json",
      complete: (xhr) => {
        location.reload()
      }
    })
  }

  bindTooltip(e){
    // Binding the data-toggle attribute as an event
    // because when building a select2 select element, any preset "data-x" attributes are not applied
    // and the title attribute is changed.
    $(".select2-selection__choice").attr("data-toggle", "tooltip")
    $(".select2-selection__choice").tooltip()
  }

  buildRule() {
    let condition
    if (Object.keys(this.ruleBlocks).length > 1){
      condition = {
        operator: "OR",
        expressions: []
      }
      for (let index in this.ruleBlocks){
        if (this.ruleBlocks[index].value)
          condition.expressions.push(this.ruleBlocks[index].value)
      }
    }
    else if (Object.keys(this.ruleBlocks).length === 1){
      for (let index in this.ruleBlocks){
        let val = this.ruleBlocks[index].value
        if (val.operator)
          condition = val
        else
          condition = {
            operator: "OR",
            expressions: [val]
          }
      }
    }
    else {
      return
    }
    let rule_options = JSON.parse(this.data.get("rule-options"))
    let rule = {
      name: $(this.nameFieldTarget).val(),
      ends_at: $(this.endDateFieldTarget).val(),
      ruleable_type: this.data.get("ruleable-type"),
      ruleable_id: this.data.get("ruleable-id"),
      signal_source_type: this.ruleableData["signal_source_type"],
      filter_attributes: {
        condition: condition,
        event: rule_options.filter.event,
        model: rule_options.filter.model
      },
      action_attributes: {
        name:  rule_options.action.name,
        model: rule_options.filter.model
      },
      scopes_attributes: [this.getScopeExpression()],
      comments_attributes: [{
        body: $(this.commentFieldTarget).val()
      }],
      details: rule_options.details,
      reasons: $(this.reasonFieldTarget).val(),
    }
    return rule
  }

  getScopeExpression() {
    let record = $(this.modalBodyTarget).data("record")
    switch (this.selectedScope.value) {
    case "entity":
      return {
        scope_type: record.entityable_type,
        scope_id: record.entityable_id
      }
    case "organization":
      return {
        scope_type: "Organization",
        scope_id: record.organization_id
      }
    case "account":
      return {
        scope_type: "Account",
        scope_id: record.account_id
      }
    }
  }

  get scopeSummary() {
    let input = $("input.scope_radio:checked")[0]

    switch (input.value) {
    case "entity":
      return `on ${input.dataset["scopeTypeDisplay"]} ${input.dataset["name"]}`
    case "organization":
      return `on Organization ${input.dataset["name"]}`
    case "account":
      return `on Account ${input.dataset["name"]}`
    default:
      return "globally"
    }
  }

  addRuleBlock() {
    let new_rule_block = new RuleBlock(++this.ruleCounter, this)
    this.ruleBlocks[this.ruleCounter] = new_rule_block
    new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
  }

  deleteRuleBlock(e){
    let index = $(e.currentTarget).data("index")
    this.ruleBlocks[index].destroy()
    delete this.ruleBlocks[index]
  }

  previewRule(event){
    if (this.hasRuleSegments()) {
      const rule_data = JSON.stringify(this.buildRule())

      this.rulePreviewLoadingTarget.style.display = ""
      this.rulePreviewResultsTarget.style.display = "none"
      this.rulePreviewTarget.style.display = "none"
      this.rulePreviewErrorTarget.style.display = "none"

      if (this.currentPreviewRequest && this.currentPreviewRequest.readyState !== 4) {
        this.currentPreviewRequest.abort()
      }

      this.previewComplete = false

      this.currentPreviewRequest = $.ajax({
        url: Routes.preview_admin_rules_path(),
        method: "POST",
        data: rule_data,
        dataType: "json",
        contentType: "application/json",
        success: (data) => {
          this.rulePreviewLoadingTarget.style.display = "none"

          if (data["preview_message"] !== undefined) {
            this.rulePreviewResultCountTarget.innerHTML = data["preview_message"]
            this.rulePreviewResultsTarget.style.display = ""
            this.previewComplete = true
          } else if (data["status"] !== undefined && data["status"] === "timeout") {
            this.previewTimedOut()
            this.previewComplete = true
          } else {
            this.previewError()
          }
        },
        error: () => {
          this.previewError()
        },
        complete: () => {
          this.updateErrorClasses()
        }
      })
    } else {
      this.showPreviewRule()
    }
  }

  isRuleValid() {
    return this.hasRuleName() &&
      this.hasRuleReasons() &&
      this.hasRuleCommentWithTwoWords() &&
      this.hasRuleSegments() &&
      this.hasEndDate() &&
      this.previewComplete
  }

  hasRuleName() {
    return this.nameFieldTarget.value !== ""
  }

  isRetroactive() {
    try {
      let ruleOptions = JSON.parse(this.data.get("rule-options"))
      return ruleOptions.details.retroactive === true
    } catch (error) {
      return true
    }
  }

  previewTimedOut() {
    this.rulePreviewLoadingTarget.style.display = "none"
    this.rulePreviewErrorTarget.style.display = "none"
    this.rulePreviewTimeoutTarget.style.display = ""
    this.retroactiveRuleLabelTarget.style.display = "none"
    this.rulePreviewContainerTarget.style.display = "none"
    this.setRetroactive(false)
  }

  resetPreviewTimeout() {
    this.rulePreviewLoadingTarget.style.display = ""
    this.rulePreviewErrorTarget.style.display = ""
    this.rulePreviewTimeoutTarget.style.display = "none"
    this.retroactiveRuleLabelTarget.style.display = ""
    this.rulePreviewContainerTarget.style.display = ""
    this.setRetroactive(true)
  }

  previewError() {
    this.rulePreviewTimeoutTarget.style.display = "none"
    this.rulePreviewLoadingTarget.style.display = "none"
    this.rulePreviewErrorTarget.style.display = ""
  }

  setRetroactive(retroactive) {
    let ruleOptions = JSON.parse(this.data.get("rule-options"))
    ruleOptions.details.retroactive = retroactive
    this.data.set("rule-options", JSON.stringify(ruleOptions))
  }

  hasRuleSegments(){
    return Object.keys(this.ruleBlocks).some( (key) => {
      const block = this.ruleBlocks[key]
      return (block != null && Object.keys(block.rule_segments).length > 0)
    })
  }

  hasRuleReasons(){
    return $(this.reasonFieldTarget).val().length > 0
  }

  hasRuleCommentWithTwoWords() {
    return this.commentFieldTarget.value.match(/\w+\s+\w+/)
  }

  hasEndDate() {
    return this.endDateFieldTarget.value || !this.endDateContainerTarget.hasAttribute("data-required")
  }

  showPreviewRule(){
    if (this.hasRuleSegments()) {
      this.previewRuleTriggerTarget.title = ""
    } else {
      this.previewRuleTriggerTarget.title = "A rule must have at least one condition."
    }

    if (this.currentPreviewRequest && this.currentPreviewRequest.readyState !== 4) {
      this.currentPreviewRequest.abort()
    }

    this.rulePreviewLoadingTarget.style.display = "none"
    this.rulePreviewResultsTarget.style.display = "none"
    this.rulePreviewErrorTarget.style.display = "none"
    this.rulePreviewTarget.style.display = ""

    this.updateErrorClasses()
  }

  prepopulateRule(type, record){
    switch (type){
    case "Suppress Signal":
    case "Suppress Signal Event": {
      $(this.modalBodyTarget).data("record", record)
      this.setRuleName(type, record, record["entityable_display"])
      this.addRuleBlock()
      break
    }
    case "Suppress Autorun":
    case "Approved Application": {
      $(this.modalBodyTarget).data("record", record)
      this.setRuleName(type, record, record["entityable_display"])
      let pregenerated_filter = {
        "expressions": [{
          "operator": "AND",
          "expressions": [
            {
              "field":"persistence_mechanism.details->>'name'",
              "value": record["persistence_mechanism.details->>'name'"],
              "matcher":"equals"
            },
            {
              "field":"binary_path",
              "value": record.binary_path,
              "matcher":"equals"
            }
          ]}
        ]
      }
      for (let i = 0; i < pregenerated_filter.expressions.length; i++){
        let expression = pregenerated_filter.expressions[i]
        let new_rule_block = new RuleBlock(++this.ruleCounter, this, false, expression)
        this.ruleBlocks[this.ruleCounter] = new_rule_block
        new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
      }
      break
    }
    }
  }

  setRuleName(type, record, scope_name) {
    switch (type){
    case "Suppress Autorun":
    case "Approved Application": {
      $(this.nameFieldTarget).val(`${scope_name}-${record["persistence_mechanism.type"]}-${record["persistence_mechanism.details->>'name'"]}`)
      break
    }
    case "Suppress Signal": {
      $(this.nameFieldTarget).val(`${scope_name}-${record["name"]}`)
      break
    }
    case "Suppress Signal Event": {
      $(this.nameFieldTarget).val(`${scope_name}-${record["signal_name"]}`)
      break
    }
    }
    this.setSummaryField()
  }

  setSummaryField() {
    const name = this.nameFieldTarget.value
    const endDate = this.endDateFieldTarget.value

    let summary = `Creates rule ${name} ${this.scopeSummary}`
    if (endDate) {
      summary += ` until ${endDate}`
    }
    summary += "."

    $(this.summaryFieldTarget).text(summary)
  }

  updateScopeWarning() {
    let scope = $(".scope_radio:checked")[0].value
    if (scope === "global") {
      $(".scope_warning").show()
    } else {
      $(".scope_warning").hide()
    }
  }

  updateErrorClasses() {
    this.updateErrorClass(this.nameFieldTarget, !this.hasRuleName())
    this.updateErrorClass(this.reasonFieldTarget, !this.hasRuleReasons())
    this.updateErrorClass(this.commentFieldTarget, !this.hasRuleCommentWithTwoWords())
    this.updateErrorClass(this.rulesBlockTarget, !this.hasRuleSegments())
    this.updateErrorClass(this.endDateFieldTarget, !this.hasEndDate())
    this.updateErrorClass(this.rulePreviewTarget, !this.previewComplete)
    this.updateErrorClass(this.ruleSubmitTarget, !this.isRuleValid())

    if (this.isRuleValid()) {
      this.submitClicked = false
    }
  }

  updateErrorClass(target, hasError) {
    const elements = $(target).parents("[data-required]")

    if (hasError && this.submitClicked) {
      elements.addClass("has-error")
    } else {
      elements.removeClass("has-error")
    }
  }

  get maxEndDate() {
    let maxEndDateString = this.selectedScope.dataset.maxEndDate
    if (maxEndDateString && typeof maxEndDateString === "string") {
      return new Date(maxEndDateString)
    } else {
      return null
    }
  }

  setupEndDatePicker() {
    this.restrictEndDate()
    $(this.endDateFieldTarget).datepicker("destroy")
    $(this.endDateFieldTarget).datepicker(this.endDatePickerSettings).on("changeDate", this.showClearEndDate.bind(this))
  }

  get endDatePickerSettings() {
    const tooltip = this.selectedScope.dataset.maxEndDateTooltip
    let maxEndDate = this.maxEndDate

    return maxEndDate ? {
      ...this.datePickerSettings,
      endDate: maxEndDate,
      beforeShowDay(date) {
        if (maxEndDate > date) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      },
      beforeShowMonth(date) {
        if (maxEndDate.getFullYear() * 13 + maxEndDate.getMonth() > date.getFullYear() * 13 + date.getMonth()) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      },
      beforeShowYear(date) {
        if (maxEndDate.getFullYear() > date.getFullYear()) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      }
    } : this.datePickerSettings
  }

  get datePickerSettings() {
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getUTCDate() + 1)

    return { ...DATEPICKER_SETTINGS, orientation: "bottom", startDate: tomorrow, clearBtn: true }
  }

  restrictEndDate() {
    if (this.maxEndDate) {
      let endDateInputValue = this.endDateFieldTarget.value

      if (endDateInputValue && new Date(endDateInputValue) > this.maxEndDate) {
        this.endDateFieldTarget.value = null
      }
    }
  }

  showClearEndDate() {
    $(this.clearEndDateTarget).removeClass("invisible")
    this.setSummaryField()
  }

  clearEndDateField() {
    $(this.endDateFieldTarget).val("")
    $(this.clearEndDateTarget).addClass("invisible")
    this.updateErrorClasses()
  }

  setEndDateToRequired() {
    this.endDateContainerTarget.dataset.required = ""
    this.endDateRequiredStarTarget.style.display = ""
  }

  setEndDateToOptional() {
    this.endDateContainerTarget.removeAttribute("data-required")
    this.endDateContainerTarget.classList.remove("has-error")
    this.endDateRequiredStarTarget.style.display = "none"
  }
}
