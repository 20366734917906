import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.dataset.action = "remote-modal#load"
    this.modal = $(this.data.get("modalSelector") || "#ajax-modal")
  }

  load(event) {
    event.preventDefault()

    $.ajax({
      url: this.element.href,
      dataType: "html"
    }).done((html) => {
      this.modal.find(".modal-content").html(html)
      this.modal.modal("show")
    }).fail((xhr, status, error) => {
      alert("Failed to load data: " + error)
    })
  }
}
