import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  dismissNotice() {
    if (huntressData.agent && huntressData.last_isolate_task && huntressData.banner_dismissible) {
      $.ajax({
        url: `${Routes.dismiss_task_failure_organization_agent_path(
          huntressData.agent.organization_id,
          huntressData.agent.id,
          {
            format: "json",
            task_id: huntressData.last_isolate_task.id
          }
        )}`,
        method: "PATCH",
        processData: false,
        contentType: "application/json",
        error: () => {
          // Allow bugsnag to log this unhandled error.
          throw new Error("Did not successfully dismiss the host isolation failure notice.")
        }
      })
    }
  }
}
