import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    product: String
  }

  toggleTable(e) {
    if (e.detail.product == this.productValue) {
      this.element.hidden = false
    } else {
      this.element.hidden = true
    }
  }

  onSetSelectedPlan(e) {
    const { product, price } = e.detail
    this.toggleTable(e)
    if (this.productValue !== product) return

    this.removePrevSelectedPlan()

    if (price && price.id) {
      let priceToSelect = this.element.querySelector(`#${this.productValue}_price_${price.id}`)
      priceToSelect.classList.add("selectedPrice")
    }
  }

  onProductRemoved(e) {
    const product = e.detail.product
    if (this.productValue !== product) return

    this.removePrevSelectedPlan()
  }

  removePrevSelectedPlan() {
    let prevSelected = this.element.querySelector(".selectedPrice")
    if (prevSelected) {
      prevSelected.classList.remove("selectedPrice")
    }
  }
}
