import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["protected", "unhealthy", "incompatible", "unmanaged", "label", "featureModal"]

  connect() {
    const currentFilters = this.currentFilters
    const filterPath = this.filterPath
    const self = this

    this.bulkAgentsSelected = []

    new Chart("antivirus-status-chart", {
      type: "doughnut",
      data: {
        datasets: [{
          data: [this.data.get("protectedCount"), this.data.get("unhealthyCount"), this.data.get("unmanagedCount"), this.data.get("incompatibleCount")],
          backgroundColor: ["#18BC9C", "#f39c12", "#6F42C1", "#e74c3c"]
        }],
        labels: ["Protected Hosts", "Unhealthy Hosts", "Unmanaged Hosts", "Incompatible Hosts"],
      },
      options: {
        legend: {
          display: false
        },
        responsive: false,
        onClick: function (evt) {
          var activeElement = this.getElementAtEvent(evt)
          if (activeElement.length < 1) return
          const filter = [
            "protected",
            "unhealthy",
            "unmanaged",
            "incompatible"
          ][activeElement[0]._index] || "incompatible"
          if (!(filter in currentFilters)) {
            self.tableController.resetPagination()
          }
          window.location.href = filterPath({ filters: { ...currentFilters, [filter]: 1 } })
        }
      }
    })

    const onFilterClick = function (filter) {
      return function (e) {
        e.preventDefault()
        if (!(filter in currentFilters)) {
          self.tableController.resetPagination()
        }
        window.location.href = filterPath({ filters: { ...currentFilters, [filter]: 1 } })
      }
    }

    $(this.protectedTarget).click(onFilterClick("protected"))
    $(this.unhealthyTarget).click(onFilterClick("unhealthy"))
    $(this.unmanagedTarget).click(onFilterClick("unmanaged"))
    $(this.incompatibleTarget).click(onFilterClick("incompatible"))

    $(this.labelTargets).find("a").click(_ => {
      self.tableController.resetPagination()
    })
  }

  get filterPath() {
    const resource = this.data.get("resource")
    return resource === "accounts" ? (
      Routes.account_antiviruses_path
    ) : (
      Routes.organization_antiviruses_path.bind(null, this.data.get("resourceId"))
    )
  }

  get currentFilters() {
    return JSON.parse(this.data.get("filters")) || {}
  }

  get tableController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector("#antivirus-table"), "components--table"
    )
  }

  closeFeatureModal() {
    $(this.featureModalTarget).modal("hide")
  }
}
