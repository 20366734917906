import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["table"]

  connect() {
    const defaultSortColumn = this.tableTarget.dataset.defaultSortColumn
    const displayLength = this.data.has("displayLength") && parseInt(this.data.get("displayLength")) || 25

    var options = {
      pageLength: displayLength,
      stateSave: true,
      stateDuration: 300,
      scrollX: true,
    }

    if (defaultSortColumn) {
      const defaultSortOrder = this.tableTarget.dataset.defaultSortOrder || "asc"
      options["order"] = [[defaultSortColumn, defaultSortOrder]]
    }

    $(this.tableTarget).dataTable(options)
  }
}
