import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets =
    [
      "datatable",
    ]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: [0,1,2,3], orderable: true },
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.admin_agent_usage_account_organization_agent_tombstones_path({
        format: "json",
        account_id: this.data.get("accountId"),
        organization_id: this.data.get("organizationId"),
        start_date: this.data.get("startDate"),
        end_date: this.data.get("endDate"),
      }),
    })
  }
}
