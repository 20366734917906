!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");

if ($('meta[name=user_name]').attr('content')) {
    Canny("identify", {
        appID: "5f7c96a470416966caa73369",
        user: {
            id: $('meta[name=user_id]').attr('content'),
            email: $('meta[name=user_email]').attr('content'),
            name: $('meta[name=user_name]').attr('content'),
            created: $('meta[name=user_registration_date]').attr('content')
        }
    });
}

Canny("initChangelog", {
    appID: "5f7c96a470416966caa73369",
    position: "bottom",
    align: "right"
});
