import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "dirtyVisibles"]

  connect() {
    this.$element = $(this.element)

    this.$element.on(this.$element.data("event"), this.changed.bind(this))
    this.initialValue = this.currentTargetValue
  }

  changed(ev) {
    if (this.initialValue !== this.currentTargetValue) {
      this.$element.addClass("dirty")
      $(this.dirtyVisiblesTargets).attr("hidden", false)
    } else {
      this.$element.removeClass("dirty")
      $(this.dirtyVisiblesTargets).attr("hidden", true)
    }
  }

  get currentTargetValue() {
    if (this.inputTarget.type == "checkbox") {
      return this.inputTarget.checked
    } else {
      return $(this.inputTarget).val()
    }
  }
}

