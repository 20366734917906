import { Controller } from "@hotwired/stimulus"
import parameterize from "../../src/parameterize"

export default class extends Controller {
  // Allows us to force input to a parameter-friendly format for use in the back-end
  // Use this function as the data-action on an element to parameterize the element's value
  parameterize(ev) {
    ev.currentTarget.value = parameterize(ev.currentTarget.value)
  }
}
