import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["accounts", "organizations", "entities"]

  connect() {
    this.accounts = JSON.parse(this.element.dataset.accounts)
    this.organizations = JSON.parse(this.element.dataset.organizations)
    this.showAccount = this.hasAccountsTarget
    this.showOrganization = this.hasOrganizationsTarget

    this.defaultSelect2Options = {
      theme: "bootstrap",
      multiple: true,
      width: "resolve",
      closeOnSelect: false,
      allowClear: true,
    }

    this.initializeAccounts()
    this.initializeOrganizations()
    this.initializeEntities()
  }

  initializeAccounts(){
    if (!this.showAccount) {
      return
    }

    $(this.accountsTarget).select2(Object.assign({
      placeholder: "Select Accounts",
      ajax: {
        url: Routes.select2_admin_accounts_path(),
        dataType: "json",
        delay: 150,
        processResults: function (data) {
          const items = data.results.map(item => { item.text += ` (${item.subdomain})`; return item })
          return { results: items }
        }
      }
    }, this.defaultSelect2Options))
  }

  initializeOrganizations(){
    if (!this.showOrganization) {
      return
    }

    if (this.accounts && this.accounts.length === 1) {
      this.organizationsTarget.removeAttribute("disabled")

      const url = this.showAccount ? Routes.select2_admin_organizations_path({accounts: this.accounts}) : Routes.select2_account_signals_path()
      $(this.organizationsTarget).select2(Object.assign({
        placeholder: "Select Organizations",
        ajax: {
          url: url,
          dataType: "json",
          delay: 150
        }
      }, this.defaultSelect2Options))
    } else {
      $(this.organizationsTarget).select2(Object.assign({ placeholder: "Select Organizations" }, this.defaultSelect2Options))
    }
  }

  initializeEntities(){
    if (this.organizations && this.organizations.length === 1) {
      this.entitiesTarget.removeAttribute("disabled")

      const url = this.showAccount ? Routes.entities_select2_admin_signals_path({ organizations: this.organizations }) : Routes.entities_select2_organization_signals_path(this.organizations[0])
      $(this.entitiesTarget).select2(Object.assign({
        placeholder: "Select Entity",
        ajax: {
          url: url,
          dataType: "json",
          delay: 150
        }
      }, this.defaultSelect2Options))
    } else {
      $(this.entitiesTarget).select2(Object.assign({ placeholder: "Select Entity" }, this.defaultSelect2Options))
    }
  }
}
