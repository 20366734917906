import { Controller } from "@hotwired/stimulus"
import EventTypes from "event_types"

export default class extends Controller {
  static targets = ["editAccount", "stripeAccountSetupModal"]

  handleRequestSuccess(event) {
    let [response,] = event.detail
    Rails.fire(this.element, EventTypes.ACCOUNT_UPDATED, {account: response.account})
    $(this.editAccountTarget).modal("hide")
  }

  handleRequestError(event) {
    let errorMsg = event.detail[0].error
    console.log(errorMsg)
    alert(errorMsg)
  }

  showEditAccountModal() {
    $(this.editAccountTarget).modal("show")
  }

  showStripeAccountSetupModal() {
    if(this.hasStripeAccountSetupModalTarget) {
      $(this.stripeAccountSetupModalTarget).modal("show")
    }
  }
}
