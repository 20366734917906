import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sourceInput"]

  updateInputOnParentForm(event) {
    const parent_form = document.getElementById(event.target.dataset.parentFormId)
    const target_input_element = document.getElementById(parent_form.dataset.targetInputId)

    target_input_element.value = this.sourceInputTarget.value
  }
}
