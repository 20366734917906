import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "adjustDiscountModal",
    "adjustMaximumModal",
  ]

  showAdjustDiscountModal() {
    $(this.adjustDiscountModalTarget).modal("show")
  }

  showAdjustMaximumModal() {
    $(this.adjustMaximumModalTarget).modal("show")
  }
}
