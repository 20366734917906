import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loadMoreButton"]

  static values = {
    url: String
  }

  connect() {
    if (this.urlValue) {
      document.getElementById("load-more-button").href = this.urlValue
      $(document.getElementById("load-more-button")).removeClass("hidden")
      $(document.getElementById("load-more-button")).removeClass("disabled")
    }
  }
}
