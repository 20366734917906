import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "subscriptionsForm"
  ]
  static outlets = [ "admin--subscriptions--new-product" ]

  initialize() {
    this.cart = new Set()
  }

  // Track changes to product selections and enable or disable submit button
  updateCart(e) {
    const { added, product } = e.detail

    if (added) {
      this.cart.add(product)
    } else {
      this.cart.delete(product)
    }
    this.submitButtonTarget.toggleAttribute("disabled", this.cart.size === 0)
  }

  // Close any open forms and submit the form
  submitForm(e) {
    this.adminSubscriptionsNewProductOutlets.forEach(controller => controller.collapse())
    this.subscriptionsFormTarget.submit()
  }
}
