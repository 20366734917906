import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["modal", "continueButton", "displayToggle"]

  connect() {
    const modalSeen = this.data.get("modalSeen") === "true"
    if(!modalSeen) {
      $(this.modalTarget).modal("show")
    }
  }

  onButtonPress() {
    const toggleTarget = this.displayToggleTarget
    if(toggleTarget && toggleTarget.checked) {
      $.ajax(this.data.get("disableModalUrl"), {
        method: "patch",
        data: {
          modal_type: this.data.get("modalType")
        },
        complete: () => {
          $(this.modalTarget).modal("hide")
        }
      })
    }
    else {
      $(this.modalTarget).modal("hide")
    }
  }
}
