import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["organizationMappings"]
  static values = {
    automapped: Number
  }

  connect() {
    // Communicates that there were changes to form values from automap operation
    if (this.automappedValue && this.automappedValue > 0) {
      $(this.element).trigger("change")
    }
    this.datatable = $(this.organizationMappingsTarget).dataTable({ searching: true })
  }

  handleSubmit(e) {
    if (e.target.contains(this.element)) {
      this.appendInputsFromAllPages()
    }
  }

  appendInputsFromAllPages() {
    let params = this.datatable.$("select").serializeArray()

    params.forEach(param => this.appendInputToForm(param.name, param.value))
  }

  appendInputToForm(inputName, value) {
    if (this.element.querySelectorAll(`select[name="${inputName}"]`).length === 0) {
      let input = document.createElement("input")
      input.type = "hidden"
      input.name = inputName
      input.value = value
      this.element.append(input)
    }
  }
}
