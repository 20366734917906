import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.confirmLeave = false
    // This has to be done for select2 box events
    $(this.element).on("change", (e) => { this.changedContents(e) })
  }
  navigateAway(e) {
    if (this.confirmLeave) {
      e.preventDefault()
    }
  }
  changedContents(e) {
    this.confirmLeave = true
  }
  submitted(e) {
    this.confirmLeave = false
  }
}
