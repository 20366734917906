import BulkActionsController from "../../components/bulk_actions_controller"
import * as Routes from "routes"

export default class extends BulkActionsController {
  bulkEnable() {
    this.ajaxBulkToggleStatus(this.getSelectedIds().split(","), "enabled")
  }

  bulkDisable() {
    this.ajaxBulkToggleStatus(this.getSelectedIds().split(","), "disabled")
  }

  ajaxBulkToggleStatus(ids, status) {
    $.ajax({
      url: Routes.bulk_toggle_status_admin_rules_path(),
      dataType: "json",
      contentType: "application/json",
      method: "POST",
      data: JSON.stringify({ ids: ids, status: status }),
      complete:  (xhr) => {
        location.reload()
      }
    })
  }
}
