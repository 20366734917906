import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterInput", "item"]

  filter() {
    this.itemTargets.forEach(row => {
      row.classList.toggle("hide", !row.textContent.toLowerCase().includes(this.filterInputTarget.value.toLowerCase()))
    })
  }
}
