
// Helper methods for Devise form validation
const zxcvbn = require("zxcvbn")

updateStrengthBar = function(input, selector) {
  const password = input.value
  if (password == '') {
    return true
  }

  const result  = zxcvbn(password),
    score   = result.score,
    message = result.feedback.warning || 'The password is weak'

  const $bar = $(selector)
  switch (score) {
    case 0:
      $bar.attr('class', 'progress-bar progress-bar-danger')
        .css('width', '1%')
      break
    case 1:
      $bar.attr('class', 'progress-bar progress-bar-danger')
        .css('width', '25%')
      break
    case 2:
      $bar.attr('class', 'progress-bar progress-bar-danger')
        .css('width', '50%')
      break
    case 3:
      $bar.attr('class', 'progress-bar progress-bar-warning')
        .css('width', '75%')
      break
    case 4:
      $bar.attr('class', 'progress-bar progress-bar-success')
        .css('width', '100%')
      break
  }

  if (score < 3) {
    return {
      valid: false,
      message: message
    }
  }

  return true
}

setButtonAvailability = function(button, available){
  if (available) {
    button.removeAttribute('disabled')
  }
  else {
    button.setAttribute('disabled', 'disabled')
  }
}

module.exports = {
  updateStrengthBar,
  setButtonAvailability,
}
