import { initTable } from "huntressHelpers"

$(function() {
  if ($("#org-agents-binaries").length < 1) return;

  let agent_id = $("#org-agents-binaries").data("agentId")

  let datatable_params = {
    language: {
      emptyTable: 'No binaries found for this classification'
    },
    columnDefs: [
      { targets: "_all", searchable: false },
      { targets: [3], orderable: false }
    ],
  };

  $('a[data-toggle="tab"]').on('click', function (e) {
    var table = $('#binaries table').DataTable();
    table.state.clear();
    table.destroy();
    initTable($(e.target).data('classification'), datatable_params);
    localStorage.setItem(`lastBinariesTab_${agent_id}`, $(e.target).data('classification'));
  });

  var lastBinariesTab = localStorage.getItem('lastBinariesTab_' + agent_id);
  if (lastBinariesTab) {
    $('a[data-classification="' + lastBinariesTab + '"]').tab('show');
  }

  $('#datatable table').on('init.dt', function() {
    $("#loading").hide();
    $("#datatable").fadeIn();
  });

  initTable($('#datatable ul li.active a').data('classification'), datatable_params);
})
