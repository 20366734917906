import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "addAgentBtn",
    "agentIdInputs",
    "agentIds",
    "agentsDatatable",
    "agentsEditButton",
    "agentsList",
    "agentsNextButton",
    "agentsPanel",
    "archiveEndDate",
    "archiveStartDate",
    "form",
    "organizationsDatatable",
    "organizationsEditButton",
    "organizationIdInputs",
    "organizationsList",
    "organizationsNextButton",
    "organizationsPanel",
    "timeframePanel",
    "toggleAllAgentsBtn",
    "saveButton"
  ]

  connect() {
    // Setup the organizations table
    $(this.organizationsDatatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      pageLength: 5,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      lengthChange: true,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: -1, orderable: false }
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.organizations_account_data_archives_path(),
      createdRow: this.createdOrganizationRow.bind(this),
    })

    this.selectedOrganizationIds = []
    this.selectedAgentIds = []
    this.selectedAllAgents = false
  }

  createSelectionElement(text, action, attrs) {
    const el = document.createElement("span")
    el.classList = "label label-success"
    el.innerText = text

    for (const key in attrs) {
      el.setAttribute("data-" + key, attrs[key])
    }

    const close = document.createElement("a")
    close.classList = "pl-1"
    close.href = "#"
    close.innerHTML = "&times;"
    close.setAttribute("data-action", `click->${this.identifier}#${action}`)

    el.append(close)
    return el
  }

  createdOrganizationRow(row, data, index) {
    // When rendering rows we check if the row is for an organization that's already been added to
    // the selected list and if it has, don't show the add button
    const button = row.querySelector("td button.add-organization")
    if (this.selectedOrganizationIds.some(id => button.dataset.organizationId === id)) {
      button.classList.add("hidden")
    }
  }

  addOrganization(event) {
    // Hide the add button
    event.currentTarget.classList.add("hidden")

    // Don't allow the same organization to be added more than once. This shouldn't happen, but this
    // is here just in case it does somehow happen
    const organizationId = event.currentTarget.dataset.organizationId
    if (this.selectedOrganizationIds.some(id => organizationId === id)) {
      return
    }

    // Add the label and update the selected list
    this.organizationsListTarget.append(
      this.createSelectionElement(
        event.currentTarget.dataset.organizationName,
        "removeOrganization",
        {
          "organization-id": organizationId
        }
      )
    )
    this.selectedOrganizationIds.push(organizationId)
  }

  removeOrganization(event) {
    // Remove the organization ID from the selected list
    const removedOrganizationId = event.currentTarget.parentElement.dataset.organizationId
    this.selectedOrganizationIds = this.selectedOrganizationIds.filter(item => item !== removedOrganizationId)

    // Update the table to re-enable the add button
    this.organizationsPanelTarget.querySelectorAll("table tbody tr").forEach(row => {
      const button = row.querySelector("td button.add-organization")
      if (button.dataset.organizationId === removedOrganizationId) {
        button.classList.remove("hidden")
      }
    })

    // Remove the label from the selected organizations
    event.currentTarget.parentElement.remove()
  }

  editOrganizations() {
    // Show the organizations panel and disable the future steps
    this.showPanel(this.organizationsPanelTarget)
    this.organizationsEditButtonTarget.classList.add("hidden")
    this.organizationsNextButtonTarget.classList.remove("hidden")
    this.agentsEditButtonTarget.classList.add("hidden")
    this.agentsNextButtonTarget.classList.add("hidden")
    this.markStepIncomplete(this.organizationsPanelTarget)
    this.markStepIncomplete(this.agentsPanelTarget)
    this.disableSubmit()

    this.organizationIdInputsTargets.forEach(el => el.remove())

    // Show the 'x' buttons on the selected organizations
    this.organizationsListTarget.querySelectorAll("span a").forEach(link => {
      link.classList.remove("hidden")
    })

    // Clear any selected agents
    this.agentsListTarget.replaceChildren()
    this.selectedAgentIds = []
  }

  doneOrganizations() {
    if (this.selectedOrganizationIds.length == 0) {
      alert("You must select at least one organization")
      return
    }

    // Mark the organizations selection step as completed
    this.markStepComplete(this.organizationsPanelTarget)
    this.organizationsNextButtonTarget.classList.add("hidden")
    this.organizationsEditButtonTarget.classList.remove("hidden")
    this.editAgents()

    // Hide the 'x' buttons on selected organizations
    this.organizationsListTarget.querySelectorAll("span a").forEach(link => {
      link.classList.add("hidden")
    })

    // Add the IDs of the selected organizations to the form
    this.selectedOrganizationIds.forEach(id => {
      const el = document.createElement("input")
      el.type = "hidden"
      el.name = "data_archive[organization_ids][]"
      el.dataset.target = "data-archives--new.organizationIdInputs"
      el.value = id
      this.formTarget.append(el)
    })
  }

  createdAgentRow(row, data, index) {
    // When rendering rows we check if the row is for an agent that's already been added to the
    // selected list and if it has, don't show the add button
    const button = row.querySelector("td button.add-agent")
    if (this.selectedAgentIds.some(id => button.dataset.agentId === id) || this.selectedAllAgents) {
      button.classList.add("hidden")
    }
  }

  toggleAllAgents() {
    this.selectedAllAgents = !this.selectedAllAgents

    this.selectedAgentIds = []

    while (this.agentsListTarget.firstChild) {
      this.agentsListTarget.removeChild(this.agentsListTarget.firstChild)
    }

    if (this.selectedAllAgents) {
      this.toggleAllAgentsBtnTarget.innerText = "Remove all"

      this.selectedAgentIds = ["all"]

      if (this.hasAddAgentBtnTarget)
        this.addAgentBtnTargets.forEach(btn => btn.classList.add("hidden"))

      this.agentsListTarget.append(
        this.createSelectionElement(
          "All agents",
          "toggleAllAgents",
        )
      )
    } else {
      this.toggleAllAgentsBtnTarget.innerText = "Add all"

      if (this.hasAddAgentBtnTarget)
        this.addAgentBtnTargets.forEach(btn => btn.classList.remove("hidden"))
    }
  }

  addAgent(event) {
    // Hide the add button
    event.currentTarget.classList.add("hidden")

    // Don't allow the same agent to be added more than once. This shouldn't happen, but this
    // is here just in case it does somehow happen
    const agentId = event.currentTarget.dataset.agentId
    if (this.selectedAgentIds.some(id => agentId === id)) {
      return
    }

    // Add the label and update the selected list
    this.agentsListTarget.append(
      this.createSelectionElement(
        event.currentTarget.dataset.organizationName + ": " + event.currentTarget.dataset.agentHostname,
        "removeAgent",
        {
          "agent-id": agentId,
          "organization-id": event.currentTarget.dataset.organizationId,
          "organization-name": event.currentTarget.dataset.organizationName
        }
      )
    )
    this.selectedAgentIds.push(agentId)
  }

  removeAgent(event) {
    // Remove the agent ID from the selected list
    const removedAgentId = event.currentTarget.parentElement.dataset.agentId
    this.selectedAgentIds = this.selectedAgentIds.filter(item => item !== removedAgentId)

    // Update the table to re-enable the add button
    this.agentsPanelTarget.querySelectorAll("table tbody tr").forEach(row => {
      const button = row.querySelector("td button.add-agent")
      if (button.dataset.agentId === removedAgentId) {
        button.classList.remove("hidden")
      }
    })

    // Remove the label from the selected agents
    event.currentTarget.parentElement.remove()
  }

  editAgents() {
    // Show the agents panel and restore buttons
    this.showPanel(this.agentsPanelTarget)
    this.agentsEditButtonTarget.classList.add("hidden")
    this.agentsNextButtonTarget.classList.remove("hidden")
    this.markStepIncomplete(this.agentsPanelTarget)
    this.disableSubmit()

    this.agentIdInputsTargets.forEach(el => el.remove())

    $(this.agentsDatatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 10,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: [6], orderable: false, searchable: false },
      ],
      order: [[5, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.agents_account_data_archives_path(
        {
          format: "json",
          organization_ids: this.selectedOrganizationIds
        }
      ),
      createdRow: this.createdAgentRow.bind(this),
    })

    // Showt the 'x' buttons for the selected agents
    this.agentsListTarget.querySelectorAll("span a").forEach(link => {
      link.classList.remove("hidden")
    })
  }

  doneAgents() {
    if (this.selectedAgentIds.length == 0) {
      alert("You must select at least one agent")
      return
    }

    // Remove editing buttons
    this.agentsEditButtonTarget.classList.remove("hidden")
    this.agentsNextButtonTarget.classList.add("hidden")
    this.markStepComplete(this.agentsPanelTarget)
    this.enableSubmit()
    this.editTimeframe()

    // Hide the 'x' button for selected agents
    this.agentsListTarget.querySelectorAll("span a").forEach(link => {
      link.classList.add("hidden")
    })

    // Add the IDs of the selected agents to the form
    this.selectedAgentIds.forEach(id => {
      const el = document.createElement("input")
      el.type = "hidden"
      el.name = "data_archive[agent_ids][]"
      el.dataset.target = "data-archives--new.agentIdInputs"
      el.value = id
      this.formTarget.append(el)
    })
  }

  setTimeframe(event) {
    let startDate = new Date()
    let endDate = new Date()

    switch (event.currentTarget.dataset.timeframeValue) {
    case "24h":
      startDate.setDate(startDate.getDate() - 1)
      break
    case "3d":
      startDate.setDate(startDate.getDate() - 3)
      break
    case "7d":
      startDate.setDate(startDate.getDate() - 7)
      break
    }

    // Update the form text boxes
    this.archiveStartDateTarget.value = this.formatDate(startDate)
    this.archiveEndDateTarget.value = this.formatDate(endDate)
  }

  formatDate(date) {
    return date.toISOString().slice(0, 10)
  }

  editTimeframe() {
    this.showPanel(this.timeframePanelTarget)
  }

  disableSubmit() {
    this.saveButtonTarget.classList.remove("btn-success")
    this.saveButtonTarget.classList.add("btn-default")
    this.saveButtonTarget.setAttribute("disabled", "disabled")
  }

  enableSubmit() {
    this.saveButtonTarget.classList.add("btn-success")
    this.saveButtonTarget.classList.remove("btn-default")
    this.saveButtonTarget.removeAttribute("disabled")
  }

  markStepComplete(target) {
    const panel = target.closest(".panel")
    panel.classList.add("panel-success")
    panel.classList.remove("panel-default")
  }

  markStepIncomplete(target) {
    const panel = target.closest(".panel")
    panel.classList.add("panel-default")
    panel.classList.remove("panel-success")
  }

  showPanel(target) {
    if (this.organizationsPanelTarget != target) {
      $(this.organizationsPanelTarget).collapse("hide")
    }

    if (this.agentsPanelTarget != target) {
      $(this.agentsPanelTarget).collapse("hide")
    }

    if (this.timeframePanelTarget != target) {
      $(this.timeframePanelTarget).collapse("hide")
    }

    $(target).collapse("show")
  }

  create(event) {
    // Verify the timeframe has been filled in
    if (this.archiveStartDateTarget.value === "" || this.archiveEndDateTarget.value === "") {
      alert("You must specify a timeframe for the archive")
      event.preventDefault()
    }
  }
}
