import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["datatable", "accountSelect"]

  connect() {
    this.setupDataTable()
    this.prepareModalSelect2()
  }

  setupDataTable(){
    $(this.datatableTarget).DataTable({
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      searching: true
    })
  }

  prepareModalSelect2() {
    $(this.element).on("shown.bs.modal", this.setupAccountSelect2.bind(this))
  }

  setupAccountSelect2() {
    $(this.accountSelectTarget).select2({
      theme: "bootstrap",
      placeholder: "Choose An Account...",
      ajax: {
        url: Routes.select2_admin_accounts_path(),
        dataType: "json",
        delay: 200,
      },
      width: "100%",
    })
  }

  goToNewSubscription(ev) {
    const data = $(this.accountSelectTarget).select2("data")[0]
    if(!data)
      return
    window.location.href = data["url"] + "/../admin/accounts/" + data["id"] + "/subscriptions/new"
    ev.preventDefault()
    ev.stopPropagation()
  }
}
