import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loading", "datatable", "table", "categorizeModal", "navLinkAutoruns", "navLinkAntivirus", "navLinkMonitoredFiles", "navLinkProcessInsights", "navLinkMDEEvents"]

  connect() {
    this.getNavMenuCounts()
    this.setupSelect2AutoOpen()
  }

  setupSelect2AutoOpen() {
    // This controller is added at a layout level and breaks on certain pages that do not use this
    if(!this.hasCategorizeModalTarget) return
    $(this.categorizeModalTarget).on("shown.bs.modal", () => {
      $(this.categorizeModalTarget.querySelector("select")).select2("open")
    })
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }

  handleCategorizeAjaxError(evt) {
    const errorMsg = evt.detail[0].error
    $(this.datatableTarget).prepend("<div class='alert alert-danger alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>" + errorMsg + "</div>")
    $(this.categorizeModalTarget).modal("hide")
  }

  refreshWindow() {
    window.location.reload(true)
  }

  getNavMenuCounts() {
    $.ajax({
      url: this.data.get("menuCountsUrl"),
      success: (data) => {
        Object.keys(data).forEach(elem => {
          if (this["hasNavLink" + elem + "Target"]) {
            const val = parseInt(data[elem])
            if (val > 0) {
              const target = this["navLink" + elem + "Target"]
              target.innerHTML = val
              target.style.display = "block"
            }
          }
        })
      }
    })
  }
}
