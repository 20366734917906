import { Controller } from "@hotwired/stimulus"
import { initTable } from "huntressHelpers"

export default class extends Controller {
  static targets = ["datatable", "loading", "table"]

  connect() {
    this.showLastTab()
    $(this.tableTarget).on("init.dt", () => this.tableInitialized())
    initTable(this.currentDataURL)
  }

  get currentDataURL() {
    return this.currentTab.dataset.classification
  }

  get currentTab() {
    return this.datatableTarget.querySelector("ul li.active a")
  }

  showLastTab() {
    const lastPersistenceTab = localStorage.getItem("lastPersistenceTab")
    if (lastPersistenceTab) {
      $(`a[data-classification="${lastPersistenceTab}"]`).tab("show")
    }
  }

  tabChanged(ev) {
    const el = $(ev.target)
    const table = $(this.tableTarget).DataTable()
    table.state.clear()
    table.destroy()
    initTable(el.data("classification"))
    localStorage.setItem("lastPersistenceTab", el.data("classification"))
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }
}
