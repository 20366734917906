import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    // Maintain filter params through actions
    const urlParams = new URLSearchParams(window.location.search)
    for (const key of urlParams.keys()) {
      const value = urlParams.get(key)
      if(!value)
        continue

      const input = $('<input type="hidden">').attr({
        name: key,
        value: value
      })

      $(this.element).append(input)
    }
  }
}
