import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from "../../../helpers/moneyHelpers"

export default class extends Controller {
  static targets = [
    "totalsTable",
    "totalsNoProductsWarning",
    "totalContractMSRP",
    "totalContractNet",
    "totalContractDisclaimer"
  ]

  static values = {
    currency: String,
    billingInterval: String,
    subscriptions: Array
  }

  connect() {
    if (this.subscriptionsValue.length > 0) this.totalsNoProductsWarningTarget.hidden = true

    // USED FOR TOTAL LEVEL CALCULATIONS
    this.subscriptions = this.subscriptionsValue.map((sub) => {
      sub.existingSub = true
      return sub
    })

    this.updateTotalContractFields()

    this.totalContractDisclaimerTarget.textContent = `Billing begins when the subscription is activated. You will be billed for the ${this.billingIntervalValue} amount.`
  }

  onBillingIntervalChanged(e){
    const { amount } = e.detail

    this.totalContractDisclaimerTarget.textContent = `Billing begins when the subscription is activated. You will be billed for the ${amount} amount.`
  }

  removeSubscription(product) {
    this.subscriptions = this.subscriptions.filter((sub) => sub.product != product)
  }

  onSetSelectedPlan(e) {
    const { product, price, minimum } = e.detail

    this.removeSubscription(product)

    if (price && minimum >= price.tier) {
      let subscription = {
        product: product,
        minimum: minimum,
        msrp: price.amount / (price.price_type === "reseller_managed" ? 0.5 : 0.8),
        amount: price.amount
      }
      this.subscriptions.push(subscription)
      this.updateTotalContractFields()
    }
  }

  onProductRemoved(e) {
    let { product } = e.detail
    this.removeSubscription(product)
    if (this.subscriptions.length > 0) this.totalsTableTarget.hidden = false
    this.updateTotalContractFields()
  }

  updateTotalContractFields() {
    let totalMSRP = 0.00
    let totalNet = 0.00

    if (this.subscriptions.length > 0) {
      totalMSRP += this.subscriptions.reduce((total, sub) => {
        return total + sub.msrp * sub.minimum * 12
      }, 0.00)
      totalNet += this.subscriptions.reduce((total, sub) => {
        return total + sub.amount * sub.minimum * 12
      }, 0.00)
    }

    this.totalContractMSRPTarget.textContent = formatCurrency(this.currencyValue, totalMSRP)
    this.totalContractNetTarget.textContent = formatCurrency(this.currencyValue, totalNet)

    this.totalsNoProductsWarningTarget.hidden = (this.subscriptions.length > 0)
  }
}
