import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.urlValue) {
      this.run(this.urlValue)
    }
  }

  trigger(event) {
    event.preventDefault()

    // Disable the target
    event.target.classList.add("disabled")
    event.target.setAttribute("disabled", "disabled")

    this.run(event.target.href)
  }

  run(url) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        this.element.outerHTML = body
      })
      .catch(error => {
        alert("Unable to load frame")
      })
  }
}
