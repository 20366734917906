import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#datatable table")
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $("#datatable table").dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [-1, 2], orderable: false }
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: huntressData.data_archives_path,
    })
  }
}
