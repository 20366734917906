import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"
import { flashHighlightId } from "huntressHelpers"

export default class extends Controller {
  connect() {
    this.paused = this.data.get("paused") === "true"
    this.bucketTime = this.data.get("bucketTime")
    this.highlightTime = this.data.get("highlightTime")
    this.updateRowHighlightColor = this.data.get("updateRowHighlightColor")
    this.newRowHighlighColor = this.data.get("newRowHighlightColor")
    this.rootElement = this.data.get("rootNode") ? document.querySelector(this.data.get("rootNode")) : document

    this.processingTimer = false
    this.bucketedIds = new Set()
    this.newRowIds = new Set()

    this.skip_update_columns = JSON.parse(this.data.get("realtimeSettings"))["skipUpdateColumns"] || []
    this.hiddenColumns = JSON.parse(this.data.get("realtimeSettings"))["hiddenColumns"] || []
    let options = JSON.parse(this.data.get("channelOptions"))
    consumer.subscriptions.create(options, {
      received: this._received.bind(this)
    })
  }

  addRow(rowData, index = 0) {
    let tbody = this.rootElement.querySelector("tbody")
    let newRow = tbody.insertRow(index)

    newRow.id = rowData.get("DT_RowId")
    flashHighlightId($(newRow), this.highlightTime, this.newRowHighlighColor)
    for(let colIndex = 0; colIndex < rowData.size - 1; colIndex++) {
      let newCol = newRow.insertCell()
      newCol.innerHTML = rowData.get(colIndex.toString())

      if (colIndex === 0) {
        newCol.classList.add("new-row")
      }
    }
  }

  updateRow(rowElement, rowData) {
    let skippedCount = 0
    let tds = rowElement.querySelectorAll("td")
    flashHighlightId($(rowElement), this.highlightTime, this.updateRowHighlightColor)

    // Might have to check if any of the visible fields are actually updated
    rowData.forEach((value, key) => {
      if (!isNaN(key)) {
        if (this.hiddenColumns.includes(key)) {
          skippedCount++
          return
        }

        if (this.skip_update_columns.includes(key)) {
          return
        }

        tds.item(key - skippedCount).innerHTML = value
      }
    })
  }

  resumeNewRows() {
    this.paused = false
    this.loadBufferredRowIds()
    $(this.rootElement.querySelector("table")).DataTable().realtime.hideLoadNewRows()
  }

  loadBufferredRowIds(e) {
    if (e) {
      e.preventDefault()
    }

    let ids = new Set(this.newRowIds)
    this.newRowIds.clear()
    ids.forEach(id => this._bucketRowId(id))
    this._updateNewRowsCount()
  }

  pauseNewRows() {
    this.paused = true
  }

  onPauseResume(e) {
    // Only do for clicks inside the toggle group
    if (e.target.parentNode.classList.contains("toggle-group")) {
      // This gets fired before the element changes, so it's the past value
      if (document.getElementById("dt-realtime-checkbox").checked) {
        this.pauseNewRows()
      } else {
        this.resumeNewRows()
      }
    }
  }

  _received(data) {
    switch(data.action) {
    case "update":
      this._onUpdateAction(data.ids)
      break
    case "create":
      this._onCreateAction(data.ids)
      break
    }
  }

  _onUpdateAction(ids) {
    ids.forEach(o => {
      let rowId = o.row_id
      let row = this.rootElement.querySelector(`#${rowId}`)
      if (row) {
        this._bucketRowId(o.id)
      }
    })
  }

  _onCreateAction(ids) {
    ids.forEach((o) => {
      if (this.paused) {
        this._bufferNewRowId(o.id)
      } else {
        this._bucketRowId(o.id)
      }
    })
  }

  _bucketRowId(id) {
    // Bucket the request
    this.bucketedIds.add(id)
    if (!this.processingTimer) {
      this.processingTimer = setTimeout(this._processBucketedIds.bind(this), this.bucketTime)
    }
  }

  _bufferNewRowId(id) {
    // Save row update for unpause, keep track of number of new rows available
    this.newRowIds.add(id)
    this._updateNewRowsCount()
  }

  _updateNewRowsCount() {
    $(this.rootElement.querySelector("table")).DataTable().realtime.updateNewRowsCount(this.newRowIds.size)
    if (this.newRowIds.size > 0) {
      $(this.rootElement.querySelector("table")).DataTable().realtime.showLoadNewRows()
    } else {
      $(this.rootElement.querySelector("table")).DataTable().realtime.hideLoadNewRows()
    }
  }

  _processBucketedIds() {
    // Retrieve row data for all ids in bucket and clear the list
    let ids = new Set(this.bucketedIds)
    this.bucketedIds.clear()
    this.processingTimer = null
    this._getRowData(ids)
  }

  _getRowData(ids) {
    let data = {
      ids: Array.from(ids),
      start: 0,
      length: -1
    }

    $.ajax({
      type: "get",
      url: this.data.get("url"),
      data: data,
      success: (data) => {
        this._updateTable(data)
      }
    })
  }

  _updateTable(data) {
    data.data.forEach(rowData => {
      let rowDataMap = new Map(Object.entries(rowData))
      let rowId = rowDataMap.get("DT_RowId")
      let row = this.rootElement.querySelector(`#${rowId}`)
      if (row) {
        this.updateRow(row, rowDataMap)
      } else {
        this.addRow(rowDataMap)
      }
    })
  }
}
