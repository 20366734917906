import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "downloadLink",
    "checkbox",
    "subscribeConfirmButton",
    "userDownloadedTOS",
    "subscriptionCountDisplay",
    "subscribeButton",
    "subscriptionsSelect2"
  ]

  connect() {
    this.termsDownloaded = false
    this.submitted = false
    this.subscribeConfirmButtonTarget.setAttribute("disabled", true)
    this.downloadLinkTarget.onclick = this.termsDownloadClickHandler.bind(this)
    this.checkboxTarget.onchange = this.toggleSubmitButtonDisabled.bind(this)
    if(this.hasSubscribeButtonTarget) {
      this.subscribeButtonTarget.onclick = this.handleModalOpen.bind(this)
    }
  }

  termsDownloadClickHandler() {
    this.termsDownloaded = true
    const downloadedText = `Downloaded by ${huntressData.current_user_name} ${this.formattedDate}`
    this.userDownloadedTOSTarget.textContent = downloadedText
    this.toggleSubmitButtonDisabled()
  }

  toggleSubmitButtonDisabled() {
    if (this.termsDownloaded && this.checkboxTarget.checked && !this.submitted) {
      this.subscribeConfirmButtonTarget.removeAttribute("disabled")
    } else {
      this.subscribeConfirmButtonTarget.setAttribute("disabled", true)
    }
  }

  formSubmitted() {
    this.submitted = true
  }

  handleModalOpen() {
    const selected = $(this.subscriptionsSelect2Target).find(":selected")
    const subs = Array.from(selected.map((idx,e) => e.innerText))
    let text
    if (subs.length > 1) {
      text = `${subs.length} subscriptions.`
    } else {
      text = `${subs[0]} subscription.`
    }
    this.subscriptionCountDisplayTarget.textContent = text
  }

  get formattedDate() {
    const currentDate = new Date()
    const year = currentDate.getUTCFullYear()
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getUTCDate()).padStart(2, "0")
    const hours = String(currentDate.getUTCHours()).padStart(2, "0")
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0")
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0")

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`
  }
}
