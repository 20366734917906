import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "discountPercentage",
    "salePrice",
    "retainDiscountContainer",
    "retainDiscountCheckbox",
  ]

  static values = {
    basePrice: Number
  }

  salePriceChanged() {
    if (this.salePrice > this.basePriceValue || this.salePrice < 0) {
      alert("Sale price must be between 0 and " + this.basePriceValue)
      this.discountPercentageTarget.value = 0
      this.salePriceTarget.value = (this.basePriceValue).toFixed(2)
      return false
    }
  }

  updateDiscountPercentage() {
    const newDiscount = (1 - (this.salePrice / this.basePriceValue)) * 100
    this.discountPercentageTarget.value = newDiscount.toFixed(2)
  }

  salePriceInput() {
    if (this.isCustomSalePrice()) {
      this.showRetainDiscountContainer()
    } else {
      this.hideRetainDiscountContainer()
    }
    this.updateDiscountPercentage()
  }

  isCustomSalePrice() {
    return !isNaN(this.salePrice) && this.salePrice < this.basePriceValue
  }

  get salePrice() {
    return parseFloat(this.salePriceTarget.value) * 100
  }

  get product() {
    return this.data.get("product")
  }

  showRetainDiscountContainer() {
    this.retainDiscountContainerTarget.hidden = false
    this.retainDiscountCheckboxTarget.removeAttribute("disabled")
  }

  hideRetainDiscountContainer() {
    this.retainDiscountContainerTarget.hidden = true
    this.retainDiscountCheckboxTarget.checked = false
    this.retainDiscountCheckboxTarget.disabled = true
  }

  keydown(event) {
    // Force the user to click the button to prevent accidental submission by pressing the 'enter' key.
    if (event.keyCode == 13){
      event.preventDefault()
      return false
    }
  }
}
