import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable", "learnerDatatable", "connectwiseDatatable"]

  connect() {
    $(this.datatableTarget).DataTable({
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
    })
    if(this.haslearnerDatatableTarget) {
      $(this.learnerDatatableTarget).DataTable({
        iDisplayLength: 25,
      })
    }
    if(this.hasConnectwiseDatatableTarget) {
      $(this.connectwiseDatatableTarget).DataTable({
        iDisplayLength: 25,
      })
    }
  }

  updateFailMessage() {
    const defaultMessage = "The number of agents that we have recorded does not match the number that we sent to ConnectWise."
    const p = document.getElementById("cw-last-sync-failed-text")
    const message = event.currentTarget.dataset.message

    p.textContent = message || defaultMessage
  }
}
