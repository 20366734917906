import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

const platformLabels = {
  darwin: "MacOS",
  windows: "Windows",
}

const platformsInOrder = ["darwin", "windows"]

export default class extends Controller {
  static targets = ["deployedAgentsByPlatform", "outdatedAgentsByPlatform"]

  connect() {
    this.makeChart(this.deployedAgentsByPlatformTarget)
    this.makeChart(this.outdatedAgentsByPlatformTarget)
  }

  makeChart(element) {
    const agentCounts = this.agentCountsForChart(element)
    const colors = this.backgroundColorsForChart(element)

    new Chart($(element), {
      type: "doughnut",
      data: {
        labels: platformsInOrder.map(platform => platformLabels[platform]),
        datasets: [
          {
            data: agentCounts,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
          }
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    })
  }

  backgroundColorsForChart(element) {
    const colors = JSON.parse(element.dataset.colors)
    return platformsInOrder.map(platform => colors[platform])
  }

  agentCountsForChart(element) {
    const counts = JSON.parse(element.dataset.counts)
    return platformsInOrder.map(platform => counts[platform])
  }
}
