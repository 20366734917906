import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onPaymentMethodChange(e) {
    const selectedValue = e.target.value

    if(selectedValue === "ach_credit_transfer") {
      document.querySelector("#stripe-cc-form").classList.add("hidden")
      document.querySelector("#stripe-ach-form").classList.remove("hidden")
    }
    else if(selectedValue === "credit_card") {
      document.querySelector("#stripe-cc-form").classList.remove("hidden")
      document.querySelector("#stripe-ach-form").classList.add("hidden")
    }
  }
}
