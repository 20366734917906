import { Controller } from "@hotwired/stimulus"
import moment from "moment"

const ANALYST_MAX_SUMMARY = 500
const MAX_SUMMARY_LENGTH = 1000

export default class extends Controller {
  static targets = ["summary", "summaryRemaining", "sectionInput", "typeInput", "publishedOnInput"]

  connect() {
    this.updateSummaryLength()
    $(this.summaryTarget).on("input", () =>{
      this.updateSummaryLength()
    })

    $(this.typeInputTarget).on("change", () =>{
      this.updateDateFields(this.typeInputTarget.value)
    })

    if(!this.publishedOnInputTarget.value){
      this.updateDateFields("monthly")
    }

    $(this.sectionInputTarget).on("change", (e)=>{
      if(this.sectionInputTarget.value == "analyst"){
        $(".report_analyst_note_threats").show()
        $(this.typeInputTarget).attr("readonly", false)
      }
      else{
        $(".report_analyst_note_threats").hide()
        $(this.typeInputTarget).val("quarterly").attr("readonly", true)
        if(!this.publishedOnInputTarget.value)
          $(this.typeInputTarget).trigger("change")
      }
      this.updateSummaryLength()
    })
    $(this.sectionInputTarget).trigger("change")
  }

  updateDateFields(type){
    switch(type){
    case "quarterly": {
      $(this.publishedOnInputTarget).val(moment().startOf("quarter").format("YYYY-MM-DD"))
    }
      break
    case "monthly" : {
      $(this.publishedOnInputTarget).val(moment().startOf("month").format("YYYY-MM-DD"))
    }
      break
    }
  }

  updateSummaryLength() {
    let max = MAX_SUMMARY_LENGTH
    if(this.hasSectionInputTarget && $(this.sectionInputTarget).val() == "analyst"){
      max = ANALYST_MAX_SUMMARY
    }

    //Strips tags from summary
    let summary = this.summaryTarget.value
    let div = document.createElement("div")
    div.textContent = summary
    let text = div.textContent || ""

    const charactersRemaining = max - text.length
    this.summaryRemainingTarget.innerHTML =  `( ${charactersRemaining} characters remaining )`
    if (charactersRemaining < 0) {
      this.summaryRemainingTarget.classList.add("text-danger")
    }
    else {
      this.summaryRemainingTarget.classList.remove("text-danger")
    }
  }
}
