import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    [
      "approveButton",
      "manuallyCompletableActionConfirmation",
      "manuallyCompletableActionCheckbox",
      "manuallyCompletableRemediation",
    ]

  connect() {
    this.setFormState()
  }

  setFormState() {
    if (this.manuallyCompletableConfirmationRequired) {
      this.showManuallyCompletableActionConfirmation()
    } else {
      this.hideManuallyCompletableActionConfirmation()
    }
  }

  get manuallyCompletableConfirmationRequired() {
    return this.hasManuallyCompletableRemediationTarget && this.manuallyCompletableRemediationTargets.some(element => element.value === "false")
  }

  showManuallyCompletableActionConfirmation() {
    this.disableApproveButton()
    this.uncheckManuallyCompletableActionCheckbox()
    this.manuallyCompletableActionConfirmationTarget.classList.remove("hidden")
  }

  hideManuallyCompletableActionConfirmation() {
    this.enableApproveButton()
    this.manuallyCompletableActionConfirmationTarget.classList.add("hidden")
  }

  disableApproveButton() {
    this.approveButtonTarget.setAttribute("disabled", true)
  }

  enableApproveButton() {
    this.approveButtonTarget.removeAttribute("disabled")
  }

  manuallyCompletableActionCheckboxChecked() {
    if (this.manuallyCompletableActionCheckboxTarget.checked === true) {
      this.enableApproveButton()
    } else {
      this.disableApproveButton()
    }
  }

  uncheckManuallyCompletableActionCheckbox() {
    this.manuallyCompletableActionCheckboxTarget.checked = false
  }
}
