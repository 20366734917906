import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"]

  set(event) {
    if (event.currentTarget.dataset.inputContent) {
      this.outputTarget.value = event.currentTarget.dataset.inputContent
    } else {
      this.outputTarget.value = event.currentTarget.textContent
    }

    this.outputTarget.focus()
  }
}
