import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkedCount"]

  connect() {
    this.element[this.identifier] = this
    this.filterMenuIdentifier = this.data.get("filterMenuIdentifier")
    this.filterMenuId = this.data.get("filterMenuId")

    $(document).on("filter_menu.updateCount", (_event, count) => {
      this.updateCheckedCount(count)
    })
  }

  clearFilters() {
    this.expandedFilterMenuController.clearFilters()
    this.updateCheckedCount(0)
  }

  updateCheckedCount(count) {
    $(this.checkedCountTarget).text(count)
  }

  get expandedFilterMenuController() {
    return document.getElementById(this.filterMenuId)[this.filterMenuIdentifier]
  }
}
