import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "onDescription", "offDescription"]

  connect() {
    // Required for bootstrap toggle to capture change event
    const self = this
    if(!this.hastoggleTarget)
      return
    $(this.toggleTarget).change(function() {
      if($(this).prop("checked")) {
        self.onDescriptionTarget.classList.remove("hidden")
        self.offDescriptionTarget.classList.add("hidden")
      }
      else {
        self.onDescriptionTarget.classList.add("hidden")
        self.offDescriptionTarget.classList.remove("hidden")
      }
    })
  }
}
