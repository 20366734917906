import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "enginesFullyDisabledWarningModal",
  ]

  static values = {
    organizationId: Number,
    agentId: Number,
    defenderFullyDisabled: Boolean,
  }

  connect() {
    this.setActiveTab()
  }

  setActiveTab() {
    if (this.data.get("activeTab") == "policy_status") {
      $("a[id='policy-status-tab']").tab("show")
    } else {
      $("a[id='overview-tab']").tab("show")
    }
    this.setupAjaxEventHandlers()
  }

  setupAjaxEventHandlers() {
    $("#mav-review-table")
      .on("draw.dt", () => {
        $("a.investigate")
          .on("ajax:success", this.investigateSuccess.bind(this))
          .on("ajax:error", this.ajaxError.bind(this))
      })
  }

  ajaxError(evt) {
    const errorMsg = evt.detail[0].error
    const xhr = evt.detail[2]
    alert(`Error: ${xhr.status} - ${errorMsg}`)
  }

  investigateSuccess(evt) {
    const el = $(evt.currentTarget)
    el.attr("disabled", "disabled").addClass("disabled")
    el.siblings().attr("disabled", "disabled").addClass("disabled")

    // Fade the row except the last column with the buttons
    el.closest("tr").find("td:not(:last)").fadeTo(500, 0.25)
  }

  onManualActionButtonClick(e) {
    if (this.data.get("defenderFullyDisabled") == "true") {
      e.preventDefault()
      e.stopPropagation()
      $(this.enginesFullyDisabledWarningModalTarget).modal("show")
    }
  }

  onFullScanAttempt(e) {
    e.preventDefault()
    $("#full-scan-modal").modal("show")
  }
}
