import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "ajaxModal",
    "ajaxModalContent",
    "ajaxModalLoading",
    "ajaxModalError",
    "reportForm",
    "infectionReportId"
  ]

  connect() {
    this.ajaxModalTarget.addEventListener("ajax:error", (_) => {
      this.showReportError()
    })
  }

  openAjaxModal(evt, data) {
    evt.preventDefault()

    const method = evt.currentTarget.dataset.ajaxMethod || "get"
    if (!data) {
      try {
        data = JSON.parse(evt.currentTarget.dataset.ajaxData)
      }
      catch {
        // leave data null
      }
    }

    this.showAjaxModalLoading()
    $(this.ajaxModalTarget).modal("show")
    $.ajax({
      type: method,
      url: evt.currentTarget.dataset.href,
      data: data,
      success: (data) => {
        this.ajaxModalContentTarget.innerHTML = data
        this.showAjaxModalContent()
        this.validateInputs()
      },
      error: () => {
        this.showAjaxModalError()
      }
    })
  }

  showAjaxModalLoading() {
    this.ajaxModalContentTarget.style.display = "none"
    this.ajaxModalLoadingTarget.style.display = "block"
    this.ajaxModalErrorTarget.style.display = "none"
  }

  showAjaxModalError() {
    this.ajaxModalContentTarget.style.display = "none"
    this.ajaxModalErrorTarget.style.display = "block"
    this.ajaxModalLoadingTarget.style.display = "none"
  }

  showAjaxModalContent() {
    this.ajaxModalContentTarget.style.display = "block"
    this.ajaxModalLoadingTarget.style.display = "none"
    this.ajaxModalErrorTarget.style.display = "none"
  }

  updateInfectionReport(e) {
    e.preventDefault()
    this.reportFormTarget.submit()
  }

  createInfectionReport(e) {
    e.preventDefault()
    if (this.hasInfectionReportIdTarget) {
      this.infectionReportIdTarget.remove()
    }

    this.reportFormTarget.submit()
  }

  validateInputs() {
    const element = $(this.reportFormTarget).find("select")
    this.updateButtons(element)
  }

  validateInput(event) {
    const element = $(event.currentTarget)
    this.updateButtons(element)
    this.updateInput(element)
  }

  updateButtons(element) {
    const title = $(this.reportFormTarget).find(".btn-success").data("title")

    if (element.val()) {
      $(this.reportFormTarget).find(".btn-success").removeAttr("disabled").removeAttr("title")
    } else {
      $(this.reportFormTarget).find(".btn-success").attr("disabled", true).attr("title", title)
    }
  }

  updateInput(element) {
    if (element.val()) {
      element.parents("[data-required]").removeClass("has-error")
    } else {
      element.parents("[data-required]").addClass("has-error")
    }
  }
}
