import between from './between';
import blank from './blank';
import callback from './callback';
import choice from './choice';
import creditCard from './creditCard';
import date from './date';
import different from './different';
import digits from './digits';
import emailAddress from './emailAddress';
import file from './file';
import greaterThan from './greaterThan';
import identical from './identical';
import integer from './integer';
import ip from './ip';
import lessThan from './lessThan';
import notEmpty from './notEmpty';
import numeric from './numeric';
import promise from './promise';
import regexp from './regexp';
import remote from './remote';
import stringCase from './stringCase';
import stringLength from './stringLength';
import uri from './uri';
export default {
    between,
    blank,
    callback,
    choice,
    creditCard,
    date,
    different,
    digits,
    emailAddress,
    file,
    greaterThan,
    identical,
    integer,
    ip,
    lessThan,
    notEmpty,
    numeric,
    promise,
    regexp,
    remote,
    stringCase,
    stringLength,
    uri,
};
