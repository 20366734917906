export const useTimeRangeFilter = controller => {
  Object.assign(controller, {
    searchTimeChanged(ev) {
      if (ev.detail && ev.detail.startDate && ev.detail.endDate) {
        const search = new URLSearchParams(location.search)
        const { startDate, endDate } = ev.detail

        search.set("filters[start_at]", startDate.toISOString())
        search.set("filters[end_at]", endDate.toISOString())

        location.search = search.toString()
      }
    },

    clearTimeFilter() {
      const search = new URLSearchParams(location.search)

      search.delete("filters[start_at]")
      search.delete("filters[end_at]")

      location.search = search
    }
  })
}
