import { Controller } from "@hotwired/stimulus"
import { RuleBlock} from "ruleHelpers"
import { flashMessage } from "huntressHelpers"

export default class extends Controller {
  static targets = ["rulesBlock", "tabs", "rulesMatchesTable", "rulesActionsTable"]
  ruleBlocks = {}
  ruleCounter = 0

  connect() {
    const filter = JSON.parse(this.data.get("filter"))
    if (filter && filter.expressions && JSON.parse(this.data.get("fields")).length) {
      if(filter.operator == "OR"){
        for(let i = 0; i < filter.expressions.length; i++){
          let expression = filter.expressions[i]
          let new_rule_block = new RuleBlock(++this.ruleCounter, this, true, expression)
          this.ruleBlocks[this.ruleCounter] = new_rule_block
          new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
          if(filter.expressions[i+1]){
            let operator = filter.operator || ""
            $(this.rulesBlockTarget).append($("<div class='text-bold'></div>").text(operator))
          }
        }
      }
      else{
        let new_rule_block = new RuleBlock(++this.ruleCounter, this, true, filter)
        this.ruleBlocks[this.ruleCounter] = new_rule_block
        new_rule_block.attachRuleBlock($(this.rulesBlockTarget))
      }
    }

    this.setupStatusToggle()
  }

  setupStatusToggle(e) {
    const toggle = $("#ruleStatusToggle")
    const url = this.data.get("toggleStatusUrl")
    toggle.change(() => {
      $.ajax({
        url: url,
        method: "post",
        complete: (response, xhr, settings) => {
          if(response.status == 200){
            flashMessage(`Rule successfully ${response.responseJSON.status}`, "success", this.element)
          }
          else{
            flashMessage("Unable to toggle status on rule", "warning", this.element)
          }
        }
      })
    })
  }

  showRuleMatches(e){
    if(e.target.tagName != "A")
      return
    this.tabChanged(e)
    this.rulesMatchesTableTarget.classList.add("active")
  }

  showRuleActions(e){
    this.tabChanged(e)
    this.rulesActionsTableTarget.classList.add("active")
  }

  tabChanged(e){
    this.tabsTarget.children.forEach(element => {
      element.classList.remove("active")
    })

    e.target.parentElement.classList.add("active")

    document.getElementsByClassName("tab-pane").forEach(element => {
      element.classList.remove("active")
    })
  }
}
