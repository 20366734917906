import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleDetails() {
    if (this.element.nextElementSibling.classList.contains("hidden")) {
      this.displayDetails()
    } else {
      this.element.nextElementSibling.classList.add("hidden")
      this.toggleArrowIcons()
    }
  }

  displayDetails() {
    this.toggleArrowIcons()
    this.element.nextElementSibling.classList.remove("hidden")
  }

  toggleArrowIcons() {
    this.toggleArrowIcon(this.element.children[0].children[0])
    this.toggleArrowIcon(this.element.children[0].children[1])
  }

  toggleArrowIcon(arrow) {
    if (arrow.classList.contains("hidden")) {
      arrow.classList.remove("hidden")
    } else {
      arrow.classList.add("hidden")
    }
  }
}
