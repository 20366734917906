import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["destroyInput", "rowContainer", "positionText", "positionInput"]

  connect() {
    if (this.hasDestroyInputTarget && this.destroyInputTarget.value === "true") {
      this.destroyRow()
    }
  }

  updatePositionText() {
    if (this.hasPositionTextTarget && this.hasPositionInputTarget) {
      this.positionTextTarget.innerHTML = this.positionInputTarget.value
    }
  }

  positionTextTargetConnected() {
    this.updatePositionText()
  }

  positionChanged(event) {
    this.updatePositionText()
  }

  handleCheckBox(event) {
    if (event.target.checked) {
      this.keepRow()
    } else {
      this.destroyRow()
    }
  }

  destroyRow() {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.value = true
    }
    if (this.hasRowContainerTarget) {
      this.rowContainerTarget.classList.add("hide")
    }
  }

  restoreRow() {
    this.keepRow()
    if (this.hasRowContainerTarget) {
      this.rowContainerTarget.classList.remove("hide")
    }
  }

  keepRow() {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.value = false
    }
  }

  positionDropdown(e) {
    const offset =e.target.getBoundingClientRect().top - e.target.closest(".tab-content").getBoundingClientRect().top
    e.target.parentElement.querySelector(".dropdown-menu").style.top = `${offset}px`
  }
}
