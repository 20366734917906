import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flash"]

  connect() {
    const dismissTimeout = (this.data.get("dismissTimeout") || 60) * 1000
    setTimeout(() => {
      this.dismiss()
    }, dismissTimeout)
  }

  dismiss() {
    this.flashTarget.classList.remove("fadeInDown")
    this.flashTarget.classList.add("fadeOutDown")

    this.element.addEventListener("animationend", () => {
      this.element.remove()
    })
  }
}
