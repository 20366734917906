import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    baseFormId: String
  }

  connect() {
    this.element.addEventListener("turbo:submit-start", this.onSubmitStart.bind(this))
  }

  async onSubmitStart(event) {
    for (const [key, value] of this.#baseFormData) {
      event.detail.formSubmission.body.append(key, value)
    }
  }

  get #baseFormData() {
    return new FormData(document.getElementById(this.baseFormIdValue))
  }
}
