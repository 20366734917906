import { Controller } from "@hotwired/stimulus"
import { parse_color } from "utils"
import { Chart } from "chart.js"
import merge from "lodash.merge"

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    switch (this.data.get("type")) {
    case "doughnut":
      this.doughnut_chart()
      break
    case "stacked_bar":
      this.stacked_bar_chart()
      break
    default:
      this.default_chart()
      break
    }
  }

  default_chart() {
    let default_options = {
      plugins: {
        legend: {
          display: this.legend,
          align: "center"
        }
      },
      scales: {
        yAxes: [{
          gridLines: {
            drawTicks: false
          },
          ticks: {
            min: 0,
            stepSize: 1,
            suggestedMax: 20,
            maxTicksLimit: 10,
            padding: 10,
            userCallback: function(value, index, values) {
              return Number(value).toLocaleString()
            }
          }
        }],
        xAxes: [{
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          }
        }]
      }
    }
    var { yAxesNotation, tooltipNotation, ...custom_options } = this.options
    let options = Object.assign(default_options, custom_options)

    if (yAxesNotation) {
      options = merge(options, {
        scales: {
          yAxes: [
            {
              ticks: {
                userCallback: function(value, index, values) {
                  return Intl.NumberFormat(undefined, { notation: yAxesNotation }).format(value)
                }
              }
            }
          ]
        }
      })
    }

    if (tooltipNotation) {
      options = merge(options, {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || ""
              label += ": "
              label += Intl.NumberFormat(undefined, { notation: tooltipNotation }).format(tooltipItem.value)
              return label
            }
          }
        }
      })
    }

    const chart = new Chart(this.chartTarget.getContext("2d"), {
      type: this.data.get("type"),
      data: {
        datasets: this.datasets,
        labels: this.labels
      },
      options: options
    })

    $(this.chartTarget).data("chart", chart)
  }

  doughnut_chart() {
    let default_options = {
      legend: {
        display: this.legend
      }
    }
    let options = Object.assign(default_options, this.options)
    const chart = new Chart(this.chartTarget.getContext("2d"), {
      type: "doughnut",
      data: {
        datasets: this.datasets,
        labels: this.labels
      },
      options: options
    })

    $(this.chartTarget).data("chart", chart)
  }

  stacked_bar_chart() {
    const options = {
      interaction: {
        intersect: false,
      },
      legend: false,
      responsive: true,
      scales: {
        xAxes: [{
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          }
        }],
        yAxes: [{
          categoryPercentage: 1.0,
          barPercentage: 0.95,
          stacked: true,
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            padding: 10,
            min: 0,
            stepSize: 1,
            suggestedMax: 20,
            maxTicksLimit: 10,
            beginAtZero: true,
          }
        }]
      }
    }

    const config = {
      type: "bar",
      data: {
        datasets: this.datasets,
        labels: this.labels
      },
      options: Object.assign(options, this.options)
    }

    const chart = new Chart(this.chartTarget.getContext("2d"), config)
    $(this.chartTarget).data("chart", chart)
  }

  get datasets() {
    let datasets = this.raw_datasets
    datasets.forEach(function(dataset, index) {
      if ("borderColor" in dataset && dataset.borderColor.startsWith("--")) {
        dataset.borderColor = parse_color(dataset.borderColor)
      }

      if ("backgroundColor" in dataset) {
        if (typeof dataset.backgroundColor == "object" || dataset.backgroundColor instanceof Array) {
          dataset.backgroundColor.forEach(function(value, index) {
            dataset.backgroundColor[index] = parse_color(value)
          })
        } else if (typeof dataset.backgroundColor == "string") {
          dataset.backgroundColor = parse_color(dataset.backgroundColor)
        }
      }
    })

    return datasets
  }

  get raw_datasets() {
    return JSON.parse(this.data.get("datasets"))
  }

  get labels() {
    return JSON.parse(this.data.get("labels"))
  }

  get legend() {
    return this.data.get("legend") || false
  }

  get options(){
    if(this.data.has("options"))
      return JSON.parse(this.data.get("options"))
    else
      return {}
  }
}
