import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["calendar", "exportStartDate", "exportEndDate", "exportButton", "calendarContainer", "exportContainer"]

  initialize() {
    this.onDateSelect = this.onDateSelect.bind(this)
    this.element["controller"] = this
  }

  connect() {
    this.initializeCalendar()
    this.activeInputTarget = this.exportStartDateTarget
  }

  get activeInputTarget() {
    return this.inputTarget
  }

  set activeInputTarget(target) {
    this.element.querySelectorAll("input").forEach(el => el.style.removeProperty("border-color"))
    target.style.borderColor = "#3498DB"
    this.inputTarget = target
  }

  onInputFocus(e) {
    e.preventDefault()
    this.activeInputTarget = e.target
    this.initializeCalendar()
  }

  initializeCalendar() {
    const startDate = this.exportStartDateTarget.value

    $(this.calendarTarget).datepicker("destroy")
    $(this.calendarTarget).datepicker({
      startDate: startDate && this.activeInputTarget != this.exportStartDateTarget ? new Date(startDate) : null,
      endDate: new Date()
    }).on("changeDate", this.onDateSelect)
  }

  onDateSelect(e) {
    const value = e.date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })
    this.activeInputTarget.value = value

    if(this.activeInputTarget === this.exportStartDateTarget) {
      this.activeInputTarget = this.exportEndDateTarget
    }
  }

  onExportRequest() {
    const urlParams = {}
    if(this.exportStartDateTarget.value) {
      urlParams["start_date"] = this.exportStartDateTarget.value
    }
    if(this.exportEndDateTarget.value) {
      urlParams["end_date"] = this.exportEndDateTarget.value
    }
    const paramString = Object.keys(urlParams).map(param => `${param}=${urlParams[param]}`).join("&")
    window.open(`${window.location.href}.csv?${paramString}`, "_blank")
  }

  onCalendarTransition() {
    this.exportContainerTarget.classList.add("hidden")
    this.calendarContainerTarget.classList.remove("hidden")
  }

  onExportViewable() {
    window.open(`${window.location.href}.csv?export_viewable=1`, "_blank")
  }

  resetState() {
    this.exportContainerTarget.classList.remove("hidden")
    this.calendarContainerTarget.classList.add("hidden")
  }
}
