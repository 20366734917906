import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "formToggle",
    "panelBody",
    "seatsInput",
    "submitButton",
    "footerText",
    "confirmDisableModal",
  ]

  static values = {
    activeSeats: Boolean,
  }

  connect() {
    this.formToggleTarget.onchange = this.toggleForm.bind(this)
  }

  toggleForm() {
    if (this.formToggleTarget.checked) {
      this.enableForm()
    } else if (this.activeSeatsValue) {
      this.showConfirmationModal()
    } else {
      this.disableForm()
    }
  }

  enableForm() {
    this.seatsInputTarget.disabled = false

    // Only override these values if there aren't any active seats.
    // Don't override when canceling the confirmation modal with active seats.
    if (this.activeSeatsValue === false) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.replace("btn-secondary", "btn-success")
    }

    this.panelBodyTarget.hidden = false
  }

  disableForm(e) {
    $(this.confirmDisableModalTarget).modal("hide")
    this.panelBodyTarget.hidden = true
    this.seatsInputTarget.disabled = true
    this.seatsInputTarget.value = "20"
    this.submitButtonTarget.value = "Activate"
    this.footerTextTarget.textContent = "Click 'Activate' to enable (Max 100 Licenses)"

    // Only send a message if there were active seats prior to disabling
    if (this.activeSeatsValue) {
      this.activeSeatsValue = false
      this.emulateFlash(e, "success")
    }
  }

  seatsChanged() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.replace("btn-secondary", "btn-success")
  }

  handleSuccess(e) {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.replace("btn-success", "btn-secondary")
    this.submitButtonTarget.value = "Update"
    this.footerTextTarget.textContent = ""
    this.activeSeatsValue = true
    this.emulateFlash(e, "success")
  }

  handleError(e) {
    this.closeModal()
    this.emulateFlash(e, "danger")
  }

  closeModal() {
    // Since we cancelled the modal, we need to set the input back to the correct state
    $(this.formToggleTarget).bootstrapToggle("on")
    $(this.confirmDisableModalTarget).modal("hide")
  }

  showConfirmationModal() {
    $(this.confirmDisableModalTarget).modal("show")
  }

  emulateFlash(e, status) {
    const innerElement = `<div class='alert alert-${status} alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>${e.detail[0].message}</div>`

    $("#alert-box").empty().append(innerElement)
  }
}
