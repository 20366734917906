import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loading", "datatable", "table", "popover"]
  connect() {
    $("#filter-date-range").hide()
    this.element[this.identifier] = this
    this.setupDataTable()
  }

  rowCallback(row, data) {
    if(data[0].includes("fa-ban")) {
      row.classList.add("italic-light-text")
    }
  }

  setupDataTable() {
    $(this.tableTarget).dataTable({
      ajax: this.element.dataset.url,
      autoWidth: false,
      destroy: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      processing: true,
      responsive: true,
      rowCallback: this.rowCallback,
      searching: true,
      serverSide: true,
      stateDuration: 300,
      stateSave: true,
    })
  }

  reload() {
    $(this.tableTarget).dataTable().api().ajax.reload()
  }
}
