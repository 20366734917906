import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.indentProcess()
  }

  indentProcess() {
    this.element.style.flexBasis = `${this.timelineWidth - this.itemIndent}px`
    this.indentation.style.flexBasis = `${this.itemIndent}px`
  }

  get itemIndent() {
    const minWidth = 600
    const totalIndentationAvailable = this.timelineWidth - minWidth

    const maxIndentation = 105
    if (maxIndentation * this.processTreeDepth > totalIndentationAvailable) {
      return (totalIndentationAvailable / this.processTreeDepth) * this.idx
    } else {
      return maxIndentation * this.idx
    }
  }

  get timelineWidth() {
    return document.getElementById("process-timeline").offsetWidth
  }

  get processTreeDepth() {
    return this.data.get("processTimelineDepth")
  }

  get idx() {
    return this.data.get("idx")
  }

  get indentation() {
    return this.element.previousElementSibling
  }
}
