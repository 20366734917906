// Use this controller when you have a datalist which has a label AND a value that is different
// datalists are great when using with text inputs, but you don't always want the label and value to be the same
// This adds support for showing one value in the UI, but a different value being set
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"]
  static values = {
    attribute: String
  }

  selected(ev) {
    const inputValue = ev.currentTarget.value
    const selectedDatalistOption = this.element.querySelector(`option[value="${inputValue}"]`)

    if (selectedDatalistOption) {
      const value = selectedDatalistOption.getAttribute(this.attributeName)

      this.outputTarget.value = value || inputValue
    } else {
      this.outputTarget.value = inputValue
    }
  }

  get attributeName() {
    return this.attributeValue || "value"
  }
}
