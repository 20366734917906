import { Controller } from "@hotwired/stimulus"
import { getSelectedIds } from "../../helpers/bulkActionHelpers"

export default class extends Controller {

  static targets = ["selectedAgentIdsContainer", "bulkInstallModal", "bulkInstallScope"]

  bulkInstallAll() {
    this.showBulkInstallModal([], "all eligible")
  }

  bulkInstallSelected(event) {
    const target = event.currentTarget
    this.showBulkInstallModal(getSelectedIds(target.dataset.scope).split(","), "the selected")
  }

  showBulkInstallModal(agentIds, scopeText) {
    const childNodes = agentIds.map(id => {
      const element = document.createElement("input")
      element.type = "hidden"
      element.name = "agent_ids[]"
      element.value = id

      return element
    })

    this.selectedAgentIdsContainerTarget.replaceChildren(...childNodes)
    this.bulkInstallScopeTarget.textContent = scopeText

    $(this.bulkInstallModalTarget).modal()
  }
}
