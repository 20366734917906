import { initTable } from "huntressHelpers"

$(function() {
  if ($("#org-binaries-index").length < 1) return;

  var datatable_params = {
    language: {
      emptyTable: 'No binaries found for this classification'
    }
  };

  $('a[data-toggle="tab"]').on('click', function (e) {
    var table = $('#datatable table').DataTable();
    table.state.clear();
    table.destroy();
    initTable($(e.target).data('classification'), datatable_params);
    localStorage.setItem('lastBinariesTab', $(e.target).data('classification'));
  });

  var lastBinariesTab = localStorage.getItem('lastBinariesTab');
  if (lastBinariesTab) {
    $('a[data-classification="' + lastBinariesTab + '"]').tab('show');
  }

  $('#datatable table').on('init.dt', function() {
    $("#loading").hide();
    $("#datatable").fadeIn();
  });

  initTable($('#datatable ul li.active a').data('classification'), datatable_params);
});
