import { Controller } from "@hotwired/stimulus"
import { getSelectedIds } from "../../../helpers/bulkActionHelpers"
import * as Routes from "routes"

export default class extends Controller {
  connect() {
    $("#organization_key").change(function() {
      this.value = parameterize(this.value)
    })

    $("#delete-organization-modal").on("show.bs.modal", function(e) {
      var data = $(e.relatedTarget).data()
      $(".organization-name", this).text(data.name)
      $("form", this).attr("action", data.path)
    })

    $("form.edit_organization").submit(function() {
      $(this).find("input[type=\"submit\"]")
        .val("Deleting...")
        .attr("disabled", "disabled")

      $(this).find("a[data-dismiss]")
        .attr("disabled", "disabled")
        .click(function(e){ e.preventDefault(); return false })

      return true
    })
  }

  bulkIsolate(event) {
    const url = Routes.bulk_isolate_modal_account_organizations_path()
    const ids = getSelectedIds().split(", ")
    const options = {
      url: url,
      data: { organization_ids: ids }
    }

    this.dispatch("openModal", {
      detail: {
        options: options,
      },
      prefix: false
    })
  }

  bulkRelease(event) {
    const url = Routes.bulk_release_modal_account_organizations_path()
    const ids = getSelectedIds().split(", ")
    const options = {
      url: url,
      data: { organization_ids: ids }
    }
    
    this.dispatch("openModal", {
      detail: {
        options: options,
      },
      prefix: false
    })
  }
}
