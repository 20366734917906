import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"
import * as Routes from "routes"

export default class extends Controller {
  connect() {
    new Chart("systems-chart", {
      type: "doughnut",
      data: this.donutChartData,
      options: {
        legend: {
          display: false,
        },
        onClick: function(evt) {
          var activeElement = this.getElementAtEvent(evt)
          if (activeElement.length < 1) return
          if (activeElement[0]._index == 1)
            window.location.href = huntressData.unresponsive_agents_url
          else if (activeElement[0]._index == 2)
            window.location.href = huntressData.outdated_agents_url
          else if (activeElement[0]._index == 3)
            window.location.href = huntressData.isolated_agents_url
          else
            window.location.href = huntressData.live_agents_url
        }
      }
    })

    new Chart("agents-chart", {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.agent_counts_values,
          backgroundColor: ["#18BC9C"],
        }],
        labels: huntressData.agent_counts_labels,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              precision: 0,
              beginAtZero: true
            }
          }]
        },
      }
    })

    new Chart("surveys-chart", {
      type: "line",
      data: {
        datasets: [{
          data: huntressData.survey_values,
          backgroundColor: "#3498db",
          borderColor: "#3498db",
          fill: false,
        }],
        labels: huntressData.survey_labels,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              precision: 0,
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15,
            }
          }]
        }
      }
    })
  }

  get donutChartData() {
    return {
      datasets: [{
        data: [
          huntressData.total_agents - huntressData.unresponsive_agents - huntressData.outdated_agents - huntressData.isolated_agents,
          huntressData.unresponsive_agents,
          huntressData.outdated_agents,
          huntressData.isolated_agents,
        ],
        backgroundColor: ["#18BC9C", "#f39c12", "#e74c3c", "#6f42c1"],
      }],
      labels: ["Live Agents", "Unresponsive Agents", "Outdated Agents", "Isolated Agents"],
    }
  }

  showProfileNavMenu() {
    $(".nav li.dropdown-profile-menu").on("hide.bs.dropdown", () => false).addClass("open")
  }

  hideProfileNavMenu() {
    $(".nav li.dropdown-profile-menu").off("hide.bs.dropdown").removeClass("open")
  }

  showOrgMenu() {
    $(".nav li.dropdown-org-menu").on("hide.bs.dropdown", () => false).addClass("open")
  }

  hideOrgMenu() {
    $(".nav li.dropdown-org-menu").off("hide.bs.dropdown").removeClass("open")
  }

  acknowledgeProductViewed(product, type) {
    product = product === "sat" ? "security_awareness_training" : product
    const modalType = `${product}_${type}`
    return $.ajax({
      contentType: "application/json",
      data: JSON.stringify({ modal_type: modalType }),
      method: "PATCH",
      url: Routes.users_disable_modal_account_path({ format: "js" }),
    })
  }
}
