import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "inputContainer",
    "permissionAbilitiesLabel",
    "email",
    "currentPermissions"
  ]

  connect() {
    if (this.emailTarget.value === "") {
      this.submitButtonTarget.disabled = true
    } else {
      this.setAbilitiesLabel(this.currentPermissionsTarget.value)
    }
  }

  onNewUserEmailChange(e) {
    // Simple check for input presence that lines up with what an email looks like
    this.submitButtonTarget.disabled = !this.isValidEmail(e.target.value)
    this.setHelpBlockDetails(e.target.value)
  }

  setHelpBlockDetails(value) {
    let helpBlock = this.inputContainerTarget.querySelector(".help-block")
    let errorText = ""

    if(!value) {
      errorText = "A value must be provided for the user email."
    }
    else if(!this.isValidEmail(value)) {
      errorText = "Value provided for user email must be a valid email."
    }

    if(!helpBlock) {
      helpBlock = document.createElement("span")
      helpBlock.className = "help-block"
      this.inputContainerTarget.appendChild(helpBlock)
    }

    if(errorText) {
      helpBlock.innerHTML = errorText
      this.inputContainerTarget.classList.add("has-error")
    }
    else {
      helpBlock.remove()
      this.inputContainerTarget.classList.remove("has-error")
    }
  }

  isValidEmail(value) {
    return /.+@.+\..+/.test(value)
  }

  updateAbilitiesLabel(e) {
    this.setAbilitiesLabel(e.target.value)
  }

  setAbilitiesLabel(ability) {
    const permissionAbilities = huntressData.permissionAbilities[ability]
    const abilitiesLabel = permissionAbilities != null ? `Abilities: ${permissionAbilities}` : ""

    this.permissionAbilitiesLabelTarget.innerText = abilitiesLabel
  }
}
