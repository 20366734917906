import { Controller } from "@hotwired/stimulus"
import {formValidation, plugins} from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "country",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipCode",
    "addressForm",
    "addressOptions"
  ]

  connect() {
    if (this.countryTarget.value === "") {
      this.toggleAllButCountry(true)
    } else {
      this.setCountry()
    }

    this.smartyKey = this.addressFormTarget.dataset.smartyKey
    this.addressType = this.addressFormTarget.dataset.addressType

    this.setupFormValidation()
    this.windowClickHandler = () => this.hideCandidatesBox()
  }

  validateAddressFields(event) {
    if (this.addressType === "shipping") {
      if (event.detail.skipShippingValidation) {
        event.detail.shippingAddressPassingValidationCb("Valid")
      } else {
        const promise = this.fv.validate()
        event.detail.shippingAddressPassingValidationCb(promise)
      }
    } else {
      const promise = this.fv.validate()
      event.detail.billingAddressPassingValidationCb(promise)
    }
  }

  setCountry() {
    // The countryAlpha3 (three char country code) is needed to make reqs for international address
    this.countryAlpha3 = this.countryTarget.children[this.countryTarget.selectedIndex].dataset.alpha3
    this.countryIsUS = this.countryTarget.value === "US"
  }

  toggleAllButCountry(isDisabled) {
    this.addressLine1Target.disabled = isDisabled
    this.addressLine2Target.disabled = isDisabled
    this.cityTarget.disabled = isDisabled
    this.stateTarget.disabled = isDisabled
    this.zipCodeTarget.disabled = isDisabled
  }

  handleCountryChange(e) {
    this.setCountry()
    // We find out if the country is supported based on the first response from Smarty.
    // See handleSmartyError()
    this.countryNotSupported = false

    if (this.countryTarget.value === "") {
      this.toggleAllButCountry(true)
    } else {
      this.toggleAllButCountry(false)
      this.clearForm()
    }

    this.fv.resetForm()
  }

  clearForm() {
    this.populateForm({ address: "", city: "", state: "", zip: "" })
  }

  populateForm({ address, city, state, zip }) {
    this.addressLine1Target.value = address
    this.cityTarget.value = city
    this.stateTarget.value = state
    this.zipCodeTarget.value = zip
  }

  async handleAddressSelect({ target }) {
    if (target.tagName === "SPAN") target = target.parentElement

    if (target.dataset.address_id) {
      await this.handleSmartyAddressIdSearch(target.dataset.address_id)
    } else {
      this.populateForm(target.dataset)
      this.hideCandidatesBox()
      this.fv.validate()
    }
  }

  appendCandidates(candidates) {
    this.showCandidatesBox()
    candidates.forEach(candidate => {
      let option
      if (candidate.address_id) {
        option = this.buildCandidateFromAddressLine(candidate)
      } else {
        option = this.buildCandidateOption(candidate)
      }
      this.addressOptionsTarget.appendChild(option)
    })
  }

  buildCandidateOption(candidate) {
    const { address, city, state, zip } = this.extractAddressInfoFromCandidate(candidate)

    const option = document.createElement("li")
    option.classList.add("autocomplete-address-option")
    option.dataset.address = address
    option.dataset.city = city
    option.dataset.state = state
    option.dataset.zip = zip

    const addressText = document.createElement("span")
    addressText.innerText = address

    const cityStateZipText = document.createElement("span")
    cityStateZipText.classList.add("city-state-zip")
    cityStateZipText.innerText = [city, state, zip].filter(text => !!text).join(", ")

    option.appendChild(addressText)
    option.appendChild(cityStateZipText)
    return option
  }

  buildCandidateFromAddressLine(candidate) {
    const { address_text, address_id } = candidate

    const option = document.createElement("li")
    option.classList.add("autocomplete-address-option")
    option.dataset.address = address_text
    option.dataset.address_id = address_id

    const addressText = document.createElement("span")
    addressText.innerText = address_text

    option.appendChild(addressText)
    return option
  }

  async addressLineChange(event) {
    if (event.target.value === "") {
      this.hideCandidatesBox()
    } else {
      await this.handleSmartySearch(event.target.value.trim())
    }
  }

  async handleSmartySearch(search) {
    if (!this.smartyKey || this.countryNotSupported) return
    if (this.countryIsUS && this.usApiDisabled) return
    if (!this.countryIsUS && this.intlApiDisabled) return

    const endpoint = this.countryIsUS ? this.usSearchUrl : this.internationalSearchUrl
    const res = await fetch(`${endpoint}&search=${search}`)
    const parsed = await res.json()

    if (res.status !== 200) {
      this.handleSmartyError(res, parsed)
    } else if (parsed.candidates || parsed.suggestions) {
      this.addressOptionsTarget.innerHTML = ""
      this.appendCandidates(parsed.candidates || parsed.suggestions)
    }
  }

  // Smarty International autocomplete v2
  async handleSmartyAddressIdSearch(addressId) {
    const res = await fetch(this.internationalSearchUrlForAddressId(addressId))
    const parsed = await res.json()

    if (res.status !== 200) {
      this.handleSmartyError(res, parsed)
    } else if (parsed.candidates || parsed.suggestions) {
      this.addressOptionsTarget.innerHTML = ""
      this.appendCandidates(parsed.candidates || parsed.suggestions)
    }
  }

  handleSmartyError(res, parsedRes) {
    // Unsupported country
    if (res.status === 422 && parsedRes.errors[0].fields.includes("query:country")) {
      this.countryNotSupported = true
      return
    }

    // Smarty says:
    //  Payment Required: There is no active subscription for the account
    //  associated with the credentials submitted with the request.
    //
    // This can happen if we don't have the proper subscription setup
    // in Smarty or if we run out of calls for the month.
    // Because Smarty has two separate endpoints/subscriptions for US and International calls
    // we should disable only for the one currently being searched, cause it could be
    // that the other still has room left in the subscription.
    if (res.status === 402) {
      if (this.countryIsUS) {
        this.usApiDisabled = true
      } else {
        this.intlApiDisabled = true
      }
    }

    throw new Error(`Smarty API Error (${res.status}) - ${parsedRes.errors[0].message}`)
  }

  extractAddressInfoFromCandidate(candidate) {
    const {
      street,
      street_line,
      city,
      locality,
      state,
      zipcode,
      postal_code,
      administrative_area,
      sub_administrative_area,
      super_administrative_area
    } = candidate
    if (this.countryIsUS) {
      return { address: street_line, city, state, zip: zipcode }
    } else {
      return {
        address: street,
        city: locality || "",
        state: administrative_area || super_administrative_area || sub_administrative_area || "",
        zip: postal_code || ""
      }
    }
  }

  showCandidatesBox() {
    this.addressOptionsTarget.classList.remove("hidden")
    window.addEventListener("click", this.windowClickHandler)
  }

  hideCandidatesBox() {
    this.addressOptionsTarget.classList.add("hidden")
    window.removeEventListener("click", this.windowClickHandler)
  }

  get usSearchUrl() {
    return `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${this.smartyKey}`
  }

  get internationalSeachEndpoint() {
    return "https://international-autocomplete.api.smarty.com/v2/lookup"
  }

  get internationalSearchUrl() {
    return `${this.internationalSeachEndpoint}?key=${this.smartyKey}&country=${this.countryAlpha3}`
  }

  internationalSearchUrlForAddressId(addressId) {
    return `${this.internationalSeachEndpoint}/${addressId}?key=${this.smartyKey}&country=${this.countryAlpha3}`
  }

  setupFormValidation() {
    const validationObject = (message, callback = (input) => input.value !== "") => ({
      validators: { callback: { message, callback } }
    })

    this.fv = formValidation(this.addressFormTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "input blur"
        })
      },
      fields: {
        [this.addressLine2Target.name]: { validators: {} },
        [this.countryTarget.name]: validationObject("Country is required."),
        [this.addressLine1Target.name]: validationObject("Address is required."),
        [this.cityTarget.name]: validationObject("City is required."),
        [this.stateTarget.name]: validationObject(null, this.stateValidation.bind(this)),
        [this.zipCodeTarget.name]: validationObject(null, this.zipValidation.bind(this)),
      }
    })
  }

  // If US or Canada is set we validate that state is 2 characters exactly.
  // Otherwise it just needs to be present
  stateValidation({ value, options }) {
    const defaultMessage = "State/region/province is required."
    if (["US", "CA"].includes(this.countryTarget.value)) {
      options.message = value.length === 0 ? defaultMessage : "Must be two characters."
      return value.length === 2
    } else {
      options.message = defaultMessage
      return value !== ""
    }
  }

  // If US is set we validate that ZIP is 5 numbers exactly.
  // Otherwise we only enforce GB (UK) has a value here.
  zipValidation({ value, options }) {
    options.message = "ZIP/Postal code is required."
    if (this.countryTarget.value === "US") {
      if (value !== "") {
        const isNumber = /^\d+$/.test(value)
        options.message = isNumber ? "ZIP must be five characters." : "ZIP must be a number."
      }
      return value.length === 5
    } else {
      return (this.countryTarget.value !== "GB") ? true : (value !== "")
    }
  }
}
