import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapsedContent", "expandedContent"]

  expandText() {
    this.collapsedContentTarget.classList.add("hidden")
    this.expandedContentTarget.classList.remove("hidden")
  }

  collapseText() {
    this.collapsedContentTarget.classList.remove("hidden")
    this.expandedContentTarget.classList.add("hidden")
  }
}
