import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    function updateModal(e) {
      var data = $(e.relatedTarget).data()
      var modal = $(this)
      modal.find(".account-name").text(data.name)
      modal.find("form").attr("action", data.path)
      modal.find("form").attr("id", data.id)
    }

    $("#delete-account-modal").on("show.bs.modal", updateModal)

    $("#activate-account-modal").on("show.bs.modal", updateModal)
  }
}
