import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["secretKey"]

  connect() {
    $(function(){
      $(".secret-installer-link").on("click", function() {
        $(this).siblings().addBack().toggleClass("hidden")
      })
    })
  }

  showSecret() {
    $(this.secretKeyTarget).val(this.data.get("key"))
  }
}
