import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    showOnLoad: Boolean
  }

  connect() {
    if (this.showOnLoad) {
      $(this.element).modal("show")
    }

    $(this.element).on("hide.bs.modal", this.dismiss.bind(this))
  }

  dismiss() {
    this.showOnLoad = false
  }

  set showOnLoad(value) {
    if (!value) {
      localStorage.setItem("hideTourModalOnLoad", true)
    } else {
      localStorage.removeItem("hideTourModalOnLoad")
    }
  }

  get showOnLoad() {
    return this.showOnLoadValue && !localStorage.getItem("hideTourModalOnLoad")
  }
}
