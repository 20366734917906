import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "billingIntervalSelect"
  ]

  billingIntervalChanged(e){
    this.dispatch("billingIntervalChanged", { detail: { amount: e.currentTarget.value } })
  }
}
