import { Controller } from "@hotwired/stimulus"
import { getSelectedIdsCount, getSelectedIds } from "../../../helpers/bulkActionHelpers"

export default class extends Controller {
  static targets = [
    "bulkReport",
    "generateConfirmationModal",
    "signalCreationCount"
  ]

  connect() {
    $("a.suspicious")
      .on("ajax:success", function(_) {
        let row = $(this).closest("tr")
        let autorun_id = row.data("autorun-id")
        row.siblings("tr[data-autorun-id='"+autorun_id+"']").addBack().each(function() {
          $(this).children("td:last-child").children().remove()
          $(this).children("td:nth-child(4)").text("Suspicious")
        })
      })
      .on("ajax:error", function(event) {
        let xhr = event.detail[2]
        alert(`Error: ${xhr.status} - ${xhr.responseText}`)
      })

    $("a.investigate")
      .on("ajax:success", function(_) {
        let row = $(this).closest("tr")
        let autorun_id = row.data("autorun-id")
        row.siblings("tr[data-autorun-id='"+autorun_id+"']").addBack().each(function() {
          $(this).children("td:last-child").children().remove()
        })
        $("#alert-box").append("<div class='alert alert-success alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>Investigation created.</div>")
      })
      .on("ajax:error", function(event) {
        let xhr = event.detail[2]
        alert(`Error: ${xhr.status} - ${xhr.responseText}`)
      })

    $("a.investigate_autorun_entry")
      .on("ajax:success", function(_) {
        let row = $(this).closest("tr")
        row.children("td:last-child").children().remove()
        $("#alert-box").append("<div class='alert alert-success alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>Investigation created.</div>")
      })
      .on("ajax:error", function(event) {
        let xhr = event.detail[2]
        alert(`Error: ${xhr.status} - ${xhr.responseText}`)
      })

    $("table#results").on("click", ".accordion-toggle", function(event) {
      if ($(event.target).closest(".no-toggle-expand").length === 0) {
        $($(this).data("target")).collapse("toggle")
      }
    })

    $("form.bulk_categorize")
      .on("ajax:success", function(_) {
        window.location.reload(true)
      })
      .on("ajax:error", function(event) {
        let errorMsg = event.detail[0].error
        $("#bulk-categorize-modal .modal-dialog").append("<div class='alert alert-danger alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>" + errorMsg + "</div>")
        $("#bulk-categorize-modal").on("hide.bs.modal", function () {
          $("#bulk-categorize-modal .alert").alert("close")
        })
      })

    $("#bulk-categorize-modal").on("shown.bs.modal", function() {
      $("#bulk-categorize-modal select").select2("open")
    })
  }

  get ajaxModalController() {
    return this.element["components--ajax-modal"]
  }

  get signalsController() {
    return this.element["admin--signals--signals"]
  }

  checkboxToggled() {
    let agentIds = new Set()
    $("#results").find(".bulk-select:checked").each((index, element) => {
      agentIds.add(element.dataset.agentId)
    })

    if (agentIds.size === 1) {
      this.enabledBulkReport()
    } else {
      this.disableBulkReport()
    }
  }

  enabledBulkReport() {
    this.bulkReportTarget.removeAttribute("disabled")
  }

  disableBulkReport() {
    this.bulkReportTarget.setAttribute("disabled", "disabled")
  }

  bulkGenerateModal() {
    this.signalCreationCountTarget.textContent = getSelectedIdsCount("#results").toString()
    $(this.generateConfirmationModalTarget).modal("show")
  }

  generateBulk() {
    const resourceType = "AutorunEntry"
    const scope = "#results"
    const reloadOnClose = true
    const followupWithReport = false
    const resourceIds = getSelectedIds(scope).split(", ")
    const resources = resourceIds.map(id => ({ type: resourceType, id: id }))

    let whenModalFinishesOpening = () => {
      this.signalsController.generate(resources, reloadOnClose, followupWithReport)
    }

    let whenModalFinishesClosing = () => {
      $(this.ajaxModalController.getModalElement()).one("shown.bs.modal", whenModalFinishesOpening)
      this.ajaxModalController.showAjaxModalContent()
      this.ajaxModalController.showAjaxModalLoading()
    }

    $(this.generateConfirmationModalTarget).one("hidden.bs.modal", whenModalFinishesClosing)

    $(this.generateConfirmationModalTarget).modal("hide")
  }
}
