import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

export default class extends Controller {
  static targets = ["deployedVersions", "callbackCounts"]

  connect() {
    new Chart($(this.deployedVersionsTarget), {
      type: "doughnut",
      data: {
        labels: huntressData.rio_versions,
        datasets: [
          {
            data: huntressData.rio_counts,
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
            ]
          }]
      },
      options: {
        legend: {
          display: true
        }
      }
    })
  }
}
