import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "modalBody" ]

  replaceParams(event) {
    const [, , xhr] = event.detail
    $(this.modalBodyTarget).html(xhr.responseText)
    $(this.modalTarget).modal("show")
  }
}
