import { Controller } from "@hotwired/stimulus"
import { getSelectedIds } from "../../../../helpers/bulkActionHelpers"
import * as Routes from "routes"
import { flashMessage } from "../../../../helpers/huntressHelpers"

export default class extends Controller {

  static targets = ["timerange", "clear"]
  static timerange = ""

  connect() {
    this.showLastTab()

    $(this.timerangeTarget).calentim({
      format: "YYYY-MM-DD HH:mm",
      hourFormat: "24",
      onbeforeshow:(calentim) => {
        this.timerange = this.timerangeTarget.value
      },
      onafterhide: (calentim) => {
        if (this.timerange == this.timerangeTarget.value)
          return
        
        this.timerange = this.timerangeTarget.value
        this.applyTimeRange(this.timerangeTarget.value)
      }
    })

    $(this.clearTarget).on("click", (_) => {
      this.applyTimeRange(null)
    })
  }

  applyTimeRange(timerange){
    let search = new URLSearchParams()

    if(timerange)
      search.set("filters[processes_timerange]", timerange)

    location.search = search.toString()
  }

  async generateIndividual(event) {
    const target = event.currentTarget
    const resourceId = target.dataset.resourceId
    const resources = [resourceId]
    const organizationId = target.dataset.organizationId
    const agentId = target.dataset.agentId
    const reloadOnClose = target.dataset.reloadOnClose

    await this.generate(resources, organizationId, agentId, reloadOnClose)
  }

  async generateBulk(event) {
    const target = event.currentTarget
    const scope = target.dataset.scope
    const resourceIds = getSelectedIds(scope).split(", ")
    const organizationId = target.dataset.organizationId
    const agentId = target.dataset.agentId
    const reloadOnClose = target.dataset.reloadOnClose

    await this.generate(resourceIds, organizationId, agentId, reloadOnClose)
  }

  async generate(resources, organizationId, agentId, reloadOnClose) {
    const options = {
      url: Routes.generate_organization_agent_processes_path(organizationId, agentId, { format: "json" }),
      method: "patch",
      contentType: "application/json",
      data: JSON.stringify({
        host_process_ids: resources
      }),
      success: (response) => this.signalsController.generate(response.map(id => ({ type: "ProcessDetection", id: id })), reloadOnClose),
      error: (response) => {
        flashMessage("Failed to find and/or generate process detections for the host process", "danger", this.element)
      }
    }

    $.ajax(options)
  }

  get signalsController() {
    return document.querySelector("[data-controller~='admin--signals--signals']")["admin--signals--signals"]
  }

  tabChanged(ev) {
    localStorage.setItem("lastAgentProcessesTab", ev.target.getAttribute("href"))
  }

  showLastTab() {
    const lastAgentProcessesTab = localStorage.getItem("lastAgentProcessesTab")
    if (lastAgentProcessesTab) {
      $(`a[href="${lastAgentProcessesTab}"]`).tab("show")
    }
  }
}
