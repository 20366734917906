import { Controller } from "@hotwired/stimulus"
import {formValidation, plugins} from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "confirmModal",
    "form",
    "stripeToken",
    "subscribeButton",
    "subscribeConfirmButton",
  ]

  connect() {
    this.setupFormValidation()
    this.setupStripe()
  }

  setupFormValidation() {
    this.fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        submitButton: new plugins.SubmitButton(),
        trigger: new plugins.Trigger({
          event: "blur"
        })
      },
      fields: {
        "cardholder-name": {
          validators: {
            notEmpty: {message: "Cardholder name is required."}
          }
        },
        "cardholder-phone": {
          validators: {
            notEmpty: {message: "Cardholder phone number is required."}
          }
        },
        "address-line-1": {
          validators: {
            notEmpty: {message: "Billing address is required."}
          }
        },
        "address-city": {
          validators: {
            notEmpty: {message: "Billing city is required."}
          }
        },
        "address-zip": {
          validators: {
            callback: {
              message: "Billing ZIP/Postal code is required.",
              callback: function(input) {
                const countryCode = document.querySelector("[name=address-country]").value
                return (countryCode !== "US" && countryCode !== "GB") || input.value !== ""
              }
            }
          }
        },
        "address-country": {
          validators: {
            notEmpty: {message: "Billing country is required."}
          }
        }
      }
    })
  }

  validate() {
    this.fv.validate()
  }

  setupStripe() {
    this.stripe = Stripe(this.data.get("stripePk"))
    this.card = this.stripe.elements().create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#3498db",
          color: "#32315E",
          // TODO: from the stripe console:
          // The use of the style property lineHeight is discouraged, because
          // it can lead to visual inconsistencies among various browsers.
          // Consider using a padding on the Element’s container instead.
          lineHeight: "45px",
          fontWeight: 400,
          fontSize: "15px",

          "::placeholder": {
            color: "#CFD7DF",
          }
        },
      }
    })
    this.card.mount("#card-element")
  }

  validateAndSubscribe() {
    this.subscribeConfirmButtonTarget.classList.remove("disabled")
    this.subscribeConfirmButtonTarget.removeAttribute("disabled")

    this.fv.validate().then(status => {
      if (status === "Valid") {
        $(this.confirmModalTarget).modal("show")
      }
    })
  }

  subscribe() {
    const form = this.formTarget

    // Disable the submit button so the form isn't accidentally submitted twice
    this.subscribeConfirmButtonTarget.disabled = true
    this.subscribeConfirmButtonTarget.setAttribute("disabled", true)

    // This form should only be submittable if the subscription-confirm modal is
    // visible. If the user presses 'enter' in the Stripe form, it will be
    // submitted and we'll catch it here that the modal isn't open
    if (!this.confirmModalTarget.classList.contains("in")) {
      return false
    }

    // When the form is submitted and passes all validations, this callback gets
    // called. Submission is blocked and the credit card details are submitted
    // to Stripe. If that succeeds, the returned token from Stripe gets added to
    // the form and it is submitted again. This time after passing validations,
    // the form contains the Stripe token so we're done here.
    if (this.stripeTokenTarget.value.length > 0) {
      return true
    }

    event.preventDefault()

    // Collect the name and address details for the card holder
    var extraDetails = {
      name: form["cardholder-name"].value,
      address_line1: form["address-line-1"].value,
      address_city: form["address-city"].value,
      address_zip: form["address-zip"].value,
      address_country: form["address-country"].value,
    }

    // Pass the details to Stripe
    this.createStripeToken(extraDetails).then((result) => {
      if (result.error) {
        const errorElement = document.getElementById("card-element-error")
        errorElement.classList.remove("hidden")
        errorElement.innerText = result.error.message

        // $("#card-element-error").removeClass("hidden").text(result.error.message)
        $(this.confirmModalTarget).modal("hide")

        this.subscribeButtonTarget.classList.remove("disabled")
        this.subscribeButtonTarget.removeAttribute("disabled")
      } else {
        this.stripeTokenTarget.value = result.token.id
        form.submit()
      }
    })

    return false
  }

  createStripeToken(cardholderInformation) {
    if (this.stripeTestToken) {
      // This enables system tests from calling out to Stripe for a token
      return Promise.resolve({ token: { id: this.stripeTestToken }})
    } else {
      return this.stripe.createToken(this.card, cardholderInformation)
    }
  }

  keydown(event) {
    // Since we want to force the user to view the modal dialog to ensure they
    // understand what they are agreeing to, we're blocking form submission by the
    // 'enter' key.
    if (event.keyCode === 13) {
      event.preventDefault()
      return false
    }
  }

  get stripeTestToken() {
    return this.data.get("stripeTestToken")
  }
}
