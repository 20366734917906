import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    [
      "navigationSection",
      "contentSection",
      "navBarExpand",
      "navBar"
    ]

  static values = {
    collapsed: Boolean
  }

  connect() {
    if (this.collapsedValue) {
      this.collapseNavBar()
    }
  }

  collapseNavBar(){
    this.navBarTarget.classList.add("hide")
    this.navBarExpandTarget.classList.remove("hide")

    this.contentSectionTarget.classList.remove("col-sm-10")
    this.contentSectionTarget.classList.add("col-sm-11")

    this.navigationSectionTarget.classList.remove("col-sm-2")
    this.navigationSectionTarget.classList.add("col-sm-1")

  }

  expandNavBar(){
    this.navBarTarget.classList.remove("hide")
    this.navBarExpandTarget.classList.add("hide")

    this.contentSectionTarget.classList.add("col-sm-10")
    this.contentSectionTarget.classList.remove("col-sm-11")

    this.navigationSectionTarget.classList.add("col-sm-2")
    this.navigationSectionTarget.classList.remove("col-sm-1")
  }
}
