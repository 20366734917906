import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

export default class extends Controller {
  static targets = ["conversions", "trials", "quantities", "invoicesUnpaid"]

  connect() {
    new Chart($(this.conversionsTarget), {
      type: "bar",
      data: {
        labels: huntressData.conversion_months,
        datasets: [{
          label: "Converted w/ Malware",
          backgroundColor: "#18BC9C",
          stack: "Stack 0",
          data: huntressData.converted_malware,
        }, {
          label: "Converted Clean",
          backgroundColor: "#3498DB",
          stack: "Stack 0",
          data: huntressData.converted_clean,
        }, {
          label: "Converted Reseller",
          backgroundColor: "#6F42C1",
          stack: "Stack 0",
          data: huntressData.converted_reseller,
        }, {
          label: "Not Converted w/ Malware",
          backgroundColor: "#E74C3C",
          stack: "Stack 1",
          data: huntressData.noconvert_malware,
        }, {
          label: "Not Converted Clean",
          backgroundColor: "#F39C12",
          stack: "Stack 1",
          data: huntressData.noconvert_clean,
        }],
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "Conversions"
            }
          }]
        }
      }
    })

    new Chart($(this.trialsTarget), {
      type: "bar",
      data: {
        labels: huntressData.trial_months,
        datasets: [{
          backgroundColor: "#18BC9C",
          data: huntressData.trial_values,
          fill: false
        }],
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        scales: {
          yAxes: [{
            label: "Trials"
          }]
        }
      }
    })

    new Chart($(this.quantitiesTarget), {
      type: "line",
      data: {
        labels: huntressData.quantity_months,
        datasets: [{
          label: "Term",
          backgroundColor: "#18BC9C",
          borderColor: "#18BC9C",
          data: huntressData.quantity_term,
          fill: false
        }, {
          label: "Non-Term",
          backgroundColor: "#3498DB",
          borderColor: "#3498DB",
          data: huntressData.quantity_nonterm,
          fill: false
        }]
      },
      options: {
        responsive: true,
        legend: {
          position: "bottom"
        }
      }
    })

    new Chart($(this.invoicesUnpaidTarget), {
      type: "bar",
      data: {
        labels: huntressData.unpaid_months,
        datasets: [{
          label: "Revenue",
          backgroundColor: "#3498DB",
          borderColor: "#3498DB",
          data: huntressData.unpaid_revenue,
          yAxisID: "right-y-axis",
          type: "line",
          fill: false
        }, {
          label: "Count",
          backgroundColor: "#F39C12",
          data: huntressData.unpaid_count,
          yAxisID: "left-y-axis",
          fill: false
        }]
      },
      options: {
        responsive: true,
        legend: {
          position: "bottom"
        },
        scales: {
          yAxes: [{
            id: "left-y-axis",
            position: "left",
            label: "Invocies",
            gridLines: {
              drawOnChartArea: false
            }
          }, {
            id: "right-y-axis",
            position: "right",
            ticks: {
              callback: function(value) {
                return "$" + value.toLocaleString()
              }
            }
          }]
        }
      }
    })
  }
}
