import BulkActionsController from "controllers/components/bulk_actions_controller"

export default class extends BulkActionsController {
  static targets = ["removeForm", "allowForm"]

  handleToggleSingle(e){
    super.handleToggleSingle(e)

    this.appendToForms()
  }

  handleToggleAll(e) {
    super.handleToggleAll(e)

    this.appendToForms()
  }

  appendToForms(){
    if(this.hasRemoveFormTarget)
      this.appendToForm($(this.removeFormTarget))
    if(this.hasAllowFormTarget)
      this.appendToForm($(this.allowFormTarget))
  }

  appendToForm(form){
    const antivirusExclusionAggregateIds = this.getSelected()
    form.find(".exclusion-input").remove()
    for(let exclusion of antivirusExclusionAggregateIds){
      ["file", "path", "type"].forEach(type => {
        const field = `antivirus_exclusion_${type}_id`
        const input = $('<input type="hidden">').attr({
          name: `antivirus_exclusions_form[antivirus_exclusion_aggregate_ids][][${field}]`,
          value: exclusion[field]
        }).addClass("exclusion-input")
        
        form.append(input)
      })
    }
  }

  getSelected() {
    const ids = []
    const selected = $(this.element).find(".bulk-select:checked")
    selected.each((i, e) => {
      const element = $(e)
      const antivirus_exclusion_file_id = element.data("antivirus-exclusion-file-id")
      const antivirus_exclusion_path_id = element.data("antivirus-exclusion-path-id")
      const antivirus_exclusion_type_id = element.data("antivirus-exclusion-type-id")
      ids.push({
        antivirus_exclusion_file_id: antivirus_exclusion_file_id,
        antivirus_exclusion_path_id: antivirus_exclusion_path_id,
        antivirus_exclusion_type_id: antivirus_exclusion_type_id,
      })
    })

    return ids
  }

  coerceAllSelections(checked) {
    $(this.element).find(".bulk-select:not(:disabled)").prop("checked", checked)
  }

  maybeEnableBulkActions() {
    if ($(this.element).find(".bulk-select:not(:disabled):checked").length > 0) {
      this.enableBulkActions()
    }
  }

  maybeDisableBulkActions() {
    if ($(this.element).find(".bulk-select:not(:disabled):checked").length === 0) {
      this.disableBulkActions()
    }
  }

  enableBulkActions() {
    $(this.element).find(".bulk-button").each((i, e) => {
      $(e).removeClass("disabled").attr("disabled", false)
    })
  }

  disableBulkActions() {
    $(this.element).find(".bulk-button").each((i, e) => {
      $(e).addClass("disabled").attr("disabled", true)
    })
  }
}
