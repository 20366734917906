import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import { flashMessage } from "huntressHelpers"

export default class extends Controller {
  static targets = [
    "contactName",
    "phoneNumber",
    "nameErrorText",
    "enterPhoneNumberText",
    "submitButton",
    "verifyButton",
    "modalTitle",
    "modalText",
    "modalBody",
    "notificationContactsForm",
    "contactMethod",
    "notificationContactId",
    "verificationCodeRow",
    "formInputs",
    "contactMethod",
    "verificationCodeText",
    "verificationCode",
    "country"
  ]

  connect() {
    $("#ajax-modal").on("hidden.bs.modal", function () {
      location.reload()
    })
  }

  emulateFlash(message, status) {
    const innerElement = `<div class='alert alert-${status} alert-dismissible animated fadeIn' style='margin:10px'><button class='close' data-dismiss='alert'>×</button>${message}</div>`

    $(".notification-contact-alert").empty().append(innerElement)
  }

  handleNameChange() {
    if (this.contactNameTarget.value !== "") {
      this.nameErrorTextTarget.textContent = ""
    }
  }
  handlePhoneNumberChange() {
    if (this.phoneNumberTarget.value !== "") {
      this.enterPhoneNumberTextTarget.style.color = "black"
    }
  }
  handleCountryChange() {
    if (this.countryTarget.value !== "") {
      this.enterPhoneNumberTextTarget.style.color = "black"
    }
  }

  //   Simple phone number formatting check
  //   Removes special characters an international phone number may contain
  //   i.e spaces, parens, +, -, . and counts to check for at least 7 digits
  //   (minimum length for a valid phone number)

  numberCheck() {
    return this.phoneNumberTarget.value.replace(/\D/g,"").length >= 7
  }

  countryCheck(){
    return this.countryTarget.value != ""
  }

  submitVerify(e) {
    if (this.verificationCodeTarget.value === "") {
      e.preventDefault()
    } else {
      $.ajax({
        url: Routes.verify_account_notification_contact_path(this.notificationContactId),
        method: "PATCH",
        contentType: "application/json",
        dataType: "json",
        async: false,
        data: JSON.stringify({
          notification_contact: {
            phone_number: null,
            name: null,
            contact_methods: null,
          },
          code: this.verificationCodeTarget.value
        }),
        success: (response)=> {
          e.preventDefault()
          if (response.verified) {
            $("#ajax-modal").modal("hide")
          } else {
            this.emulateFlash("Unable to verify Notification Contact", "danger")
          }
        }
      })
    }
  }

  submitForm(e) {
    if (this.contactNameTarget.value === "") {
      this.nameErrorTextTarget.textContent = "Contact Name cannot be blank"
      this.nameErrorTextTarget.style.color = "#e74c3c"
      e.preventDefault()
    } else if (!this.numberCheck() || !this.countryCheck()) {
      this.enterPhoneNumberTextTarget.style.color = "#e74c3c"
      e.preventDefault()
    } else {
      $.ajax({
        url: Routes.account_notification_contacts_path(),
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        async: false,
        data: JSON.stringify({
          notification_contact: {
            name: this.contactNameTarget.value,
            phone_number: this.phoneNumberTarget.value,
            contact_methods: this.contactMethodTargets.find((e) => { return e.checked }).value,
            country: this.countryTarget.value
          }
        }),
        success: (response) => {
          e.preventDefault()

          if (response.notification_contact_created && response.code_sent) {
            this.modalTitleTarget.textContent = "Verify Phone Number"
            this.modalTextTarget.textContent = "Enter the verification code sent to your phone number. This code is valid for 10 minutes."
            this.submitButtonTarget.remove()
            this.verificationCodeTextTarget.remove()
            this.formInputsTarget.remove()
            this.verifyButtonTarget.classList.remove("hidden")
            this.verificationCodeRowTarget.classList.remove("hidden")
            this.notificationContactId = response.notification_contact_id
          } else if (!response.notification_contact_created) {
            flashMessage(`Failed to add ${response.phone_number} to incident notifications.`, "danger", this.element)
          } else {
            flashMessage("Failed to send Verification Code.", "danger", this.element)
          }
        }
      })
    }
  }
}
