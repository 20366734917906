import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["page_select", "search"]

  connect() {
    $(this.page_selectTarget).on("change", function(){
      let params = new URLSearchParams(document.location.search)
      params.delete("page")
      params.set("per_page", $(this).val())
      document.location.search = params.toString()
    })

    $(this.searchTarget).on("keypress", function(e){
      if(e.key === "Enter"){
        let params = new URLSearchParams(document.location.search)
        params.set("search", $(this).val())
        document.location.search = params.toString()
      }
    })
  }
}
