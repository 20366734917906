import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clickable"]

  connect() {
    $(this.clickableTargets).click(function(e) {
      const targetFilters = $(this).data("value").split(",").map(filter => filter.trim())
      const qsObj = new URLSearchParams(window.location.search)
      const currentFilters = (qsObj.get("filters") || "").split(",")

      if(currentFilters.includes("")) {
        currentFilters.splice(currentFilters.indexOf(""), 1)
      }

      targetFilters.forEach(filter => {
        if(!currentFilters.includes(filter)) {
          currentFilters.push(filter)
        }
        else {
          currentFilters.splice(currentFilters.indexOf(filter), 1)
        }
      })

      if(currentFilters.length) {
        qsObj.set("filters", currentFilters)
      }
      else {
        qsObj.delete("filters")
      }

      window.location.search = qsObj.toString()
    })
  }
}
