import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterable"]

  update(ev) {
    const filterValue = ev.currentTarget.value.toLowerCase()

    this.filterableTargets.forEach((el) => {
      if (el.innerText.toLowerCase().match(filterValue)) {
        el.removeAttribute("hidden")

      } else {
        el.setAttribute("hidden", true)
      }
    })
  }
}
