import {Controller} from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["configEdit"]

  connect() {
    const textInput = $(this.configEditTarget)
    textInput.on("keydown", (e) => {
      if (e.key.toLowerCase() === "tab") {
        e.preventDefault()
        var startPos = e.target.selectionStart
        var endPos = e.target.selectionEnd
        var text = e.target.value
        if (e.shiftKey) {

          // reverse indent the current line - multiline indenting is not supported
          const changes = this.reverseIndent(text, startPos)
          e.target.value = changes[0]
          e.selectionStart = startPos - changes[1]
          e.selectionEnd = endPos - changes[1]
        } else {
          if (startPos != endPos) {
            // add spaces to start of line
            var prevStart = this.findLineStart(e.target.value, startPos)
            e.target.value = text.substring(0, prevStart) + "  " + text.substring(prevStart, text.length)
          } else {
            // add spaces at cursor
            e.target.value = text.substring(0, startPos) + "  " + text.substring(endPos, text.length)
          }
          e.target.selectionStart = startPos + 2
          e.target.selectionEnd = startPos + 2
        }
      }
    })
  }

  findLineStart(text, position) {
    // walk back to previous newline
    for (let i = position - 1; i >= 0; i--) {
      var c = text[i]
      if (c === "\n") {
        return i
      }
    }
    return 0
  }

  reverseIndent(text, position) {
    var prevStart = this.findLineStart(text, position)
    var foundSpaces = 0
    var start = prevStart
    if (text[prevStart] === "\n") {
      start = prevStart + 1
    }
    for (let i = start; i < position; i++) {
      // Remove up to 2 consecutive spaces
      if (text[i] == " ") {
        text = text.substring(0, i) + text.substring(i + 1, text.length)
        foundSpaces++
        i--
        if (foundSpaces == 2) {
          break
        }
      } else {
        break
      }
    }
    // [modified text, number of positions to move the cursor]
    return [text, foundSpaces]
  }
}
