import DetectorsController from "./show_controller.js"

export default class extends DetectorsController {
  buildDataTables() {
    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: 0, orderable: true, searchable: false },
        { targets: 1, orderable: true, searchable: false },
        { targets: 2, orderable: true, searchable: false },
        { targets: 3, orderable: true, searchable: false },
        { targets: 4, orderable: true, searchable: false },
        { targets: "_all", orderable: false, searchable: false }
      ],
      order: [[4, "desc"]],
      searching: false,
      deferRender: true,
      responsive: true,
      ajax: huntressData.admin_threatops_detector_detections_path,
    })
  }
}
