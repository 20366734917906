import { Controller } from "@hotwired/stimulus"
import { initTable } from "huntressHelpers"

export default class extends Controller {
  static targets = ["datatable", "loading", "table"]

  connect() {
    this.tableOptions = { columnDefs: [{ targets: 3, orderable: false }]}
    this.showLastTab()
    $(this.tableTarget).on("init.dt", () => this.tableInitialized())
    initTable(this.currentDataURL, this.tableOptions)
  }

  get currentDataURL() {
    return this.currentTab.dataset.classification
  }

  get currentTab() {
    return this.datatableTarget.querySelector("ul li.active a")
  }

  showLastTab() {
    const lastBinariesTab = localStorage.getItem("lastBinariesTab")
    if (lastBinariesTab) {
      $(`a[data-classification="${lastBinariesTab}"]`).tab("show")
    }
  }

  tabChanged(ev) {
    const el = $(ev.target)
    const table = $(this.tableTarget).DataTable()
    table.state.clear()
    table.destroy()
    initTable(el.data("classification"), this.tableOptions)
    localStorage.setItem("lastBinariesTab", el.data("classification"))
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }
}
