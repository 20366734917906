import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categories", "autoruns"]

  connect() {
    $(this.categoriesTarget).DataTable()
    $(this.autorunsTarget).DataTable()
  }
}
