class RuleBlock {
  index = 0
  rule_segment_counter = 0
  rule_segments = {}
  filter_condition = {}
  readonly = false

  ui = {
    rule_block: null,
    rules_grid: null
  }
  stimulus_controller

  constructor(index, stimulus_controller, readonly = false, prefill = null){
    this.index = index
    this.readonly = readonly
    this.stimulus_controller = stimulus_controller
    this.ui.rule_block = $(`<div class="col-md-12 rule_block">
        <div class="rules_grid"> </div>
        <a class="close corner-top-right removeRuleBlock" data-index="${this.index}">&times </a>
        <div class="col-md-4" style="margin: 10px 0;">
          <a class="btn btn-sm btn-success ruleAndBtn">+ And</a>
        </div>
      </div>`)
    this.ui.rules_grid = this.ui.rule_block.find(".rules_grid")
    if(!readonly)
      this.attachEventListeners()
    else
      this.setReadOnly()
    
    if(prefill)
      this.prefill(prefill)
    
  }

  setReadOnly(){
    this.ui.rule_block.find(".ruleAndBtn").parents(".col-md-4").remove()
  }

  prefill(prefilled_block){
    if(prefilled_block.expressions){
      for(let i = 0; i < prefilled_block.expressions.length; i++){
        let expression = prefilled_block.expressions[i]
        let segment = this.addRuleSegment(expression)
        if(prefilled_block.expressions[i+1] && this.readonly){
          let operator = prefilled_block.operator || ""
          $("<div class='text-bold'></div>").text(operator).appendTo(segment.ui.rule_wrapper)
        }
      }
    }
    else{
      this.addRuleSegment(prefilled_block)
    }
  }

  attachEventListeners(){
    this.ui.rule_block.on("click", ".ruleAndBtn", (e)=> {
      this.addRuleSegment()
    })

    this.ui.rule_block.on("click", ".removeRuleSegment", (e)=> {
      this.removeRuleSegment(e)
    })
  }

  attachRuleBlock(element){
    $(element).append(this.ui.rule_block)
  }

  addRuleSegment(prefill = null){
    let new_rule_segment = new RuleSegment(++this.rule_segment_counter, this.stimulus_controller, this.readonly, prefill)
    this.rule_segments[this.rule_segment_counter] = new_rule_segment
    new_rule_segment.attachRuleSegment(this.ui.rules_grid)
    return new_rule_segment
  }

  removeRuleSegment(e){
    let index = $(e.currentTarget).data("index")
    this.rule_segments[index].destroy()
    delete this.rule_segments[index]
  }

  destroy(){
    this.ui.rule_block.fadeOut(500, ()=>{
      this.ui.rule_block.remove()
    })
  }

  get value() {
    if(Object.keys(this.rule_segments).length > 1){
      let val = { 
        operator: "AND",
        expressions: [] 
      }
      for(let index in this.rule_segments){
        if(this.rule_segments[index].value)
          val.expressions.push(this.rule_segments[index].value)
      }
      return val
    }
    else{
      for(let index in this.rule_segments){
        return this.rule_segments[index].value
      }
    }
    return null
  }

}

class RuleSegment {
  rule_expression_counter = 0
  rule_expressions = {}
  ui = {
    rule_segment: null,
    field_select: null,
    rules_expressions_container: null,
    or_value_btn: null
  }
  readonly = false
  stimulus_controller

  constructor(index, stimulus_controller, readonly = false, prefill = null){
    this.index = index
    this.stimulus_controller = stimulus_controller
    this.readonly = readonly
    this.fields = JSON.parse(this.stimulus_controller.data.get("fields"))
      
    this.generateRuleSegmentUI()
    
    if(!readonly){
      if(!prefill)
        this.addRuleExpression()
      
      this.attachEventListeners()
    }
    else{
      this.setReadOnly()
    }

    if(prefill){
      this.prefill(prefill)
    }
  }

  prefill(prefilled_block){
    if(prefilled_block.expressions){
      for(let expression of prefilled_block.expressions){
        this.ui.field_select.val(expression.field)
        this.addRuleExpression(expression)
      }
    }
    else{
      this.ui.field_select.val(prefilled_block.field)
      this.addRuleExpression(prefilled_block)
    }
  }

  setReadOnly(){
    this.ui.or_value_btn.remove()
    $(".removeRuleSegment").remove()
    this.ui.field_select.attr("disabled", true)
  }

  generateRuleSegmentUI(){
    this.ui.rule_wrapper= $("<div class='col-md-4 rule_wrapper'>")
    this.ui.rule_segment = $("<div class='col-md-12 rule_segment'>").appendTo(this.ui.rule_wrapper)
    
    this.generateRuleFields().appendTo(this.ui.rule_segment)
    this.ui.rules_expressions_container = $("<div class='rules_expressions_container'> </div>").appendTo(this.ui.rule_segment)
    $(`<a class="close corner-top-right removeRuleSegment" data-index="${this.index}">&times </a>`).appendTo(this.ui.rule_segment)
    this.ui.or_value_btn = $("<a class='btn btn-sm btn-success or_value'>+ Or Value</a>").appendTo(this.ui.rule_segment)
  }

  generateRuleFields(){
    this.ui.field_select = $("<select class='form-control input-sm' id='field-select'>")
    for(let field of this.fields){
      $(`<option value="${field.value}">${field.display}</option>`).appendTo(this.ui.field_select)
    }
    return this.ui.field_select 
  }

  attachEventListeners(){
    this.ui.or_value_btn.on("click", (e) => {
      this.addRuleExpression()
    })

    this.ui.field_select.on("change", (e) => {
      this.resetRuleExpressions()
    })

    this.ui.rules_expressions_container.on("click", ".expression_delete", (e) =>{
      this.removeRuleExpression(e)
    })
  }

  attachRuleSegment(element){
    $(element).append(this.ui.rule_wrapper)
  }

  addRuleExpression(prefill = null){
    let new_rule_expression = new RuleExpression(++this.rule_expression_counter, this.stimulus_controller, this, prefill)
    this.rule_expressions[this.rule_expression_counter] = new_rule_expression
    new_rule_expression.attachRuleExpression(this.ui.rules_expressions_container)
  }

  removeRuleExpression(e){
    let index = $(e.currentTarget).data("index")
    this.rule_expressions[index].destroy()
    delete this.rule_expressions[index]
  }

  resetRuleExpressions(){
    this.rule_expression_counter = 0
    this.rule_expressions = {}
    this.ui.rules_expressions_container.empty()
    this.addRuleExpression()
  }

  destroy(){
    this.ui.rule_wrapper.fadeOut(500, ()=>{
      this.ui.rule_wrapper.remove()
    })
  }

  get field() {
    return this.ui.field_select.val()
  }

  get value() {
    if(Object.keys(this.rule_expressions).length > 1){
      let val = { 
        operator: "OR",
        expressions: [] 
      }
      for(let index in this.rule_expressions){
        if(this.rule_expressions[index].expression)
          val.expressions.push(this.rule_expressions[index].expression)
      }
      return val
    }
    else{
      for(let index in this.rule_expressions){
        return this.rule_expressions[index].expression
      }
    }
    return null
  }
}

class RuleExpression {
  index = 0
  ui = {
    expression: null,
    matcher_select: null,
    value_input: null,
    value_container: null,
    wildcard_info: null
  }
  field_data = {}
  stimulus_controller

  constructor(index, stimulus_controller, parent_rule_segment, prefill = null){
    this.index = index
    this.stimulus_controller = stimulus_controller
    this.parent_rule_segment = parent_rule_segment
    for(let prop of parent_rule_segment.fields){
      if(prop.value == this.parent_rule_segment.field){
        this.field_data = prop
        break
      }
    }
    this.ui.expression = $("<div class='expression'>")
    this.ui.matcher_select = $("<select class='form-control input-sm' style='width: 40%;'>").appendTo(this.ui.expression)
    for(let matcher of this.field_data.matchers){
      $(`<option value="${matcher.value}">${matcher.display}</option>`).appendTo(this.ui.matcher_select)
    }

    if(prefill){
      this.ui.matcher_select.val(prefill.matcher)
      this.val = prefill.value
    }
    else if($(stimulus_controller.modalBodyTarget).data("record") && $(stimulus_controller.modalBodyTarget).data("record")[this.parent_rule_segment.field] !== undefined){
      this.val = $(stimulus_controller.modalBodyTarget).data("record")[this.parent_rule_segment.field]
    }
    else{
      this.val = ""
    }

    this.ui.value_container = $("<div class=\"value_container\">").appendTo(this.ui.expression)
    if (this.isSelectValueInput) {
      this.ui.value_input = this.selectValueInput.appendTo(this.ui.value_container)
    } else {
      this.ui.value_input = this.textValueInput.appendTo(this.ui.value_container)
      this.ui.wildcard_info = $("<div class=\"wildcard_info\">Use '*' for wildcarding</div>").appendTo(this.ui.value_container)
    }
    this.ui.btn_container = $("<div class='btn_container'>").appendTo(this.ui.expression)
    this.ui.delete_btn = $(`<a class="fa fa-close close expression_delete" data-index="${this.index}">`).appendTo(this.ui.btn_container)

    if (parent_rule_segment.readonly) {
      this.setReadOnly()
    } else {
      this.attachInputListener()
    }
  }

  get isSelectValueInput() {
    return this.field_data.options !== undefined
  }
  
  get selectValueInput() {
    let select = $("<select class='form-control input-sm' id='value-select'>\"</select>")
    for (let [key, value] of Object.entries(this.field_data.options)) {
      let option = $("<option></option>").text(key).val(value)
      if (value === this.val) {
        option.prop("selected", true)
      }
      select.append(option)
    }
    return select
  }
  
  get textValueInput() {
    return $("<input type='text' class='form-control input-sm'>").val(this.val)
  }

  setReadOnly(){
    this.ui.delete_btn.remove()
    this.ui.matcher_select.attr("disabled", true)
    this.ui.value_input.attr("disabled", true)
  }

  attachInputListener(){
    if (this.isSelectValueInput) {
      return
    }
    
    this.ui.value_input.on("focus", (e) => {
      let txtarea = $("<textarea>")
      txtarea.attr("class", this.ui.value_input.attr("class"))
      txtarea.attr("rows", 4)
      txtarea.attr("cols", 30)        
      txtarea.val(this.ui.value_input.val())
      $(this.ui.value_input).replaceWith(txtarea)
      this.ui.value_input = txtarea
      this.ui.value_input.focus()
      if(this.ui.matcher_select.val() == "equals")
        this.ui.wildcard_info.show()
      this.attachTextAreaListener()
    })
  }

  attachTextAreaListener(){
    this.ui.value_input.on("blur", (e) => {
      let input = $("<input type='text' class='form-control input-sm'>")
      input.val(this.ui.value_input.val())
      $(this.ui.value_input).replaceWith(input)
      this.ui.value_input = input
      this.ui.wildcard_info.hide()
      this.attachInputListener()
    })
  }

  attachRuleExpression(element){
    $(element).append(this.ui.expression)
  }

  destroy(){
    this.ui.expression.fadeOut(500, ()=>{
      this.ui.expression.remove()
    })
  }

  get value(){
    return this.ui.value_input.val() || ""
  }

  get expression(){
    return  { 
      field: this.parent_rule_segment.field, 
      matcher: this.ui.matcher_select.val(), 
      value:  this.value
    }
  }
}

export {
  RuleBlock,
  RuleSegment,
  RuleExpression
}
