import { Controller } from "@hotwired/stimulus"
import { initTable } from "huntressHelpers"

export default class extends Controller {
  connect() {
    $("a[data-toggle=\"tab\"]").on("click",  (e) => {
      var table = $("#datatable table").DataTable()
      table.state.clear()
      table.destroy()
      initTable($(e.currentTarget).data("classification"), this.getDatatableParams($(e.currentTarget).data("showdetector")))
      localStorage.setItem("lastAutorunsTab", $(e.currentTarget).data("classification"))
    })

    var lastAutorunsTab = localStorage.getItem("lastAutorunsTab")
    if (lastAutorunsTab) {
      $("a[data-classification=\"" + lastAutorunsTab + "\"]").tab("show")
    }

    $("#datatable table").on("init.dt", function() {
      $("#loading").hide()
      $("#datatable").fadeIn()
    })

    initTable($("#datatable ul li.active a").data("classification"), this.getDatatableParams($("#datatable ul li.active a").data("showdetector")))
  }

  getDatatableParams(showDetector) {
    return {
      columnDefs: [
        {orderable: false, targets: [0,-1]},
        {visible: showDetector, targets: [0] }
      ],
      order: [[1, "asc"]],
      language: {
        emptyTable: "No autoruns found for this classification"
      }
    }
  }
}
