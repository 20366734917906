import { Controller } from "@hotwired/stimulus"
const VALIDATING_QUERY_INTERVAL = 5000

export default class extends Controller {
  connect() {
    if (this.isValidationInProgress()) { this.scheduleValidatingQuery() }
  }

  isValidationInProgress() {
    return (this.data.get("validationInProgress") == "true")
  }

  scheduleValidatingQuery() {
    setTimeout(this.validatingQuery.bind(this), VALIDATING_QUERY_INTERVAL)
  }

  validatingQuery() {
    $.ajax({
      type: "GET",
      url: window.location["href"],
      dataType: "json",
      success: this.handleValidatingQueryResponse.bind(this),
    })
  }

  handleValidatingQueryResponse(response) {
    response.validation_in_progress == true ? this.scheduleValidatingQuery() : this.refreshPage()
  }

  refreshPage() {
    window.location = location.toString()
  }
}
