import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const options = this.element.dataset.options ? JSON.parse(this.element.dataset.options) : {}
    const select2_options = {
      placeholder: "Select an option...",
      allowClear: true,
      theme: "bootstrap",
      width: "100%",
      ajax: {
        url: options.url,
        dataType: "json",
        delay: 150,
        data: function (params) {
          return {
            search: params.term,
            autotask_company_id: options.autotask_company_id,
          }
        }
      }
    }
    $(this.element).select2(select2_options)
  }
}
