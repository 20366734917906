import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from "../../../helpers/moneyHelpers"

export default class extends Controller {
  static targets = [
    "expandButton",
    "saveButton",
    "minimum",
    "basePrice",
    "productSubtotal",
    "minimumWarning",
    "onRenewal"
  ]

  static values = {
    product: String,
    prices: Array,
    subscription: { type: Object, default: {} },
    isUpgrade: Boolean,
    prevMinimum: Number
  }

  connect() {
    if (this.isUpgradeValue) {
      this.prevMinimum = this.subscriptionValue.minimum
      this.updateProduct(this.prevMinimum)
    }
  }

  onProductToggled(e) {
    const { product } = e.detail

    this.element.hidden = product !== this.productValue
  }

  onProductRemoved(e) {
    const { product } = e.detail
    if (this.productValue !== product) return

    this.minimumTarget.value = ""
    this.basePriceTarget.value = ""
    this.productSubtotalTarget.value = ""
  }

  onProductChanged(e) {
    const minimum = parseInt(this.minimumTarget.value || 0)

    this.updateProduct(minimum)
  }

  updateProduct(minimum) {
    let basePrice = ""
    let productSubtotal = ""
    this.selectedPlan = null

    if (this.isUpgradeValue && this.isUnderPreviousMinimum(minimum) && !this.onRenewalTarget.checked) {
      this.showMinimumWarning(`Minimum must be higher than ${this.prevMinimum}`)
    } else if (this.isUnderLowestTier(minimum)) {
      this.showMinimumWarning(`Must have a minimum of at least ${this.pricesValue[0].tier}`)
    } else {
      this.hideMinimumWarning()

      this.selectedPlan = this.pricesValue.findLast(price => price.tier <= minimum)

      const pricePer = this.selectedPlan.amount
      basePrice = formatCurrency(this.selectedPlan.currency, pricePer)
      productSubtotal = formatCurrency(this.selectedPlan.currency, pricePer * minimum)
    }

    this.basePriceTarget.value = basePrice
    this.productSubtotalTarget.value = productSubtotal

    this.dispatch("setSelectedPlan", {
      detail: {
        product: this.productValue,
        price: this.selectedPlan,
        minimum: minimum
      }
    })
  }

  isUnderPreviousMinimum(minimum) {
    return this.prevMinimum && this.prevMinimum > minimum
  }

  isUnderLowestTier(minimum) {
    return minimum < this.pricesValue[0].tier
  }

  showMinimumWarning(text) {
    this.minimumWarningTarget.textContent = text
    this.minimumWarningTarget.classList.remove("hidden")
    this.minimumTarget.classList.add("invalid")
  }

  hideMinimumWarning() {
    this.minimumWarningTarget.classList.add("hidden")
    this.minimumTarget.classList.remove("invalid")
  }

  keydown(event) {
    // Force the user to click the button to prevent accidental submission by pressing the 'enter' key.
    if (event.keyCode == 13){
      event.preventDefault()
      return false
    }
  }
}
