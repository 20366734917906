import Controller from "../components/simple_select2_input_controller.js"

export default class extends Controller {
  static values = {
    organizationId: Number,
    options: { type: String, default: "{}" }
  }

  connect() {
    let options = JSON.parse(this.optionsValue)
    let ajax = options.ajax
    ajax.data = this.queryParams(this.organizationIdValue)
    options = {
      ...this.defaultOptions(),
      ...options,
      ajax: ajax
    }

    this.setupSelect2(this.element, options)
  }

  queryParams(organizationId) {
    return function (params) {
      let organizationParam = {}

      if (organizationId !== 0)
        organizationParam.organization_id = organizationId

      return {
        ...params,
        ...organizationParam
      }
    }
  }
}
