import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "asset",
    "assetFilenameContainer",
    "assetUrl",
    "assetUrlError",
    "customThumbnail",
    "customThumbnailError",
    "longDescription",
    "purgeCustomThumbnail",
    "saveButton",
    "shortDescription",
    "thumbnailContainer",
    "title",
    "fileModal",
    "subAssetFile",
    "subAssetTypeLabel",
    "subAssetPath",
    "subAssetUrlModal",
    "subAssetTitle",
    "subAssetThumbnail",
    "subAssetContainer",
    "hiddenSubAssetContainer"
  ]

  connect() {
    const providedAttachments = JSON.parse(this.data.get("attachments"))
    this.subAssetPreviews = providedAttachments || []

    this.setFormState()
  }

  assetChanged() {
    if (this.assetTarget.value !== "") {
      this.assetFilenameContainerTarget.style.display = "none"
    } else {
      this.assetFilenameContainerTarget.style.display = ""
    }
    this.fieldChanged()
  }

  assetUrlChanged() {
    if (this.assetUrl) {
      this.assetUrlTarget.value = this.assetUrl.href
    }
    this.fieldChanged()
  }

  customThumbnailChanged() {
    if (this.hasCustomThumbnail) {
      this.thumbnailContainerTarget.style.visibility = "hidden"
    } else {
      this.thumbnailContainerTarget.style.visibility = "visible"
    }
    this.fieldChanged()
  }

  removeAsset() {
    this.assetFilenameContainerTarget.innerHTML = ""
    this.setFormState()
  }

  fieldChanged() {
    this.setFormState()
  }

  removeThumbnail() {
    this.thumbnailContainerTarget.innerHTML = ""
    this.purgeCustomThumbnailTarget.value = true
    this.purgeCustomThumbnailTarget.disabled = false
    this.setFormState()
  }

  setFormState() {
    this.setAssetState()
    this.setSaveButtonState()
  }

  setAssetState() {
    if (this.hasAsset) {
      this.assetTarget.removeAttribute("disabled")
      this.assetUrlTarget.setAttribute("disabled", true)
    } else if (this.hasAssetUrl) {
      this.assetTarget.setAttribute("disabled", true)
      this.assetUrlTarget.removeAttribute("disabled")
    }
  }

  setSaveButtonState() {
    if (this.isFormValid) {
      this.saveButtonTarget.removeAttribute("disabled")
    } else {
      this.saveButtonTarget.setAttribute("disabled", true)
    }
  }

  setSubAssetFile(e) {
    e.preventDefault()
    $(this.subAssetFileTarget).trigger("click")
  }

  setFile(type, path) {
    this.subAssetTypeLabelTarget.innerText = type == "url" ? "URL: " : "Attached File: "
    this.subAssetPathTarget.innerText = path
    this.formMode = "new"
    this.currentSubAssetType = type
    $(this.fileModalTarget).modal("show")
  }

  openSubAssetUrlModal(e) {
    e.preventDefault()
    $(this.subAssetUrlModalTarget).modal("show")
  }

  setSubAssetUrl(e) {
    e.preventDefault()
    const urlValue = this.subAssetUrlModalTarget.querySelector("input").value
    if(urlValue == "" || this.subAssetUrlModalTarget.querySelector(".help-block")) {
      return
    }
    $(this.subAssetUrlModalTarget).modal("hide")
    this.currentSubAsset = urlValue
    this.setFile("url", urlValue)
  }

  subAssetSelected(e) {
    e.preventDefault()
    if(e.target.files[0]) {
      this.currentSubAsset = e.target.files[0]
      this.setFile("file", e.target.files[0].name)
    }
  }

  createSubAssetPreview(e) {
    e.preventDefault()

    const isCreating = this.formMode === "new"

    if(!this.subAssetTitleTarget.value) {
      return
    }

    let preview

    if(isCreating) {
      this.subAssetPreviews.push({
        title: this.subAssetTitleTarget.value,
        thumbnail: this.subAssetThumbnailTarget.files[0],
        file: this.currentSubAssetType === "file" ? this.currentSubAsset : null,
        url: this.currentSubAssetType === "url" ? this.currentSubAsset : null
      })
      preview = this.subAssetPreviews[this.subAssetPreviews.length - 1]
    }
    else {
      if(this.subAssetTitleTarget.value) {
        this.subAssetPreviews[this.formIndex].title = this.subAssetTitleTarget.value
      }
      if(this.subAssetThumbnailTarget.files[0]) {
        this.subAssetPreviews[this.formIndex].thumbnail = this.subAssetThumbnailTarget.files[0]
      }
      preview = this.subAssetPreviews[this.formIndex]

      if(preview.id) {
        const idInput = document.createElement("input")
        idInput.setAttribute("type", "hidden")
        idInput.setAttribute("name", `marketing_asset[attachments_attributes][${this.formIndex}][id]`)
        idInput.value = preview.id

        this.hiddenSubAssetContainerTarget.appendChild(idInput)
      }
    }

    $(this.fileModalTarget).modal("hide")

    const operatingIndex = isCreating ? this.subAssetPreviews.length - 1 : this.formIndex

    let textInput = this.subAssetTitleTarget.cloneNode(true)
    let fileInput = preview.file ? this.subAssetFileTarget.cloneNode(true) : null
    let thumbnailInput = preview.thumbnail ? this.subAssetThumbnailTarget.cloneNode(true) : null
    let urlInput = null
    if(preview.url && isCreating) {
      urlInput = document.createElement("input")
      urlInput.value = this.currentSubAsset
    }

    const names = ["title", "custom_thumbnail", "attachment", "url"]
    const inputs = [textInput, thumbnailInput, fileInput, urlInput]

    inputs.forEach((input, idx) => {
      if(!input || !input.value) { return }

      const inputName = `marketing_asset[attachments_attributes][${operatingIndex}][${names[idx]}]`
      const existingInput = document.querySelector(`[name="${inputName}"]`)

      if(existingInput) {
        existingInput.remove()
      }

      input.removeAttribute("data-components--image-preview-target")
      input.removeAttribute("data-action")
      input.removeAttribute("class")
      input.removeAttribute("id")

      input.setAttribute("name", inputName)

      this.hiddenSubAssetContainerTarget.appendChild(input)
    })

    this.renderSubAssets()
    this.clearSubAssetValues()
    this.setFormState()
  }

  renderSubAssets() {
    this.subAssetContainerTarget.innerHTML = ""

    // Needed to maintain order
    Promise.all(
      this.subAssetPreviews.map((preview) => preview ? this.getFilePreview(preview) : null)
    ).then(sources => {
      this.subAssetPreviews.forEach((preview, idx) => {
        if(!preview) {
          return
        }

        const dataIdAttribute = preview.id ? `data-id="${preview.id}"` : ""

        const template = `
          <div class="sub-asset">
            <div class="sub-asset-image-container" data-controller="components--image-preview">
              <img src="${sources[idx]}" data-components--image-preview-target="image" />
            </div>
            <span>${preview.title}</span>
            <div class="overlay-controls">
              <button class="btn btn-xs btn-info" data-index="${idx}" ${dataIdAttribute} data-action="admin--marketing-assets--form#onSubAssetEdit">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn btn-xs btn-danger" data-index="${idx}" ${dataIdAttribute} data-action="admin--marketing-assets--form#onSubAssetDelete">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        `

        this.subAssetContainerTarget.innerHTML += template
      })
    })
  }

  clearSubAssetValues() {
    this.subAssetFileTarget.value = ""
    this.subAssetUrlModalTarget.querySelector("input").value = ""
    this.subAssetTitleTarget.value = ""
    this.subAssetThumbnailTarget.value = ""
  }

  onSubAssetEdit(e) {
    e.preventDefault()

    const target = e.target.nodeName === "I" ? e.target.parentElement : e.target
    const { index } = target.dataset

    const attachmentTarget = this.subAssetPreviews[index]

    if(attachmentTarget) {
      this.subAssetTitleTarget.value = attachmentTarget.title

      this.subAssetTypeLabelTarget.innerText = attachmentTarget.type == "url" ? "URL: " : "Attached File: "

      const localFileName = attachmentTarget.file ? attachmentTarget.file.name : "Not Found"
      this.subAssetPathTarget.innerText = attachmentTarget.type == "url" ? attachmentTarget.url : (attachmentTarget.filename || localFileName)

      this.formMode = "edit"
      this.formIndex = index
      $(this.fileModalTarget).modal("show")
    }
  }

  onSubAssetDelete(e) {
    e.preventDefault()

    const target = e.target.nodeName === "I" ? e.target.parentElement : e.target
    const { index, id } = target.dataset

    this.subAssetPreviews[index] = null

    if(id !== undefined) {
      const idInput = document.createElement("input")
      idInput.setAttribute("name", `marketing_asset[attachments_attributes][${index}][id]`)
      idInput.value = id

      const destroyInput = document.createElement("input")
      destroyInput.setAttribute("name", `marketing_asset[attachments_attributes][${index}][_destroy]`)
      destroyInput.value = true

      this.hiddenSubAssetContainerTarget.appendChild(idInput)
      this.hiddenSubAssetContainerTarget.appendChild(destroyInput)
    }
    else {
      this.hiddenSubAssetContainerTarget.querySelectorAll(`[name*="marketing_asset[attachments_attributes][${index}]"]`).forEach(el => el.remove())
    }

    this.renderSubAssets()
  }

  getFilePreview(attachment) {
    return new Promise((resolve, reject) => {
      if(!attachment) {
        return resolve(null)
      }
      else if(attachment.thumbnail || attachment.file) {
        try {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(attachment.thumbnail || attachment.file)
          fileReader.addEventListener("load", function () {
            resolve(this.result)
          })
        }
        catch(e) {
          resolve(null)
        }
      }
      else if(attachment.preview) {
        return resolve(attachment.preview)
      }
      else {
        return resolve(null)
      }
    })
  }

  get assetFilename() {
    return this.assetFilenameContainerTarget.querySelector("label")
  }

  get assetUrl() {
    let url
    try {
      url = new URL(this.assetUrlTarget.value)
    } catch {
      url = null
    }
    return url
  }

  get hasAsset() {
    return this.assetTarget.value !== "" || this.assetFilename !== null
  }

  get hasAssetUrl() {
    return this.assetUrlTarget.value !== ""
  }

  get hasCustomThumbnail() {
    return this.customThumbnailTarget.value !== ""
  }

  get hasValidCustomThumbnail() {
    return this.hasCustomThumbnail && this.isCustomThumbnailValid
  }

  get hasRequiredFields() {
    return this.longDescriptionTarget.value !== "" &&
      this.shortDescriptionTarget.value !== "" &&
      this.titleTarget.value !== ""
  }

  get isFormValid() {
    return this.hasRequiredFields && (
      (this.hasAssetUrl && this.hasValidCustomThumbnail) ||
      (this.hasAsset && (this.isAssetPreviewable || this.hasValidCustomThumbnail))
    )
  }

  get isAssetPreviewable() {
    return this.data.get("previewable") === "true" || this.isAssetExtensionPreviewable
  }

  get isAssetUrlValid() {
    return this.assetUrl && this.assetUrl.origin !== "null"
  }

  get isAssetExtensionPreviewable() {
    if (this.assetTarget.files.length > 0) {
      let assetType = this.assetTarget.files[0].type
      return assetType.includes("video/") || assetType.includes("/pdf") || assetType.includes("image/")
    } else {
      return false
    }
  }

  get isCustomThumbnailValid() {
    let valid = false
    if (this.customThumbnailTarget.files.length > 0) {
      let thumbnailType = this.customThumbnailTarget.files[0].type
      valid = thumbnailType.includes("image/")
    } else if (this.thumbnail) {
      valid = true
    }
    return valid
  }

  get thumbnail() {
    return this.thumbnailContainerTarget.querySelector("img")
  }
}
