import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["agentVersions"]

  get versionsByPlatform() {
    return huntressData.versions_by_platform
  }

  setAgentVersions(e) {
    const previouslySelectedVersion = this.agentVersionsTarget.value
    const newPlatform = e.target.value

    const newVersions = this.versionsByPlatform[newPlatform]

    // Remove all options except the initial "Select an agent version" option
    while (this.agentVersionsTarget.length > 1) {
      this.agentVersionsTarget.remove(1)
    }

    // Add the new versions for the new platform, attempting to use
    // our previous selection if we can
    newVersions?.forEach((version, index) => {
      const option = new Option(version)
      if (version === previouslySelectedVersion) {
        option.selected = true
      }

      this.agentVersionsTarget.add(option)
    })
  }
}
