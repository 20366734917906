import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "form",
    "accountName",
    "accountSubdomain",
    "companyClassification",
    "productSelection",
    "email",
    "password",
    "submitButton",
    "edrCheckbox",
    "m365Checkbox",
    "satCheckbox"
  ]

  connect() {
    this.setupFormValidation()

    this.emailTarget.addEventListener("input", this.validateField.bind(this, "email"))
    this.passwordTarget.addEventListener("input", this.validateField.bind(this, "password"))
    if (this.hasAccountNameTarget) {
      this.accountNameTarget.addEventListener("input", this.validateField.bind(this, "accountName"))
    }
    if (this.hasAccountSubdomainTarget) {
      this.accountSubdomainTarget.addEventListener("input", this.validateField.bind(this, "accountSubdomain"))
    }
    if (this.hasCompanyClassificationTarget) {
      this.companyClassificationTarget.addEventListener("change", this.validateCompanyClassification.bind(this))
      this.productSelectionTarget.addEventListener("change", this.validateProductSelection.bind(this))
      this.fv.validate()
      this.toggleButtonDisable()
      this.validateCompanyClassification()
      this.validateProductSelection()
    }
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3({
            defaultMessageContainer: false
          }),
          trigger: new plugins.Trigger({
            event: "blur change"
          }),
          message: new plugins.Message({
            clazz: "help-block text-danger",
            container: "#error-container",
          }),
        },
        fields: {
          accountName: {
            selector: "#accountNameInput",
            validators: {
              notEmpty: { message: "Account name is required." },
            }
          },
          accountSubdomain: {
            selector: "#accountSubdomainInput",
            validators: {
              notEmpty: {
                message: "Subdomain is required."
              },
            }
          },
          email: {
            selector: "#emailInput",
            validators: {
              notEmpty: { message: "Email is required." },
            }
          },
          password: {
            selector: "#passwordInput",
            validators: {
              notEmpty: { message: "Password is required." },
              stringLength: { min: 10, message: "Password minimum is 10 characters" }
            }
          }
        }
      }
    )
    this.fv.on("core.field.invalid", this.toggleButtonDisable.bind(this))
    this.fv.on("core.field.valid", this.toggleButtonDisable.bind(this))
  }

  validateField(field) {
    this.fv.validateField(field)
    this.toggleButtonDisable()
  }

  validateCompanyClassification() {
    const errorContainer = document.getElementById("company-classification-error")
    const companyClassificationContainer = document.getElementById("company-classification-container")

    if (this.companyClassificationTarget.value.length > 0) {
      errorContainer.classList.add("hidden")
      if(companyClassificationContainer.classList.contains("has-error")) {
        companyClassificationContainer.classList.remove("has-error")
        companyClassificationContainer.classList.add("has-success")
      }
    } else {
      errorContainer.classList.remove("hidden")
      if (companyClassificationContainer.classList.contains("has-success")) {
        companyClassificationContainer.classList.remove("has-success")
      }
      companyClassificationContainer.classList.add("has-error")
    }

    this.toggleButtonDisable()
  }

  validateProductSelection() {
    const errorContainer = document.getElementById("product-selection-error")
    const productSelectionContainer = document.getElementById("product-container")

    if (this.productSelectionTarget.value.length > 0) {
      errorContainer.classList.add("hidden")
      if(productSelectionContainer.classList.contains("has-error")) {
        productSelectionContainer.classList.remove("has-error")
        productSelectionContainer.classList.add("has-success")
      }
    } else {
      errorContainer.classList.remove("hidden")
      if (productSelectionContainer.classList.contains("has-success")) {
        productSelectionContainer.classList.remove("has-success")
      }
      productSelectionContainer.classList.add("has-error")
    }

    this.toggleButtonDisable()
  }

  toggleButtonDisable() {
    let allFilledOut
    if (!this.hasAccountNameTarget) {
      allFilledOut = this.emailTarget.value.length > 0 &&
                     this.passwordTarget.value.length >= 10
    } else {
      allFilledOut = this.accountNameTarget.value.length > 0 &&
                     this.accountSubdomainTarget.value.length > 0 &&
                     this.emailTarget.value.length > 0 &&
                     this.passwordTarget.value.length >= 10 &&
                     this.companyClassificationTarget.value.length > 0 &&
                     this.productSelectionTarget.value.length > 0
    }
    this.submitButtonTarget.disabled = !allFilledOut
  }
}
