import ExtendedFilterMenuController from "controllers/components/expanded_filter_menu_controller"

export default class extends ExtendedFilterMenuController {
  get search() {
    let search = this.searchFromFilters

    for (let select of $(this.filtersTarget).find("select")) {
      if (select.multiple) {
        let filterName = `country_code_filters[${select.name.replace("[]", "")}][]`
        $(select).val().forEach((val) => {
          search.append(filterName, val)
        })
      } else {
        let filterName = `country_code_filters[${select.name}]`
        if($(select).val().length > 0)
          search.append(filterName, $(select).val())
      }
    }

    return search
  }

  get searchFromFilters() {
    let search = new URLSearchParams()
    for (let filter of this.selectedFilters) {
      if (typeof(filter) === "object")
        for (let group in filter) {
          const value = filter[group]
          if (Array.isArray(value))
            search.append(`country_code_filters[${group}][]`, value[0])
          else
            search.set(`country_code_filters[${group}]`, value)
        }
      else
        search.set(`country_code_filters[${filter}]`, "1")
    }

    return search
  }
}
