import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

export default class extends Controller {
  static values = {
    dataPoints: Array,
    labels: Array,
    colors: Array,
  }

  connect() {
    this.loadChart()
  }

  loadChart() {
    const config = {
      type: "doughnut",
      data: this.chartData,
      options: {
        legend: { display: false },
        maintainAspectRatio: false
      }
    }

    if (!this.hasData) {
      this.addEmptyChartPlugins(config)
    }

    new Chart(this.element, config)
  }

  addEmptyChartPlugins(config) {
    config.options.plugins = {
      emptyDoughnut: {
        color: "#ECF0F1",
        width: 38,
        radiusDecrease: 20
      }
    }

    config.plugins = [this.emptyChartPlugin]
  }

  get chartData() {
    return {
      datasets: [{
        data: this.dataPointsValue,
        backgroundColor: this.colorsValue
      }],
      labels: this.labelsValue
    }
  }

  get hasData() {
    return this.dataPointsValue.some(val => val > 0)
  }

  get emptyChartPlugin() {
    return {
      id: "emptyDoughnut",
      afterDraw(chart, args, options) {
        const { color, width, radiusDecrease } = options

        const { chartArea: { left, top, right, bottom }, ctx } = chart
        const centerX = (left + right) / 2
        const centerY = (top + bottom) / 2
        const r = Math.min(right - left, bottom - top) / 2

        ctx.beginPath()
        ctx.lineWidth = width
        ctx.strokeStyle = color
        ctx.arc(centerX, centerY, (r - radiusDecrease), 0, 2 * Math.PI)
        ctx.stroke()
      }
    }
  }
}
