import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
import FieldStatus from "formvalidation/plugins/FieldStatus"
import { updateStrengthBar, setButtonAvailability } from "../../../src/devise/helper"

export default class extends Controller {
  static targets = ["form", "submitButton", "password", "passwordConfirmation"]

  connect() {
    this.setupFormValidation()
  }

  setupFormValidation() {
    const fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "input"
        }),
        fieldStatus: new FieldStatus({
          onStatusChanged: (areFieldsValid) => {
            setButtonAvailability(this.submitButtonTarget, areFieldsValid)
          }
        })
      },
      fields: {
        "user[email]": {
          validators: {
            notEmpty: {
              message: "E-mail address is required."
            },
            emailAddress: {
              message: "Please enter a valid email address."
            }
          }
        },
      }
    })

    this.addPasswordFields(fv)
  }

  addPasswordFields(validator) {
    if (this.ssoEnforced) {
      return
    }

    validator.addField("user[current_password]", {
      validators: {
        notEmpty: {
          message: "Your current password is required."
        }
      }
    })

    validator.addField("user[password_confirmation]", {
      validators: {
        identical: {
          compare: () => {
            return this.passwordTarget.value
          },
          message: "The password and its confirm are not the same."
        }
      }
    })

    validator.addField("user[password]", {
      validators: {
        callback: {
          callback: (input) => {
            // Returns the appropriate state and message
            return updateStrengthBar(input, "#strengthBar")
          }
        }
      }
    })

    // Revalidate the password confirmation when changing the password input field
    this.passwordTarget.addEventListener("input", function() {
      validator.revalidateField("user[password_confirmation]")
    })
  }

  get ssoEnforced() {
    return this.element.dataset["ssoEnforced"] != null
  }
}
