import {formValidation, plugins} from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
import PaymentDetailsController from "controllers/layouts/payment_details_controller"

export default class extends PaymentDetailsController {
  static targets = [
    "shippingAddressFields",
    "billingAddresses",
    "billingAddressesFormPanel",
    "billingAddress",
    "cloneBillingAddress",
    "paymentFields",
    "paymentMethod",
    "form",
    "updateCreditCard",
    "stripeToken",
    "cardholderName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipCode",
    "country",
    "submit",
    "stripeToken",
    "updatePayment",
    "cardError",
  ]

  updatePayment = false

  connect() {
    if(this.hasPaymentFieldsTarget) {
      this.setupFormValidation()
      this.setupStripe()
      this.setupBillingFormDisplay()
    }
  }

  setupStripe() {
    this.stripe = Stripe(huntressData.stripe_data.key)
    this.card = this.stripe.elements().create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#3498db",
          color: "#32315E",
          lineHeight: "45px",
          fontWeight: 400,
          fontSize: "15px",
          marginTop: "15px",

          "::placeholder": {
            color: "#CFD7DF",
          }
        },
      }
    })
    this.card.mount("#card-element")

    this.card.on("change", (event) => {
      this.updatePayment = true

      if (event.error) {
        this.cardErrorTarget.hidden = false
        this.cardErrorTarget.innerHTML = event.error.message
      } else {
        this.cardErrorTarget.hidden = true
        this.cardErrorTarget.text = ""

        this.validateCardholderName()
      }
    })
  }

  setupBillingFormDisplay() {
    // Most accounts should have a payment source set up, but an account that
    // just switched currencies will not, in which case we want to keep the
    // default value of "credit_card."
    if (this.element.dataset.currentPaymentMethod != null) {
      this.selectedPaymentMethod = this.element.dataset.currentPaymentMethod
    }
    this.configurePaymentColumns()
  }

  changeCloneBilling(event) {
    this.configurePaymentColumns()
  }

  changePaymentMethod(event) {
    this.configurePaymentColumns()
    // If we switched to ACH, we don't need a value for the cardholder name
    this.validateCardholderName()
  }

  validateCardholderName() {
    this.fv.validate("cardholder-name").then((result) => {
      this.submitTarget.disabled = (result !== "Valid")
    })
  }

  configurePaymentColumns() {
    if (this.selectedPaymentMethod === "ach_credit_transfer") {
      // For ACH payments, do not show credit card fields.
      this.paymentFieldsTarget.hidden = true
      this.shippingAddressFieldsTarget.hidden = this.cloneBillingAddress
    } else {
      // For credit card payments, show the payment fields and billing address,
      // and show the shipping address if it's not set to be the same as the
      // billing address.
      this.billingAddressTarget.hidden = false
      this.paymentFieldsTarget.hidden = false
      this.shippingAddressFieldsTarget.hidden = this.cloneBillingAddress
    }
  }

  displayBillingAddressesForm() {
    this.billingAddressesTarget.hidden = true
    this.billingAddressesFormPanelTarget.hidden = false
  }

  hideBillingAddressesForm() {
    this.billingAddressesTarget.hidden = false
    this.billingAddressesFormPanelTarget.hidden = true
  }

  modifySubmitButton(event) {
    this.fv.validate(event.target.field).then((result) => {
      this.submitTarget.disabled = (result !== "Valid")
    })
  }

  async subscribe(event) {
    let paymentDetailsValid = true
    await this.validatePaymentDetails((status) => {
      if (status === "Invalid") {
        paymentDetailsValid = false
      }
    })

    if (paymentDetailsValid === false) {
      event.preventDefault()
      return true
    }

    if (!this.updatePayment) {
      return true
    }

    // When the form is submitted and passes all validations, this callback gets
    // called. Submission is blocked and the credit card details are submitted
    // to Stripe. If that succeeds, the returned token from Stripe gets added to
    // the form and it is submitted again. This time after passing validations,
    // the form contains the Stripe token so we're done here.
    if (this.stripeTokenTarget.value.length > 0) {
      return true
    }

    event.preventDefault()

    // Collect the name and address details for the card holder
    var extraDetails = {
      name: this.cardholderNameTarget.value,
      address_line1: this.addressLine1Target.value,
      address_line2: this.addressLine2Target.value,
      address_city: this.cityTarget.value,
      address_state: this.stateTarget.value,
      address_zip: this.zipCodeTarget.value,
      address_country: this.countryTarget.value
    }

    // Pass the details to Stripe
    this.stripe.createToken(this.card, extraDetails).then((result) => {
      if (result.error) {
        return false
      } else {
        this.updatePaymentTarget.value = true
        this.stripeTokenTarget.value = result.token.id

        this.formTarget.submit()
      }
    })

    return false
  }

  setupFormValidation() {
    this.fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        icon: new plugins.Icon({
          valid: "glyphicon glyphicon-ok",
          invalid: "glyphicon glyphicon-remove",
          validating: "glyphicon glyphicon-refresh"
        }),
        trigger: new plugins.Trigger({
          event: "blur"
        })
      },
      fields: {
        "cardholder-name": {
          validators: {
            callback: {
              callback: () => ({
                message: "Cardholder name is required.",
                valid: this.cardholderNameTarget.value.length > 0 || this.selectedPaymentMethod === "ach_credit_transfer",
              })
            }
          }
        }
      }
    })
  }

  get selectedPaymentMethod() {
    return this.paymentMethodTarget.value
  }

  set selectedPaymentMethod(newValue) {
    this.paymentMethodTarget.value = newValue
  }

  get cloneBillingAddress() {
    return this.cloneBillingAddressTarget.checked
  }

  get currentPaymentMethod() {
    return this.element.dataset.currentPaymentMethod
  }
}
