import { Controller } from "@hotwired/stimulus"

// Use this with the `datatable_record_count` helper method to show the
// number of records in a datatable. The number of records will update
// as the filters are changed on the datatable.
// This by design works on pages with ONE datatable.

export default class extends Controller {
  static targets = ["recordCount"]

  updateCount(event) {
    $(this.recordCountTarget).fadeOut(() => {
      this.recordCountTarget.innerHTML = event.detail.dataTable.api().page.info().recordsTotal
      $(this.recordCountTarget).fadeIn()
    })
  }
}
