import PaymentDetailsController from "controllers/layouts/payment_details_controller"
import * as Routes from "routes"

export default class extends PaymentDetailsController {
  static targets = [
    "confirmModal",
    "currentMonthlyAgentCount",
    "currentMonthlyDescription",
    "currentMonthlyMultiplier",
    "currentMonthlySum",
    "fewerDeployedSum",
    "fewerDeployedTextWarning",
    "minimumAgentCount",
    "minimumMonthlySum",
    "moreDeployedSum",
    "overDeployed",
    "perAgentPrice",
    "selectedPaymentMethod",
    "starterButton",
    "starterPlanConfirmation",
    "subscribeButton",
    "subscribeConfirmButton",
    "subscriptionMinimumButton",
    "underDeployed",
    "underDeployedCorrect"
  ]

  connect() {
    super.connect()

    const currentMinimumBtn = this.subscriptionMinimumButtonTargets.find(el => {
      const min = Number(el.dataset.agentMinimum)
      return min === huntressData.current_unit_count
    })

    if (currentMinimumBtn)
      currentMinimumBtn.click()

    this.setNewMinimum(huntressData.current_unit_count)

    this.attachStarterClickEvent()
    this.attachStarterPlanConfirmationEvent()

    const minimumAgentsSelected = Boolean(this.formTarget.querySelector("#step2 .option-group label.active"))
    this.setSubscribeButtonDisabled(!minimumAgentsSelected)
  }

  setSubscribeButtonDisabled(disabled) {
    this.subscribeButtonTarget.disabled = disabled
    if (disabled) {
      this.subscribeButtonTarget.setAttribute("title", "Must select minimum agent count")
    } else {
      this.subscribeButtonTarget.removeAttribute("title")
    }
  }

  selectNewMinimum(event) {
    const btn = event.currentTarget
    const minimum = Number(btn.dataset.agentMinimum)

    this.setNewMinimum(minimum)
    this.setSubscribeButtonDisabled(false)
  }

  setNewMinimum(minimum) {
    huntressData.current_unit_count = minimum
    this.updateEquations()
  }

  formatPrice(price) {
    return `${this.currencySymbol}${price.toFixed(2)} ${this.currencyCode}`
  }

  formatSum(price) {
    return `${this.formatPrice(price)}/Month`
  }

  updateCurrent(base) {
    if (huntressData.current_deployed_count < huntressData.current_unit_count) {
      this.currentMonthlyAgentCountTarget.innerHTML = huntressData.current_unit_count
      this.currentMonthlyDescriptionTarget.innerHTML = "Minimum Agents"
      this.currentMonthlyMultiplierTarget.classList.add("text-warning")
      this.currentMonthlyMultiplierTarget.setAttribute("title", "Your account currently has fewer than the minimum number of agents deployed, so the cost is based on the minimum number of agents selected.")

      this.currentMonthlySumTarget.innerHTML = this.formatSum(base * huntressData.current_unit_count)
    } else {
      this.currentMonthlyAgentCountTarget.innerHTML = huntressData.current_deployed_count
      this.currentMonthlyDescriptionTarget.innerHTML = "Deployed Agents"
      this.currentMonthlyMultiplierTarget.classList.remove("text-warning")
      this.currentMonthlyMultiplierTarget.setAttribute("title", "")

      this.currentMonthlySumTarget.innerHTML = this.formatSum(base * huntressData.current_deployed_count)
    }
  }

  updateWithFewer(base) {
    this.fewerDeployedSumTarget.innerHTML = this.formatSum(base * huntressData.current_unit_count)
    if (huntressData.current_unit_count === 0) {
      this.fewerDeployedTextWarningTarget.classList.remove("text-warning")
    } else {
      this.fewerDeployedTextWarningTarget.classList.add("text-warning")
    }
  }

  updateEquations() {
    let selectedPlan = huntressData.prices.find(plan => plan.minimum === huntressData.current_unit_count)
    if (!selectedPlan)
      return

    let base = selectedPlan.cost_per_agent

    this.perAgentPriceTargets.forEach(el => {
      el.innerHTML = this.formatPrice(base)
    })
    this.minimumAgentCountTarget.innerHTML = huntressData.current_unit_count
    this.overDeployedTargets.forEach(el => {
      el.innerHTML = huntressData.current_unit_count + 50
    })
    this.underDeployedTarget.innerHTML = huntressData.current_unit_count < 50 ? 0 : huntressData.current_unit_count - 50
    this.underDeployedCorrectTarget.innerHTML = huntressData.current_unit_count

    this.minimumMonthlySumTarget.innerHTML = this.formatSum(base * huntressData.current_unit_count)
    this.updateCurrent(base)
    this.updateWithFewer(base)
    this.moreDeployedSumTarget.innerHTML = this.formatSum(base * (huntressData.current_unit_count + 50))
  }

  get isDirect() {
    return this.data.get("isDirect") == "true"
  }

  get canSelectACHPayment() {
    return huntressData.allow_ach_payments && huntressData.current_unit_count >= huntressData.minimum_unit_count_for_ach
  }

  get selectedPaymentMethod() {
    if (!this.hasSelectedPaymentMethodTarget)
      return null

    return this.selectedPaymentMethodTarget.value
  }

  set selectedPaymentMethod(newValue) {
    if (!this.hasSelectedPaymentMethodTarget)
      return

    this.selectedPaymentMethodTarget.value = newValue
  }

  get currencySymbol() {
    return this.data.get("currencySymbol")
  }

  get currencyCode() {
    return this.data.get("currencyCode")
  }

  revalidateField(event) {
    this.fv.revalidateField(event.target.getAttribute("data-field"))
  }

  validateAndSubscribe() {
    $("#subscribe-confirm-submit").removeClass("disabled").attr("disabled", false)

    this.validatePaymentDetails((status) => {
      if (status === "Valid") $("#subscribe-confirm").modal("show")
    })
  }

  subscribe(event) {
    // Disable the submit button so the form isn't accidentally submitted twice
    this.subscribeConfirmButtonTarget.disabled = true
    this.subscribeConfirmButtonTarget.setAttribute("disabled", true)

    // This form should only be submittable if the subscription-confirm modal is
    // visible. If the user presses 'enter' in the Stripe form, it will be
    // submitted and we'll catch it here that the modal isn't open
    if (!$("#subscribe-confirm").hasClass("in")) {
      return false
    }

    // No need to create a Stripe token if a Stripe subscription already exists
    // If paying using ACH, we handle the source creation on the server
    if (this.selectedPaymentMethod === "ach_credit_transfer") {
      return true
    }

    // When the form is submitted and passes all validations, this callback gets
    // called. Submission is blocked and the credit card details are submitted
    // to Stripe. If that succeeds, the returned token from Stripe gets added to
    // the form and it is submitted again. This time after passing validations,
    // the form contains the Stripe token so we're done here.
    if (this.stripeTokenTarget.value.length > 0) {
      return true
    }

    if (this.selectedPaymentMethod == "ach_credit_transfer")
      return true

    this.createStripePaymentMethodToken()

    event.preventDefault()

    return false
  }

  keydown(event) {
    // Since we want to force the user to view the modal dialog to ensure they
    // understand what they are agreeing to, we're blocking form submission by the
    // 'enter' key.
    if (event.keyCode == 13) {
      event.preventDefault()
      return false
    }
  }

  attachStarterClickEvent() {
    if (!this.hasStarterButtonTarget) {
      return
    }

    $(this.starterButtonTarget).click(function(e) {
      e.preventDefault()
      $("#starter-modal").modal("show")
    })
  }

  attachStarterPlanConfirmationEvent() {
    if (!this.hasStarterPlanConfirmationTarget) {
      return
    }

    $(this.starterPlanConfirmationTarget).click(e => {
      e.preventDefault()
      $(this.starterPlanConfirmationTarget).prop("disabled", true)
      $.ajax(Routes.startup_msp_request_account_subscription_path({format: "json"}), {
        method: "POST",
        success: function() {
          $(".confirmation-step").toggleClass("hidden")
          $(".completion-step").toggleClass("hidden")
        },
        error: function() {
          alert("We could not process your request at this time. Please email sales@huntresslabs.com for assistance.")
          $(this.starterPlanConfirmationTarget).prop("disabled", false)
        }
      })
    })
  }
}
