import BulkActionsController from "../../components/bulk_actions_controller"
import { joinWithOxford } from "huntressHelpers"

export default class extends BulkActionsController {
  static targets = ["container", "autorunIds", "autorunBulkActionDescription", "autorunListField"]
  bulkInvestigate() {
    this.autorunIdsTarget.value = this.getSelectedIds()
    this.autorunBulkActionDescriptionTarget.innerHTML = `
        <span>Would you like to designate the following Autoruns as Investigation worthy?</span>
        <br />
        <span style='font-weight: bold'>This will mark these Autoruns as suspicious so they go into the "Needs Investigation" queue.</span>`
    const nameList = this.getSelectedRows().map(row => row.querySelector("a.autorun-name").text)
    this.autorunListFieldTarget.textContent = joinWithOxford(nameList)

    $("#bulk-investigate-modal").modal()
  }
}
