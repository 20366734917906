import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.showSelectedTab()
    $(this.tabLinks).on("shown.bs.tab", this.preserveTabState.bind(this))
  }

  showSelectedTab() {
    const tabStateHash = window.location.hash.replace("-tab", "")
    $(this.element.querySelector(`a[href='${tabStateHash}']`)).tab("show")
  }

  get tabLinks() {
    return this.element.querySelectorAll("a")
  }

  preserveTabState(ev) {
    if (history.pushState) {
      history.pushState(null, null, ev.target.hash + "-tab")
    } else {
      window.location.hash = ev.target.hash + "-tab"
    }
  }
}
