import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]
  static values = {
    sanitize: { type: Boolean, default: true }
  }

  allowHtml = false

  connect() {
    let template = this.templateTarget.innerHTML

    $(this.triggerEl).popover({
      template: template,
      html: this.allowHtml,
      sanitize: this.sanitizeValue
    })

    $(this.dismissEl).popover({
      trigger: "focus"
    })
  }

  get triggerEl() {
    let selector = "[data-toggle='popover']"
    if (this.element.dataset.triggerId) {
      selector += `[data-trigger-id='${this.element.dataset.triggerId}']`
    }

    return this.element.querySelector(selector)
  }

  get dismissEl() {
    return this.element.querySelector(".popover-dismiss")
  }
}
