import BulkActionsController from "../../components/bulk_actions_controller"
import * as Routes from "routes"
import { flashMessage } from "huntressHelpers"

export default class extends BulkActionsController {
  static targets = ["loading", "datatable", "table", "container", "killswitchToggle", "killswitchRioToggle"]

  connect(){
    const killswitchToggle = $(this.killswitchToggleTarget)
    killswitchToggle.on("change", (e) => {
      $.ajax({
        url: killswitchToggle.data("url"),
        method: "POST",
        complete: (response, xhr, settings) => {
          if(response.status == 200){
            flashMessage(`Agent rollout killswitch ${response.responseJSON.status ? "enabled" : "disabled"}`, "success", this.element)
          }
          else{
            flashMessage("Unable to toggle killswitch", "warning", this.element)
          }
        }
      })
    })

    $(this.killswitchRioToggleTarget).on("change", (e) => {
      $.ajax({
        url: $(this.killswitchRioToggleTarget).data("url"),
        method: "POST",
        complete: (response, xhr, settings) => {
          if(response.status == 200){
            flashMessage(`Agent rollout killswitch ${response.responseJSON.status ? "enabled" : "disabled"}`, "success", this.element)
          }
          else{
            flashMessage("Unable to toggle killswitch", "warning", this.element)
          }
        }
      })
    })
  }

  bulkStartRollouts() {
    this.bulkModifyRollouts(Routes.bulk_start_admin_agent_rollouts_path())
  }

  bulkPauseRollouts() {
    this.bulkModifyRollouts(Routes.bulk_pause_admin_agent_rollouts_path())
  }

  bulkDeleteRollouts() {
    this.bulkModifyRollouts(Routes.bulk_delete_admin_agent_rollouts_path())
  }

  bulkModifyRollouts(target) {
    $.ajax({
      url: target,
      method: "POST",
      data: {ids: this.getSelectedIds()},
      complete: (data) => {
        window.location.reload()
      }
    })
  }
}
