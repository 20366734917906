import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "hostField",
    "hostLabel",
    "companyIdField",
    "companyIdLabel",
    "publicKeyField",
    "publicKeyLabel",
    "privateKeyField",
    "privateKeyLabel"
  ]

  connect() {
    this.setupFormValidation()

    this.hostFieldTarget.addEventListener("input", this.validateField.bind(this, "host"))
    this.companyIdFieldTarget.addEventListener("input", this.validateField.bind(this, "companyId"))
    this.publicKeyFieldTarget.addEventListener("input", this.validateField.bind(this, "publicKey"))
    this.privateKeyFieldTarget.addEventListener("input", this.validateField.bind(this, "privateKey"))
  }

  selectHost(ev) {
    this.hostFieldTarget.value = ev.currentTarget.getAttribute("data-host")
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3(),
          trigger: new plugins.Trigger({
            event: "blur"
          })
        },
        fields: {
          host: {
            selector: '[name="integrations_connect_wise[cw_host]"]',
            validators: {
              notEmpty: { message: "ConnectWise host or domain name is required." },
            }
          },
          companyId: {
            selector: '[name="integrations_connect_wise[company_id]"]',
            validators: {
              notEmpty: { message: "Company ID is required." },
            }
          },
          publicKey: {
            selector: '[name="integrations_connect_wise[public_key]"]',
            validators: {
              notEmpty: { message: "Public Key is required." },
            }
          },
          privateKey: {
            selector: '[name="integrations_connect_wise[private_key]"]',
            validators: {
              notEmpty: { message: "Private Key is required." },
            }
          },
        }
      }
    )

    this.fv.on("core.field.invalid", (field) => this.toggleLabel(field, true))
    this.fv.on("core.field.valid", (field) => this.toggleLabel(field, false))
  }

  validateField(field) {
    // Trigger the validate so the `core.field.valid/invalid`
    // callback can run and update the label color
    this.fv.validateField(field)
    this.toggleButtonDisable()
  }

  toggleLabel(field, invalid) {
    // Same red used by `formValidation`
    this[`${field}LabelTarget`].style.color = invalid ? "#E74C3C" : null
  }

  toggleButtonDisable() {
    // Not running this.fv.validate here to prevent the
    // form fields from all being validated and turning red
    const allFilledOut = (
      this.publicKeyFieldTarget.value.length > 0 &&
      this.privateKeyFieldTarget.value.length > 0 &&
      this.hostFieldTarget.value.length > 0 &&
      this.companyIdFieldTarget.value.length > 0
    )

    this.submitButtonTarget.disabled = !allFilledOut
  }
}
