import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["eventLogSourcesToggle", "eventLogSourcesConfirmationModal"]

  eventLogUpdating = false

  connect() {
    if (this.hasEventLogSourcesToggleTarget) {
      $(this.eventLogSourcesToggleTarget).on("change", (e) => {
        if(!this.eventLogUpdating) {
          $(this.eventLogSourcesConfirmationModalTarget).modal("show")
          this.eventLogUpdating = true
        } else {
          this.eventLogUpdating = false
        }
      })
    }
  }

  cancelDisableEventLogSources(e){
    this.cancelFeatureToggle(this.eventLogSourcesConfirmationModalTarget, this.eventLogSourcesToggleTarget)
  }

  cancelFeatureToggle(modalTarget, toggleTarget) {
    $(modalTarget).modal("hide")
    $(toggleTarget).parent(".toggle").trigger("click")
  }
}
