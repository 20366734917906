import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loadingShow", "loadingHide"]

  showLoading() {
    this.toggleLoading(true)
  }

  hideLoading() {
    this.toggleLoading(false)
  }

  toggleLoading(show) {
    this.loadingShowTargets.forEach(target => {
      target.classList.toggle("hide", !show)
    })
    this.loadingHideTargets.forEach(target => {
      target.classList.toggle("hide", show)
    })
  }
}
