import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "frame",
    "filterForm"
  ]

  // By default, dropdown-menu will auto-close on any click, but
  // we only want it to close when we click apply. This ensures that
  // clicks to labels etc do not auto-close the menu.
  clickedFilterMenu(e) {
    if (!e.target.matches("input, button")) {
      e.stopPropagation()
    }
  }

  applyFilters(e) {
    e.preventDefault()
    const formData = new FormData(this.filterFormTarget)
    const queryString = new URLSearchParams(formData).toString()

    this.updateFrames(queryString)
  }

  updateFrames(queryString) {
    this.frameTargets.forEach((frame) => {
      this.updateFrame(frame, queryString)
    })
  }

  updateFrame(frame, queryString) {
    const baseUrl = frame.src.split("?")[0]
    frame.src = `${baseUrl}?${queryString}`
    frame.loading = "eager"
  }
}
