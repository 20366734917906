import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // The bootstrap-tokenfield triggers 2 connects because it modifies the element on
    // initialization. Only initialize the library once
    if (this.initialized) { return }

    let inputType = this.data.get("inputType")

    if(this.element.value && !this.element.value.includes(",")) {
      this.element.value = this.element.value.split(" ").join(", ")
    }

    $(this.element).tokenfield({
      delimiter: this.delimiters,
      inputType: inputType,
      createTokensOnBlur: true,
      limit: this.data.get("limit"),
    })

    const enforceLower = this.element.classList.contains("enforce-lower")
    if(enforceLower) {
      this.element.parentElement.querySelector(".token-input").classList.add("enforce-lower")
      $(this.element).on("tokenfield:createtoken", event => {
        event.attrs.value = event.attrs.value.toLowerCase()
        event.attrs.label = event.attrs.label.toLowerCase()
      })
      $(this.element).on("tokenfield:createdtoken", event => {
        const validRegex = /^(?:[a-z0-9]{1}|[a-z0-9][a-z0-9_]*[a-z0-9])$/
        if(!event.attrs.value.match(validRegex)) {
          $(event.relatedTarget).addClass("invalid")
        }
      })
    }

    // Required to apply styles to the input container
    this.element.parentElement.style.height = "auto"
    // Required to offset margins on the tokens and allow for variable height inputs
    this.element.parentElement.style.paddingBottom = "5px"

    if (inputType === "email") {
      // Only fires jQuery events
      $(this.element).on("tokenfield:createdtoken", (event) => {
        if (!this.validEmail(event.attrs.value)) {
          $(event.relatedTarget).addClass("invalid")
        }
      })
    }

    // Initialization completed
    this.initialized = true
  }

  validEmail(email) {
    // Validation taken from HTML Living Standard:
    // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(email)
  }

  get delimiters() {
    let value = this.data.get("delimiters")
    if (value === null) {
      value = [",", ";"]
    } else {
      value = JSON.parse(value)
    }

    return value
  }
}
