import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    $(this.element).on("click", "td", (e) => {
      let target = $(e.target)
      let tr = $(target.parents("tr")[0])
      let btn = $(tr).find(".row-expand-btn")

      if(!btn)
        return

      if(target.hasClass("row-expand-btn")){
        tr.trigger("click")
        this.rotateBtn(btn, e)
      }
      else if(target.is("tr,td")){
        this.rotateBtn(btn, e)
      }
    })
  }

  addRowAfter(event) {
    const rowId = event.target.dataset.id
    const childRowPath = event.target.dataset.childRowPath

    const newRow = document.createElement("tr")
    newRow.innerHTML = `
      <td colspan="100" class="expanded-row child-task-panel">
        <turbo-frame id="${rowId}-child-tasks" src="${childRowPath}">
            <div id="loading"></div>
        </turbo-frame>
      </td>
    `

    const currentRow = document.getElementById(rowId)
    currentRow.insertAdjacentElement("afterend", newRow)
  }

  deleteRowAfter(event) {
    event.preventDefault()

    const rowToDelete = event.target.closest("tr").nextElementSibling
    rowToDelete.remove()
  }

  rotateBtn(btn, e){
    // CSS animations
    if(btn.hasClass("rotate")){
      btn.removeClass("rotate")
      this.deleteRowAfter(e)
    }
    else
    {
      btn.addClass("rotate")
      this.addRowAfter(e)
    }
  }
}
