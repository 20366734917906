import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "verificationCode",
    "verifyButton",
    "verifyForm",
    "notificationContactId"
  ]

  connect() {
    $("#ajax-modal").on("hidden.bs.modal", function () {
      location.reload()
    })
  }

  emulateFlash(message, status) {
    const innerElement = `<div class='alert alert-${status} alert-dismissible animated fadeIn' style='margin:10px'><button class='close' data-dismiss='alert'>×</button>${message}</div>`

    $(".notification-contact-alert").empty().append(innerElement)
  }

  submitVerify(e) {
    if (this.verificationCodeTarget.value === "") {
      e.preventDefault()
    } else {
      $.ajax({
        url: Routes.verify_account_notification_contact_path(this.notificationContactIdTarget.textContent),
        method: "PATCH",
        contentType: "application/json",
        dataType: "json",
        async: false,
        data: JSON.stringify({
          notification_contact: {
            phone_number: null,
            name: null,
            contact_methods: null,
          },
          code: this.verificationCodeTarget.value
        }),
        success: (response)=> {
          if (response.verified) {
            $("#ajax-modal").modal("hide")
          } else {
            this.emulateFlash("Unable to verify Notification Contact", "danger")
          }
        }
      })
    }
  }
}
