import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "form",
    "criticalPriorityField",
    "criticalPriorityLabel",
    "highPriorityField",
    "highPriorityLabel",
    "lowPriorityField",
    "lowPriorityLabel",
    "defaultStatusField",
    "defaultStatusLabel",
    "defaultQueueField",
    "defaultQueueLabel",
    "defaultAccountField",
    "defaultAccountLabel",
    "submitButton"
  ]

  connect() {
    this.setupFormValidation()

    $(this.criticalPriorityFieldTarget).on("select2:select select2:unselect", this.validateField.bind(this, "criticalPriority"))
    $(this.highPriorityFieldTarget).on("select2:select select2:unselect", this.validateField.bind(this, "highPriority"))
    $(this.lowPriorityFieldTarget).on("select2:select select2:unselect", this.validateField.bind(this, "lowPriority"))
    $(this.defaultStatusFieldTarget).on("select2:select select2:unselecting", this.validateField.bind(this, "defaultStatus"))
    $(this.defaultQueueFieldTarget).on("select2:select select2:unselecting", this.validateField.bind(this, "defaultQueue"))
    $(this.defaultAccountFieldTarget).on("select2:select select2:unselecting", this.validateField.bind(this, "defaultAccount"))
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3(),
          trigger: new plugins.Trigger({
            event: "blur"
          })
        },
        fields: {
          criticalPriority: {
            selector: '[name="integrations_autotask[critical_priority_id]"',
            validators: {
              notEmpty: { message: "Selecting a critical priority is required." },
            }
          },
          highPriority: {
            selector: '[name="integrations_autotask[high_priority_id]"',
            validators: {
              notEmpty: { message: "Selecting a high priority is required." },
            }
          },
          lowPriority: {
            selector: '[name="integrations_autotask[low_priority_id]"',
            validators: {
              notEmpty: { message: "Selecting a low priority is required." },
            }
          },
          defaultStatus: {
            selector: '[name="integrations_autotask[status_id]"',
            validators: {
              notEmpty: { message: "Selecting a status is required." },
            }
          },
          defaultQueue: {
            selector: '[name="integrations_autotask[queue_id]"',
            validators: {
              notEmpty: { message: "Selecting a default queue is required." },
            }
          },
          defaultAccount: {
            selector: '[name="integrations_autotask[default_company_id]"',
            validators: {
              notEmpty: { message: "Selecting a default account is required." },
            }
          },
        }
      }
    )

    this.fv.on("core.field.invalid", (field) => this.toggleLabel(field, true))
    this.fv.on("core.field.valid", (field) => this.toggleLabel(field, false))
  }

  validateField(field) {
    // Trigger the validate so the `core.field.valid/invalid`
    // callback can run and update the label color
    this.fv.validateField(field)
    this.toggleButtonDisable()
  }

  toggleLabel(field, invalid) {
    // Same red used by `formValidation`
    this[`${field}LabelTarget`].style.color = invalid ? "#E74C3C" : null
  }

  toggleButtonDisable() {
    // Not running this.fv.validate here to prevent the
    // form fields from all being validated and turning red
    const allFilledOut = (
      this.criticalPriorityFieldTarget.value.length > 0 &&
      this.highPriorityFieldTarget.value.length > 0 &&
      this.lowPriorityFieldTarget.value.length > 0 &&
      this.defaultStatusFieldTarget.value.length > 0 &&
      this.defaultQueueFieldTarget.value.length > 0 &&
      this.defaultAccountFieldTarget.value.length > 0
    )

    this.submitButtonTarget.disabled = !allFilledOut
  }
}
