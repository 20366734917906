import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets= ["category"]

  connect() {
    $(this.categoryTarget)
      .select2({
        theme: "bootstrap",
        placeholder: "Choose A Category...",
        ajax: {
          url: Routes.admin_categories_path({format: "json"}),
          dataType: "json",
          delay: 250
        },
        width: "100%",
        allowClear: true
      })
  }
}
