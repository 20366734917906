import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    const table = document.querySelector(this.data.get("tableDivElementId"))
    this.observer = new MutationObserver((mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          this.tableController.reload()
        }
      })
    })
    this.observer.observe(table, { childList: true })
  }

  get tableController() {
    return document.querySelector(this.data.get("tableDivElementId"))[this.data.get("datatableController")]
  }

  refreshIdentities(_event) {
    const target = this.buttonTarget
    if (target.disabled == true) return false
    target.querySelector("#refresh-identities").innerText = " Refreshing..."
    target.classList.add("disabled")
    target.disabled = true
    this.userIdentityRefresh(target)
  }

  async userIdentityRefresh(target) {
    const options = {
      url: this.data.get("href"),
      method: "post",
      dataType: "json",
      error: (response) => {
        target.querySelector("#refresh-identities").innerText = " Refresh Identities"
        target.classList.remove("disabled")
        target.blur()
      },
    }
    $.ajax(options)
  }
}
