import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "organizationsDatatable",
    "changeCurrencyConfirmationModal",
    "accountCountry",
    "accountCurrency",
    "submitButton",
    "enterCurrencyText",
    "trialExtensionModal",
    "trialId",
    "trialExtensionDatePicker",
    "disableAccountModal",
    "disableAccountDatePicker",
    "enableAccountModal",
  ]

  confirmCurrencyChange = false

  connect() {
    $(this.organizationsDatatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.organizationsDatatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.admin_account_organizations_path(this.data.get("accountId")),
    })
  }

  showTrialExtensionModal(event) {
    this.trialIdTarget.value = event.currentTarget.dataset.id
    this.trialExtensionDatePickerTarget.value = event.currentTarget.dataset.endsAt
    $(this.trialExtensionModalTarget).modal("show")
  }

  showDisableAccountModal(event) {
    this.disableAccountDatePickerTarget.value = event.currentTarget.dataset.endsAt
    $(this.disableAccountModalTarget).modal("show")
  }

  showEnableAccountModal(event) {
    $(this.enableAccountModalTarget).modal("show")
  }

  handleSubmit(e) {
    const selectedCurrency = this.accountCurrencyTarget.value !== ""

    if (selectedCurrency && this.accountCurrencyTarget.value !== huntressData.current_currency && !this.confirmCurrencyChange) {
      e.preventDefault()

      $(this.changeCurrencyConfirmationModalTarget).modal("show")
    } else {
      return true
    }
  }

  handleCountryChange() {
    if (this.accountCountryTarget.value) {
      this.accountCurrencyTarget.disabled = false
    }

    if (document.getElementById("no-currency-selected") == null) {
      const option = new Option("Select a currency", "", true, true)
      option.id = "no-currency-selected"

      this.accountCurrencyTarget.add(option, 0)
    }
    this.enterCurrencyTextTarget.classList.remove("hidden")

    this.submitButtonTarget.classList.replace("btn-success", "btn-warning")
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.title = "Select a currency"
  }

  handleCurrencyChange() {
    if (this.accountCurrencyTarget.value !== "") {
      this.accountCurrencyTarget.namedItem("no-currency-selected")?.remove()

      this.submitButtonTarget.classList.replace("btn-warning", "btn-success")
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.title = "Submit"

      this.enterCurrencyTextTarget.classList.add("hidden")
    }
  }

  cancelChangeCurrency() {
    $(this.changeCurrencyConfirmationModalTarget).modal("hide")
    this.submitButtonTarget.disabled = false
    this.accountCurrencyTarget.value = huntressData.current_currency
  }

  confirmChangeCurrency() {
    this.confirmCurrencyChange = true
  }
}
