import {Controller} from "@hotwired/stimulus"

// According to Bootstrap documentation:
// Due to how HTML5 defines its semantics, the autofocus HTML attribute has no effect in Bootstrap modals.
// To achieve the same effect, use some custom JavaScript.

export default class extends Controller {
  static values = {
    showOnLoad: Boolean
  }

  // Bootstrap events (like show.bs.modal & hide.bs.modal) are triggered via the jQuery trigger() method,
  // which unfortunately doesn’t create an event that we can listen to via addEventListener.
  // This means we can’t bind the events to data-action like Stimulus encourages.
  // Instead, we must explicitly bind the events in the Stimulus controller using the jQuery on() method.

  connect() {
    $(this.element).on("shown.bs.modal", this.shown.bind(this))

    if (this.showOnLoadValue) {
      $(this.element).modal("show")
    }
  }

  shown() {
    let el = this.element.querySelector("[autofocus]")
    if (el !== null) {
      el.focus()
    }

    // This is a nasty hack to fix Bootstrap Toggles within modals. When the toggle is initialized
    // automatically by the library, the modal is hidden and so everything has a 0px width. When
    // the modal is shown, the toggle does not redraw itself and ends up drawn with incorrect sizes
    // and looks terrible. Bootstrap Toggle does not have any API that can be called to resize the
    // element once the modal is shown. Furthermore, re-initializing the toggle causes the existing
    // toggle wrapper element to be wrapped again and breaks everything.
    //
    // This hack destroys any existing toggles within the modal and re-creates them when the modal
    // is shown. There may be a slight flash as the old DOM elements are removed and the new ones
    // are added. Hopefully in the future bootstrap-toggle will be updated and we can remove this.
    $(this.element).find("[data-toggle^=toggle]").bootstrapToggle("destroy")
    $(this.element).find("[data-toggle^=toggle]").bootstrapToggle()
  }
}
