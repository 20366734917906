import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "bulkSelect",
    "endCount",
    "nextButton",
    "prevButton",
    "sessionsList",
    "startCount",
    "totalCount"
  ]

  connect() {
    this.currentPage = parseInt(this.element.dataset.startPage)
    this.sessionsPath = this.element.dataset.sessionsPath
    this.bulkReviewPath = this.element.dataset.bulkReviewPath
  }

  bulkReview() {
    var guids = []
    this.bulkSelectTargets.forEach(el => {
      if (el.checked)
        guids.push(el.dataset.guid)
    })

    if (guids.length == 0)
      return

    this.submitReviewGuids(guids)
  }

  bulkReviewAll() {
    var guids = []
    this.bulkSelectTargets.forEach(el => {
      guids.push(el.dataset.guid)
    })

    if (guids.length == 0)
      return

    this.submitReviewGuids(guids)
  }

  submitReviewGuids(guids) {
    Rails.ajax({
      url: this.bulkReviewPath,
      type: "POST",
      data: `guids[]=${guids}`,
      success: function(data) {
        this.load()
      }.bind(this),
      error: function(error) {
        alert("Bulk review failed")
      },
    })
  }

  load() {
    var url = new URL(this.sessionsPath, window.location.origin)
    url.searchParams.set("page", this.currentPage)

    fetch(url.href)
      .then(response => response.json())
      .then(json => {
        if (json.count > 0) {
          this.sessionsListTarget.innerHTML = json.body
        } else {
          this.sessionsListTarget.innerHTML = "<div><strong>No records found!</strong></div>"
        }

        this.updateCountDetails(json)
        this.updatePageButtons(json)
      })
      .catch(error => {
        alert("Unable to load records")
      })
  }

  updateCountDetails(json) {
    var startCount = (this.currentPage * json.page_size) + 1
    this.startCountTarget.innerText = startCount
    this.endCountTarget.innerText = startCount + json.count - 1
    this.totalCountTarget.innerHTML = json.total
  }


  updatePageButtons(json) {
    if (json.count < json.page_size) {
      this.nextButtonTarget.disabled = true
    } else {
      this.nextButtonTarget.disabled = false
    }

    if (this.currentPage <= 0) {
      this.prevButtonTarget.disabled = true
    } else {
      this.prevButtonTarget.disabled = false
    }
  }

  prevPage() {
    this.currentPage -= 1
    if (this.currentPage < 0)
      this.currentPage = 0

    this.load()
  }

  nextPage() {
    this.currentPage += 1
    this.load()
    this.sessionsListTarget.scrollIntoView()
  }
}
