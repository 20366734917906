import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  connect() {
    this.inputTarget.addEventListener("keyup", this.onChange)
    this.inputTarget.addEventListener("keydown", this.onKeyDown)
  }

  disconnect() {
    this.inputTarget.removeEventListener("keyup", this.onChange)
    this.inputTarget.removeEventListener("keydown", this.onKeyDown)
  }

  debounce(callback) {
    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
    this.debounceTimeout = setTimeout(callback, 300)
  }

  onChange(e) {
    switch(this.inputTarget.getAttribute("type")) {
    case "number":
      this.numberValidation()
      break
    case "url":
      this.debounce(this.urlValidation.bind(this))
      break
    case "text":
      this.debounce(this.contentValidation.bind(this))
      break
    default:
      break
    }
  }

  onKeyDown(e) {
    switch(this.inputTarget.getAttribute("type")) {
    case "number": {
      // Because HTML number inputs accept the letter e as a value to support exponential notation
      if(e.key.toLowerCase() === "e") {
        e.preventDefault()
      }
      break
    }
    default:
      break
    }
  }

  numberValidation() {
    const input = this.inputTarget
    const min = parseInt(input.getAttribute("min"))
    const max = parseInt(input.getAttribute("max"))
    const errorElement = this.errorElement

    if(!isNaN(min) && input.value < min) {
      this.appendErrorToInput(`Number provided must be greater or equal to ${min}.`)
    }
    else if(!isNaN(max) && input.value > max) {
      this.appendErrorToInput(`Number provided must be less than or equal to ${max}.`)
    }
    else if(isNaN(input.value)) {
      this.appendErrorToInput("Value provided must be a number.")
    }
    else if(input.classList.contains("required") && input.value === "") {
      this.appendErrorToInput("A value must be provided.")
    }
    else {
      if(errorElement) {
        this.clearErrorFromInput()
      }
    }
  }

  urlValidation() {
    const input = this.inputTarget
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi

    const maxlength = parseInt(input.getAttribute("maxlength"))

    if(input.value && !input.value.match(urlRegex)) {
      this.appendErrorToInput("Must be a valid URL (should follow the format of https://website.com).")
    }
    else if(maxlength && input.value.length > maxlength) {
      this.appendErrorToInput(`Input value cannot be greater than ${maxlength} characters.`)
    }
    else if(!input.value) {
      this.clearErrorFromInput()
    }
    else {
      this.clearErrorFromInput()
    }
  }

  // TODO: Maybe convert the URL validation to content validation and pass the regex in as an argument
  contentValidation() {
    const input = this.inputTarget
    const validationRule = new RegExp(input.dataset.validationRule || "")
    const maxlength = parseInt(input.getAttribute("maxlength"))

    if(input.value && !input.value.match(validationRule)) {
      let errorMsg = "Must match allowed characters."
      if(input.dataset.characterSetDescription) {
        errorMsg += ` Input allows ${input.dataset.characterSetDescription}`
      }
      this.appendErrorToInput(errorMsg)
    }
    else if(maxlength && input.value.length > maxlength) {
      this.appendErrorToInput(`Input value cannot be greater than ${maxlength} characters.`)
    }
    else if(!input.value) {
      this.clearErrorFromInput()
    }
    else {
      this.clearErrorFromInput()
    }
  }

  appendErrorToInput(text) {
    let errorElementToGenerate = this.errorElement

    if(!errorElementToGenerate) {
      errorElementToGenerate = document.createElement("span")
      errorElementToGenerate.classList.add("help-block", "validation-error")
    }
    errorElementToGenerate.innerText = text

    if(!this.errorElement) {
      this.inputTarget.closest(".form-group").classList.add("has-error")
      this.inputTarget.parentNode.insertBefore(errorElementToGenerate, this.inputTarget.nextSibling)
    }
  }

  clearErrorFromInput() {
    const nextSibling = this.errorElement

    if(nextSibling && nextSibling.classList.contains("help-block")) {
      nextSibling.remove()
      this.inputTarget.closest(".form-group").classList.remove("has-error")
    }
  }

  get errorElement() {
    return this.inputTarget.parentElement.querySelector(".help-block.validation-error")
  }
}
