import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "expiresAtDateUtc",
    "expiresAtMessage",
    "neverExpires",
  ]

  hasValidValue() {
    return !!(this.neverExpiresTarget.checked || this.expiresAtDateUtcTarget.value)
  }

  enableFields() {
    this.element.classList.remove("disabled")
    this.neverExpiresTarget.disabled = false
  }

  disableFields() {
    this.element.classList.add("disabled")
    this.expiresAtMessageTarget.classList.add("hidden")
    this.expiresAtDateUtcTarget.value = null
    // `readOnly`, not `disabled`, because we need to submit the form with the
    // `null` value. (`disabled` would result in the input value not being
    // submitted at all.)
    this.expiresAtDateUtcTarget.readOnly = true
    this.neverExpiresTarget.checked = true
    this.neverExpiresTarget.disabled = true
  }

  toggleNeverExpires(event){
    if (event.target.checked) {
      this.expiresAtDateUtcTarget.value = null
      this.expiresAtMessageTarget.classList.add("hidden")
      // `readOnly`, not `disabled`, because we need to submit the form with the
      // `null` value. (`disabled` would result in the input value not being
      // submitted at all.)
      this.expiresAtDateUtcTarget.readOnly = true
    } else {
      this.expiresAtMessageTarget.classList.remove("hidden")
      this.expiresAtDateUtcTarget.readOnly = false
    }
  }
}
