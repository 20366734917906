import { Controller } from "@hotwired/stimulus"
import { getSelectedIds } from "../../../helpers/bulkActionHelpers"
import * as Routes from "routes"
import { fadeInOut, flashMessage } from "../../../helpers/huntressHelpers"

export default class SignalsController extends Controller {
  static targets = [
    "signalsCount",
    "signalsSelectedCount",
    "signalsTotalCount",
    "reAssignSignalsTable",
    "followupSignalsTable",
    "bulkEdit",
    "bulkClose",
    "bulkReport",
    "bulkReportMultiEntity",
    "followupSignalsPanel",
    "bulkEditPanel",
    "bulkEditConfirmationNotification",
    "bulkEditConfirmationText",
    "bulkEditConfirmationIcon",
    "multiReportAcknowledgment"
  ]

  connect(){
    this.mode = this.data.get("mode") || "index"
    this.element[this.identifier] = this
  }

  get ajaxModalController() {
    return this.element["components--ajax-modal"]
  }

  async generateIndividual(event) {
    const target = event.currentTarget
    const resourceType = target.dataset.resourceType
    const resourceId = target.dataset.resourceId
    const reloadOnClose = target.dataset.reloadOnClose
    const resources = [ { type: resourceType, id: resourceId } ]

    await this.generate(resources, reloadOnClose)
  }

  async generateBulk(event) {
    const target = event.currentTarget
    const resourceType = target.dataset.resourceType
    const scope = target.dataset.scope
    const reloadOnClose = target.dataset.reloadOnClose
    const followupWithReport = "followupWithReport" in target.dataset
    const resourceIds = getSelectedIds(scope).split(", ")
    const resources = resourceIds.map(id => ({ type: resourceType, id: id }))

    await this.generate(resources, reloadOnClose, followupWithReport)
  }

  async generate(resources, reloadOnClose, followupWithReport = true) {
    const options = {
      url: Routes.generate_admin_signals_path({ format: "json" }),
      method: "patch",
      contentType: "application/json",
      data: JSON.stringify({
        resources: resources
      }),
      success: (response) => {
        if (followupWithReport) {
          this.assign(response, "report", reloadOnClose)
        } else {
          this.assign(response, null, true)
        }
      },
      error: (response) => {
        flashMessage("Failed to find and/or generate signals for the detection", "danger", this.element)
      }
    }

    $.ajax(options)
  }

  async assignIndividual(event) {
    const target = event.currentTarget
    const url = target.dataset.href
    const reloadOnClose = this.toBoolean(target.dataset.reload || "false")
    const searchParams = new URL(url).searchParams
    const signals_ids = [searchParams.get("signals[]")]
    const options = {
      url: url,
      method: "patch",
      data: {
        mode: this.mode
      }
    }

    this.assignAjax(options, signals_ids, target.dataset.followup, reloadOnClose)
  }

  bulkRelease(event) {
    const signal_ids = getSelectedIds("#admin-signals-table").split(", ")
    const options = {
      url: Routes.bulk_release_admin_signals_path(),
      method: "PATCH",
      contentType: "application/json",
      data: JSON.stringify({
        signals: signal_ids
      })
    }

    $.ajax(options)
  }

  async bulkAssign(event) {
    const signal_ids = typeof event.currentTarget.dataset.signalIds === "string" ?
      JSON.parse(event.currentTarget.dataset.signalIds) : getSelectedIds("#admin-signals-table").split(", ")

    await this.assign(signal_ids, event.currentTarget.dataset.followup, false)
  }

  async reassign(event) {
    if ($(event.currentTarget).attr("disabled")) {
      return
    }
    const signals_ids = []
    $(this.reAssignSignalsTableTarget).children("tr").each((index, element) => {
      signals_ids.push($(element).data("id"))
    })

    await this.assign(signals_ids, event.currentTarget.dataset.followup, false, true)
  }

  async assign(signal_ids, followup, reloadOnClose, force = false) {
    const options = {
      url: Routes.claim_admin_signals_path(),
      method: "PATCH",
      contentType: "application/json",
      data: JSON.stringify({
        signals: signal_ids,
        force: force,
        followup: followup,
        mode: this.mode
      })
    }

    await this.assignAjax(options, signal_ids, followup, reloadOnClose)
  }

  async close(event) {
    this.validateInputs(true)

    const options = {
      url: `${Routes.close_admin_signals_path()}`,
      method: "PATCH",
      processData: false,
      contentType: "application/json",
      data: JSON.stringify({
        "signals": this.followupSignalsData("close"),
        "mode": this.mode
      }),
      error: this.handleAjaxError.bind(this)
    }

    this.disableButton(event.currentTarget)
    this.ajaxModalController.showAjaxModalLoading()

    await $.ajax(options)
    await this.ajaxModalController.hideAjaxModal()
    location.reload()
  }

  async reportMultiEntity(event) {
    this.validateInputs()

    if (!this.multiReportAcknowledgmentTarget.checked) {
      return
    }

    const options = {
      url: `${Routes.report_multi_entity_admin_signals_path()}`,
      method: "PATCH",
      processData: false,
      contentType: "application/json",
      data: JSON.stringify({
        "signals": this.followupSignalsData("report_multi_entity")
      }),
      error: this.handleAjaxError.bind(this)
    }

    this.disableButton(event.currentTarget)
    this.ajaxModalController.showAjaxModalLoading()

    await $.ajax(options)
    await this.ajaxModalController.hideAjaxModal()
    location.reload()
  }

  async createReport(event) {
    this.validateInputs()

    const options = {
      url: `${Routes.report_admin_signals_path()}`,
      method: "PATCH",
      processData: false,
      contentType: "application/json",
      data: JSON.stringify({
        "signals": this.followupSignalsData("report"),
        "report_id": event.target.dataset.infectionReportId,
        "mode": this.mode
      }),
      error: this.handleAjaxError.bind(this)
    }

    this.disableButton(event.currentTarget)
    if (this.ajaxModalController) {
      this.ajaxModalController.showAjaxModalLoading()
    }

    await $.ajax(options)
  }

  async edit(event) {
    const signalsData = this.followupSignalsData("edit")[0]
    let signalEdit = {
      malice: signalsData.malice,
      category_id: signalsData.category_id,
      contexts: signalsData.contexts
    }

    const options = {
      url: `${Routes.admin_signal_path(signalsData.signal_id)}`,
      method: "PATCH",
      processData: false,
      contentType: "application/json",
      data: JSON.stringify({
        signal: signalEdit,
        comment: signalsData.comment,
        mode: this.mode
      }),
      error: this.handleAjaxError.bind(this)
    }

    this.disableButton(event.currentTarget)
    this.ajaxModalController.showAjaxModalLoading()

    await $.ajax(options)
    await this.ajaxModalController.hideAjaxModal()
    location.reload()
  }

  toggledIndexCheckbox() {
    let entities = []
    let reported_signal_selected = false
    let canReport = true

    $("#admin-signals-table").find(".bulk-select:checked").each((index, element) => {
      let entity = `${element.dataset.entityableType}-${element.dataset.entityableId}`
      if (entities.indexOf(entity) < 0 ) {
        entities.push(entity)
      }
      reported_signal_selected ||= element.dataset.status === "reported"
      canReport &&= element.dataset.canReport === "true"
    })

    if (entities.length === 0) {
      this.showBulkReport()
      this.bulkReportTarget.setAttribute("disabled", true)
      this.bulkCloseTarget.setAttribute("disabled", true)
      this.bulkCloseTarget.setAttribute("title", "")
    } else {
      if (reported_signal_selected) {
        this.bulkCloseTarget.setAttribute("disabled", true)
        this.bulkCloseTarget.setAttribute("title", "Cannot close reported signals.")
      } else {
        this.bulkCloseTarget.removeAttribute("disabled")
        this.bulkCloseTarget.setAttribute("title", "")
      }

      if (entities.length === 1) {
        this.showBulkReport()
      } else {
        this.showBulkReportMulti()
      }

      if (!canReport) {
        this.bulkReportTarget.setAttribute("disabled", true)
        this.bulkReportMultiEntityTarget.setAttribute("disabled", true)
      }
    }
  }

  showBulkReport() {
    this.bulkReportTarget.classList.remove("hide")
    this.bulkReportTarget.removeAttribute("disabled")

    this.bulkReportMultiEntityTarget.classList.add("hide")
    this.bulkReportMultiEntityTarget.setAttribute("disabled", true)
  }

  showBulkReportMulti() {
    this.bulkReportTarget.classList.add("hide")
    this.bulkReportTarget.setAttribute("disabled", true)

    this.bulkReportMultiEntityTarget.classList.remove("hide")
    this.bulkReportMultiEntityTarget.removeAttribute("disabled")
  }

  toggledFollowupCheckbox() {
    let num_checked = $(this.followupSignalsTableTarget).find(".bulk-select:checked").length
    $(this.signalsSelectedCountTarget).text(num_checked)
    this.toggleBulkEdit(num_checked > 0)
  }

  bulkEdit() {
    const selections = this.followupSignalsTableTarget.querySelectorAll(".bulk-select:checked")
    let rows = [...selections].map(selection => selection.closest("tr"))
    let malice = $(this.bulkEditTarget).find(".malice-select").val()
    let category_id = $(this.bulkEditTarget).find(".category-select").val()
    let context = $(this.bulkEditTarget).find(".contexts-select").val()
    let comment = $(this.bulkEditTarget).find(".comment-input").val()
    let edits = new Set()

    for(let row of rows) {
      let $row = $(row)

      if (malice) {
        $row.find(".malice-select").val(malice).trigger("change")
        edits.add("Malice")
      }

      if (category_id) {
        $row.find(".category-select").val(category_id).trigger("change")
        edits.add("Category")
      }

      if (context && context.length > 0) {
        $row.find(".contexts-select").val(context).trigger("change")
        edits.add("Context")
      }

      if (comment) {
        $row.find(".comment-input").val(comment)
        edits.add("Comment")
      }
    }
    this.validateInputs()
    this.clearBulkEdit()
    this.flashBulkEditSignalsEdited(edits, rows.length)
  }

  clearBulkEdit() {
    $(this.bulkEditTarget).find(".malice-select").val("").trigger("change")
    $(this.bulkEditTarget).find(".category-select").val("").trigger("change")
    $(this.bulkEditTarget).find(".contexts-select").val("").trigger("change")
    $(this.bulkEditTarget).find(".comment-input").val("").trigger("change")
  }

  flashBulkEditSignalsEdited(properties, signalsCount) {
    fadeInOut($(this.bulkEditConfirmationNotificationTarget))
    if (properties.size == 1) {
      $(this.bulkEditConfirmationTextTarget).text(`${properties.values().next().value} updated`)
    } else if (properties.size > 1) {
      $(this.bulkEditConfirmationTextTarget).text(`${signalsCount} signals updated`)
    } else {
      $(this.bulkEditConfirmationTextTarget).text("Nothing was edited")
    }
  }

  async assignAjax(options, signals_ids = [], followup, reloadOnClose) {
    options.success = (response) => {
      if (response.reassign) {
        this.ajaxModalController.showAjaxModalContent(response.modal)
      } else if (followup && signals_ids.length > 0) {
        this.openFollowupModal(signals_ids, followup, reloadOnClose)
      } else {
        this.ajaxModalController.hideAjaxModal()
        if (reloadOnClose) {
          $(window).scrollTop(0)
          location.reload()
        }
      }
    }
    options.success.bind(this)

    this.dispatch("openModal", {
      detail: {
        options: options,
        showLoading: false,
        reloadOnClose: reloadOnClose,
        modalClass: "xl",
      },
      prefix: false
    })
  }

  async openFollowupModal(signals_ids, followup, reloadOnClose) {
    const options = { 
      url: Routes.followup_modal_admin_signals_path({ signals: signals_ids, followup: followup, mode: this.mode }) 
    }

    this.dispatch("openModal", {
      detail: {
        options: options,
        showLoading: false,
        reloadOnClose: reloadOnClose,
        modalClass: "xl",
      },
      prefix: false
    })
  }

  followupSignalsData(followup) {
    let rows = []
    $(this.followupSignalsTableTarget).find("tr").each((index, row) => {
      let data = {
        signal_id: parseInt(row.dataset.id),
        malice: $(row).find(".malice-select").val(),
        category_id: parseInt($(row).find(".category-select").val()),
        contexts: $(row).find(".contexts-select").val(),
        comment: $(row).find(".comment-input").val()
      }
      if (followup === "close") {
        if (!data.malice || !data.contexts.length || !data.comment.match(/\w+\s+\w+/)) {
          throw new Error("Missing required field")
        }
      } else if (followup === "edit") {
        if (!data.malice) {
          throw new Error("Missing required field")
        }
      } else if (followup === "report") {
        if (!data.malice || !data.category_id) {
          throw new Error("Missing required field")
        }
        const comment_words = data.comment.trim().split(/\s+/).filter(i => i)
        if (comment_words.length != 0 && comment_words.length < 5) {
          throw new Error("Comment must be 5 or more words")
        }
      } else if (followup === "report_multi_entity") {
        if (!data.category_id) {
          throw new Error("Missing required field")
        }
      }
      rows.push(data)
    })
    return rows
  }

  validateInputs(comment_required = false) {
    $(this.followupSignalsTableTarget).find("tr").each((index, row) => {
      this.updateSelectClass($(row).find(".malice-select"))
      this.updateSelectClass($(row).find(".category-select"))
      this.updateSelectClass($(row).find(".contexts-select"))
      this.updateCommentClass($(row).find(".comment-input"), comment_required)
    })
    this.updateRequiredInstructionClass()
    this.updateMultiReportAcknowledgmentClass()
  }

  validateInput(event) {
    this.updateSelectClass($(event.currentTarget))
    this.updateRequiredInstructionClass()
  }

  validateCommentInput(event) {
    this.updateCommentClass($(event.currentTarget))
    this.updateRequiredInstructionClass()
  }

  validateMultiReportAcknowledgmentInput(event) {
    this.updateMultiReportAcknowledgmentClass()
  }

  updateSelectClass(element) {
    if (element.length === 0) return
    if (element.val().length) {
      element.closest("[data-required]").removeClass("has-error")
    } else {
      element.closest("[data-required]").addClass("has-error")
    }
  }

  updateCommentClass(element, comment_required = false) {
    const words = element.val().trim().split(/\s+/).filter(i => i)
    if ((comment_required && words.length <= 0) ||
        words.length != 0 && words.length < 5) {
      element.parents("[data-required]").addClass("has-error")
    } else {
      element.parents("[data-required]").removeClass("has-error")
    }
  }

  updateRequiredInstructionClass() {
    if ($(this.followupSignalsTableTarget).find("[data-required].has-error").length) {
      $("[data-required-instructions]").addClass("text-danger")
    } else {
      $("[data-required-instructions]").removeClass("text-danger")
    }
  }

  updateMultiReportAcknowledgmentClass() {
    if (!this.hasMultiReportAcknowledgmentTarget) return

    if (this.multiReportAcknowledgmentTarget.checked) {
      $(this.multiReportAcknowledgmentTarget).parents("[data-required]").removeClass("has-error")
    } else {
      $(this.multiReportAcknowledgmentTarget).parents("[data-required]").addClass("has-error")
    }
  }

  toggleBulkEdit(enabled) {
    let bulk_edit_el = $(this.bulkEditTarget)
    if (enabled) {
      bulk_edit_el.find(".malice-select").removeAttr("disabled")
      bulk_edit_el.find(".category-select").removeAttr("disabled")
      bulk_edit_el.find(".contexts-select").removeAttr("disabled")
      bulk_edit_el.find(".comment-input").removeAttr("disabled")
      this.followupSignalsPanelTarget.classList.add("edit-open")
      this.bulkEditPanelTarget.classList.add("edit-open")
    }
    else {
      bulk_edit_el.find(".malice-select").attr("disabled", true)
      bulk_edit_el.find(".category-select").attr("disabled", true)
      bulk_edit_el.find(".contexts-select").attr("disabled", true)
      bulk_edit_el.find(".comment-input").attr("disabled", true)
      this.followupSignalsPanelTarget.classList.remove("edit-open")
      this.bulkEditPanelTarget.classList.remove("edit-open")
    }
  }

  removeTableRow(event) {
    let row = $(event.currentTarget).parents("tr")
    row.fadeOut(300, () => {
      let tbody = row.parents("tbody")
      row.remove()

      if (this.hasSignalsSelectedCountTarget) {
        let num_checked = $(this.followupSignalsTableTarget).find(".bulk-select:checked").length
        $(this.signalsSelectedCountTarget).text(num_checked)
      }

      let count = tbody.children("tr:visible").length
      if (this.hasSignalsCountTarget) {
        $(this.signalsCountTarget).text(count)
      }
      if (this.hasSignalsTotalCountTarget) {
        let totalCount = tbody.children("tr").length
        $(this.signalsTotalCountTarget).text(totalCount)
      }

      if (tbody.children("tr").length === 0) {
        $("#reassign").attr("disabled", true)
      }
      else if (count === 0) {
        $("#reassign").removeAttr("disabled")
        $("#reassign").text("Continue")
      }
      else {
        $("#reassign").removeAttr("disabled")
        $("#reassign").text("Re-Assign")
      }

      this.updateRequiredInstructionClass()
    })
  }

  disableButton(element) {
    $(element).attr("disabled", true).css("pointer-events", "none")
  }

  handleAjaxError() {
    $(this.ajaxModalController.getModalDialogContent()).find(".modal-title").text("Unable to Complete this Action")
    $(this.ajaxModalController.getModalDialogContent()).find(".modal-body").addClass("hidden")
    $(this.ajaxModalController.getModalDialogContent()).find(".modal-body#error").removeClass("hidden")
    $(this.ajaxModalController.getModalDialogContent()).find(".modal-footer").find(".btn-success:not([disabled='disabled'])").hide()
    $(this.ajaxModalController.getModalDialogContent()).find(".modal-footer").find(".btn-success").removeAttr("disabled").css("pointer-events", "").text("Retry")
    this.ajaxModalController.showAjaxModalContent()
  }

  toBoolean(value) {
    return value.toLowerCase() === "true"
  }
}
