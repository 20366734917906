import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]

  connect() {
    let template = this.templateTarget.innerHTML

    $(this.triggerEl).popover({
      template: template
    })

    $(this.triggerEl).on("click", (e) => { e.stopPropagation() })
  }

  get triggerEl() {
    return this.element.querySelector("[data-toggle='popover']")
  }
}
