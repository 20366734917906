import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets= ["category", "securityServiceName", "serviceTrackingContent",
    "autorunSelectTd", "autorunCount",
    "persistenceMechanismSelectTd", "persistenceMechanismCount",
    "binarySelectTd", "binaryCount",
    "totalAffectedCount"]

  connect() {
    $(this.categoryTarget)
      .select2({
        theme: "bootstrap",
        placeholder: "Choose A Category...",
        ajax: {
          url: Routes.admin_categories_path({format: "json"}),
          dataType: "json",
          delay: 250
        },
        width: "100%",
        allowClear: true
      })

    if (this.eligibleForServiceTracking) {
      $("#resolution-modal").on("select2:select", () => {
        this.showServiceTracking()
      })
      this.showServiceTracking()
    } else {
      this.hideServiceTracking()
    }

    $(this.categoryTarget).on("change", this.updateAffectedCount.bind(this))
    $(this.autorunSelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))
    $(this.persistenceMechanismSelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))

    if (this.hasBinarySelectTdTarget) {
      $(this.binarySelectTdTarget).find("select").on("change", this.updateAffectedCount.bind(this))
    }
  }

  get eligibleForServiceTracking() {
    return this.hasAllDataTargets && huntressData.windows_service
  }

  get hasAllDataTargets() {
    return this.hasCategoryTarget && this.hasSecurityServiceNameTarget && this.hasServiceTrackingContentTarget
  }

  get selectedCategoryText() {
    return $(this.categoryTarget).select2("data")[0].text
  }

  hideServiceTracking() {
    if (this.hasServiceTrackingContentTarget) {
      this.serviceTrackingContentTarget.classList.add("hidden")
    }
  }

  showServiceTracking() {
    const categoryName = this.selectedCategoryText
    this.securityServiceNameTarget.textContent = /[^/]*$/.exec(categoryName)[0]
    if (this.hasServiceTrackingContentTarget) {
      this.serviceTrackingContentTarget.classList.remove("hidden")
    }
  }

  updateAffectedCount() {
    let highestCount = 0

    if(this.autorunSelectTdTarget.querySelector("select").value) {
      const autorunCount = this.parseCount(this.autorunCountTarget.textContent)

      if(!isNaN(autorunCount)) {
        highestCount = autorunCount
      }
    }

    if(this.persistenceMechanismSelectTdTarget.querySelector("select").value) {
      const persistenceMechanismCount = this.parseCount(this.persistenceMechanismCountTarget.textContent)

      if(!isNaN(persistenceMechanismCount) && persistenceMechanismCount > highestCount) {
        highestCount = persistenceMechanismCount
      }
    }

    if(this.hasBinarySelectTdTarget && this.binarySelectTdTarget.querySelector("select").value) {
      const binaryCount = this.parseCount(this.binaryCountTarget.textContent)

      if(!isNaN(binaryCount) && binaryCount > highestCount) {
        highestCount = binaryCount
      }
    }

    if(this.categoryTarget.dataset["original"] != this.categoryTarget.value) {
      // Category uses the same count as autorun as it is an autorun field
      const categoryCount = this.parseCount(this.autorunCountTarget.textContent)

      if(!isNaN(categoryCount) && categoryCount > highestCount) {
        highestCount = categoryCount
      }
    }

    if(highestCount >= this.countLimit) {
      highestCount = `${this.countLimit}+`
    }

    this.totalAffectedCountTarget.textContent = highestCount.toString()
  }

  parseCount(text){
    const count = parseInt(text)

    if(isNaN(count)) {
      return 0
    }

    return count
  }

  get countLimit() {
    const defaultCountLimit = 1000
    const setCountLimit = parseInt(this.data.get("countLimit"))

    if(isNaN(setCountLimit)) {
      return defaultCountLimit
    }

    return setCountLimit
  }
}
