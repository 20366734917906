import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expiration"]

  connect() {
    var hash = window.location.hash.substring(1)
    if(hash == "tunnel_operator"){
      $("#tunnel_operator-tab").addClass("active")
      $("#tunnel_operator").addClass("active")
    }
    else{
      $("#country_code-tab").addClass("active")
      $("#country_code").addClass("active")
    }
  }
}
