import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datatable"]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $(this.datatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: 1, searchable: true },
        { targets: [0, 6, 7], orderable: false },
        { targets: "_all", orderable: true, searchable: false }
      ],
      order: [[4, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: this.data.get("ajaxUrl")
    })
  }
}
