export function css_var(name) {
    return getComputedStyle(document.documentElement).getPropertyValue(name).trim();
}

export function parse_color(name) {
    if (typeof name == "string" && name.startsWith("--")) {
        return css_var(name)
    } else {
        return name
    }
}
