import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkBox"]

  connect() {
    // bootstrap-toggle requires jQuery event
    $(this.checkBoxTarget).change(this.submitForm.bind(this))
  }

  disconnect() {
    $(this.checkBoxTarget).off("change")
  }

  submitForm(ev) {
    Rails.fire(ev.target.form, "submit")
  }

  handleUpdateAutoRenew(e) {
    this.emulateFlash(e, "success")
  }

  emulateFlash(e, status) {
    const innerElement = `<div class='alert alert-${status} alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>${e.detail[0].message}</div>`

    $("#alert-box").empty().append(innerElement)
  }

  handleSuccess(ev) {
    document.getElementById("renewal-row").classList.remove("hidden")
    document.getElementById("renewal-info").textContent = `${ev.detail[0].date} by ${ev.detail[0].email}`
  }

  handleError(ev) {
    if (this.isAutoRenewError(ev.detail[0])) {
      // Silently reset toggle to avoid infinite 'change event' callback loop
      // Must use inner element rather than convenience method
      const innerElement = $(this.checkBoxTarget).data("bs.toggle")
      if (this.checkBoxTarget.checked) {
        innerElement.off(true)
      } else {
        innerElement.on(true)
      }
      const errorMessage = "Not authorized to update the following attribute: " + ev.detail[0].forbidden_attributes
      // Allow toggle to visually reset before error prompt
      this.alertAfterDelay(errorMessage, 500)
    }
  }

  isAutoRenewError(response) {
    // Check to be certain the Ajax error is a rescued ForbiddenAttributesError from 'ContractsController#update'
    // Prevent TypeError case since Ajax request could potentially trigger different error with no 'forbidden_attributes'
    if (response.forbidden_attributes) {
      // Only one attribute (auto_renew) is PATCH'd upon auto-renew toggle
      const singleForbidden = (response.forbidden_attributes.length === 1)
      const forbiddenAttr = response.forbidden_attributes[0]
      if (singleForbidden && forbiddenAttr === "auto_renew") {
        return true
      }
    }
    return false
  }

  alertAfterDelay(message, delayMilliseconds) {
    setTimeout(function(){
      alert(message)
    }, delayMilliseconds
    )
  }
}
