import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "productSpecificSection",
    "edrTrialEnded",
    "m365TrialEnded",
  ]

  static values = {
    edrTrialDismissedKey: String,
    m365TrialDismissedKey: String,
  }

  connect() {
    // reload the page every 29 minutes
    this.refreshTimeoutId = setTimeout(() => {
      location.reload()
    }, 29 * 60 * 1000)

    if (this.hasEdrTrialEndedTarget && !this.hasM365TrialEndedTarget) {
      // Switch the orders of the products
      const element = this.edrTrialEndedTarget
      this.edrTrialEndedTarget.remove()
      this.productSpecificSectionTarget.appendChild(element)
    }

    if (this.hasEdrTrialEndedTarget && !this.hasDismissedTrialEndedProduct("edr")) {
      this.edrTrialEndedTarget.classList.remove("hidden")
    }

    if (this.hasM365TrialEndedTarget && !this.hasDismissedTrialEndedProduct("m365")) {
      this.m365TrialEndedTarget.classList.remove("hidden")
    }
  }

  disconnect() {
    clearTimeout(this.refreshTimeoutId)
  }

  dismissTrialEnded(e) {
    const { product } = e.target.dataset
    const key = this.trialEndedKeyForProduct(product)

    localStorage.setItem(key, "true")
    this[`${product}TrialEndedTarget`].remove()
  }

  hasDismissedTrialEndedProduct(product) {
    const key = this.trialEndedKeyForProduct(product)

    return localStorage.getItem(key) === "true"
  }

  trialEndedKeyForProduct(product) {
    if (product === "edr") return this.edrTrialDismissedKeyValue
    if (product === "m365") return this.m365TrialDismissedKeyValue
  }
}
