import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "cspModal",
    "checkBoxTenantParent",
    "checkBoxTenant1",
    "checkBoxTenant2",
    "checkBoxTenant3",
    "checkBoxTenant4",
    "checkBoxClientParent",
    "checkBoxExchangeLicense",
    "checkConditionalAccess",
    "microsoftOauthButtonEnabled",
    "microsoftOauthButtonDisabled",
  ]

  get clientCheckBoxes() {
    return [
      this.checkBoxExchangeLicenseTarget,
      this.checkConditionalAccessTarget,
    ]
  }

  get tenantCheckBoxes() {
    return [
      this.checkBoxTenant1Target,
      this.checkBoxTenant2Target,
      this.checkBoxTenant3Target,
      this.checkBoxTenant4Target,
    ]
  }

  get checkBoxTargets() {
    return this.tenantCheckBoxes.concat(this.clientCheckBoxes)
  }

  handleCheckBox(e) {
    if (e.target === this.checkBoxTenantParentTarget) {
      this.toggleAllTenantTargets(e.target.checked)
    } else if (e.target === this.checkBoxClientParentTarget) {
      this.toggleAllClientTargets(e.target.checked)
    } else {
      this.checkParentIfAllChildrenChecked()
    }

    this.strikeThroughCheckedBoxes()
    this.toggleMicrosoftButton()
  }

  toggleAllTenantTargets(toggle) {
    this.tenantCheckBoxes.forEach(checkbox => checkbox.checked = toggle)
  }

  toggleAllClientTargets(toggle) {
    this.clientCheckBoxes.forEach(checkbox => checkbox.checked = toggle)
  }

  allPrerequisiteTargetsChecked() {
    return this.checkBoxTargets.every(target => target.checked)
  }

  checkParentIfAllChildrenChecked() {
    this.checkBoxClientParentTarget.checked = this.clientCheckBoxes.every(box => box.checked)
    this.checkBoxTenantParentTarget.checked = this.tenantCheckBoxes.every(box => box.checked)
  }

  strikeThroughCheckedBoxes() {
    this.checkBoxTargets.forEach(target => {
      if (target.checked) {
        target.parentElement.children.forEach(child => {
          child.classList.add("strike-through")
        })
      } else {
        target.parentElement.children.forEach(child => {
          child.classList.remove("strike-through")
        })
      }
    })
  }

  toggleMicrosoftButton() {
    const enabledBtn = this.microsoftOauthButtonEnabledTarget
    const disabledBtn = this.microsoftOauthButtonDisabledTarget

    const enabled = this.allPrerequisiteTargetsChecked()
    if (enabled) {
      enabledBtn.style.display = "block"
      disabledBtn.style.display = "none"
    } else {
      enabledBtn.style.display = "none"
      disabledBtn.style.display = "block"
    }
  }
}
