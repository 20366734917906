import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"


export default class extends Controller {
  static targets = [
    "confirmationModal",
    "confirmationModalTitle",
    "confirmationModalBody",
    "confirmButton",
    "purchaseOrder",
    "form"
  ]

  static values = {
    isUpgrade: Boolean
  }

  connect() {
    this.subscriptions = []
    this.setupFormValidation()
  }

  onSetSelectedPlan(e) {
    let { product, price } = e.detail
    this.removeProductFromSubscriptions(product)
    if (price) this.subscriptions.push(e.detail)
  }

  onProductRemoved(e) {
    let { product } = e.detail
    this.removeProductFromSubscriptions(product)
  }

  removeProductFromSubscriptions(product) {
    this.subscriptions = this.subscriptions.filter((sub) => sub.product !== product)
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3({ rowValidClass: "" }),
          trigger: new plugins.Trigger({
            event: "blur"
          })
        },
        fields: {
          purchaseOrder: {
            selector: '[name="subscription[purchase_order]"]',
            validators: {
              notEmpty: { message: "Purchase order is required." }
            }
          },
        }
      }
    )
  }

  showConfirmationModal(){
    let cartIsEmpty = this.subscriptions.length === 0
    const isInvalidPurchaseOrder = this.purchaseOrderTarget.value === ""

    this.fv.validateField("purchaseOrder")

    if (cartIsEmpty) {
      let actionText = this.isUpgrade ? "upgrade your subscription" : "start a subscription"
      this.confirmationModalTitleTarget.textContent = "Invalid Subscription Minimum"
      this.confirmationModalBodyTarget.textContent = `In order to ${actionText}, please change the minimum for the product you would like to add and try again.`
      this.confirmButtonTarget.classList.add("hidden")
      $("#confirmationModal").modal()
    } else if (isInvalidPurchaseOrder) {
      window.scrollTo(0,0)
    } else {
      this.confirmationModalTitleTarget.textContent = this.isUpgradeValue ? "Confirm Upgrade Subscription" : "Confirm Save and Start Subscriptions"
      this.confirmationModalBodyTarget.textContent = `Are you sure you would like to ${this.isUpgradeValue ? "upgrade" : "save and start"} ${this.subscriptions.length > 1 ? `these ${this.subscriptions.length}` : "this"} subscription${this.subscriptions.length > 1 ? "s" : ""}?`
      this.confirmButtonTarget.classList.remove("hidden")
      $("#confirmationModal").modal()
    }
  }
}
