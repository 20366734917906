import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "form",
    "ticketTypeIdField",
    "ticketTypeIdLabel",
    "defaultCompanyIdField",
    "defaultCompanyIdLabel",
    "submitButton"
  ]

  connect() {
    this.setupFormValidation()

    $(this.ticketTypeIdFieldTarget).on("select2:select select2:unselect", this.validateField.bind(this, "ticketTypeId"))
    $(this.defaultCompanyIdFieldTarget).on("select2:select select2:unselect", this.validateField.bind(this, "defaultCompanyId"))
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3(),
          trigger: new plugins.Trigger({
            event: "blur focus"
          })
        },
        fields: {
          ticketTypeId: {
            selector: '[name="integrations_halo_psa[ticket_type_id]"',
            validators: {
              notEmpty: { message: "Selecting a ticket type is required." },
            }
          },
          defaultCompanyId: {
            selector: '[name="integrations_halo_psa[default_company_id]"',
            validators: {
              notEmpty: { message: "Selecting a client is required." },
            }
          }
        }
      }
    )

    this.fv.on("core.field.invalid", (field) => this.toggleLabel(field, true))
    this.fv.on("core.field.valid", (field) => this.toggleLabel(field, false))
  }

  validateField(field) {
    // Trigger the validate so the `core.field.valid/invalid`
    // callback can run and update the label color
    this.fv.validateField(field)
    this.toggleButtonDisable()
  }

  toggleLabel(field, invalid) {
    // Same red used by `formValidation`
    this[`${field}LabelTarget`].style.color = invalid ? "#E74C3C" : null
  }

  toggleButtonDisable() {
    // Not running this.fv.validate here to prevent the
    // form fields from all being validated and turning red
    const allFilledOut = (
      this.ticketTypeIdFieldTarget.value.length > 0 &&
      this.defaultCompanyIdFieldTarget.value.length > 0
    )

    this.submitButtonTarget.disabled = !allFilledOut
  }
}
