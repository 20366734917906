import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["organizationFilter", "agentFilter"]

  connect(){
    $(this.element).find(".dropdown-toggle").on("click", () => {
      if(!this.hasOrganizationFilterTarget || $(this.organizationFilterTarget).val()){
        $(this.agentFilterTarget).select2("enable")
      }
    })

    if(this.hasOrganizationFilterTarget){
      $(this.organizationFilterTarget).on("change", () =>{
        if($(this.organizationFilterTarget).val()){
          $(this.organizationFilterTarget).parents(".datagrid-form").get(0).requestSubmit()
        }
        else{
          $(this.agentFilterTarget).select2("disable")
        }
      })
    }
  }
}
