import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "addButton",
  ]

  static values = {
    product: String,
    added: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false }
  }

  connect() {
    this.formElement = this.formTarget
    this.collapse()
  }

  collapse() {
    this.expandedValue = false
    if (!this.addedValue) this.removeForm()
  }

  expand() {
    this.dispatch("expanded")
    this.element.append(this.formElement)
    this.expandedValue = true
  }

  // Removes the form from the DOM
  removeForm() {
    this.formElement.remove()
  }

  // Handles the "Remove" UI action, which removes the product selection
  remove() {
    this.addedValue = false
    this.collapse()
  }

  // Saves the product selection
  save() {
    this.addedValue = true
    this.collapse()
  }

  // When the added value changes, we reflect in the UI and broadcast the change
  addedValueChanged() {
    this.addButtonTarget.classList.toggle("added", this.addedValue)
    this.dispatch("addedChanged", { detail: { added: this.addedValue, product: this.productValue } } )
  }

  // Applies the expanded class as expanded value changes, switching between intro and form panels
  expandedValueChanged() {
    this.element.classList.toggle("expanded", this.expandedValue)
  }
}
