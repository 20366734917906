import { Controller } from "@hotwired/stimulus"
import { useTimeRangeFilter } from "useTimeRangeFilter"

export default class extends Controller {
  static targets = ["form"]

  get signalsController() {
    return document.querySelector("[data-controller~='admin--signals--signals']")["admin--signals--signals"]
  }

  connect() {
    $(this.formTarget).find(".dropdown-menu").on("click", ev => {
      ev.stopPropagation() // keep dropdown menu open
    })

    useTimeRangeFilter(this)
  }

  async generateIndividual() {
    const target = event.currentTarget
    const reloadOnClose = target.dataset.reloadOnClose

    const resourceId = target.dataset.resourceId
    const resource = { type: "DefenderAlert", id: resourceId }

    await this.signalsController.generate(resource, reloadOnClose, true)
  }

  async generateBulk(ev) {
    await this.signalsController.generateBulk(ev)
  }
}
