import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkBoxEDR", "checkBoxSAT", "checkBoxM365"]

  connect() {
    // bootstrap-toggle requires jQuery event
    this.product = this.element.dataset.product
    this.checkBoxDOM = this[`checkBox${this.product}Target`]
    $(this.checkBoxDOM).change(this.submitForm.bind(this))
  }

  submitForm(ev) {
    Rails.fire(ev.target.form, "submit")
  }

  handleSuccess(ev) {
    this.emulateFlash(ev, "success")
  }

  handleError(ev) {
    this.emulateFlash(ev, "danger")
    this.resetToggle()
  }

  resetToggle() {
    const innerElement = $(this.checkBoxDOM).data("bs.toggle")
    if (this.checkBoxDOM.checked) {
      innerElement.off(true)
    } else {
      innerElement.on(true)
    }
  }

  emulateFlash(e, status) {
    const flashElement = document.querySelector("#alert-box > .alert")
    const closeButton = flashElement.children[0]
    flashElement.classList.remove("hidden", "alert-danger", "alert-success")
    flashElement.classList.add(`alert-${status}`)
    flashElement.innerHTML = ""
    flashElement.appendChild(closeButton)
    flashElement.append(e.detail[0].message)
  }

  hideFlash() {
    const flashElement = document.querySelector("#alert-box > .alert")
    flashElement.classList.add("hidden")
  }
}
