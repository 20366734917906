import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pieChart"]

  onPieChartClick(e) {
    e.preventDefault()

    const context = $(this.pieChartTarget).data("chart")
    const pieSegment = context.getElementAtEvent(e)[0]
    if(pieSegment) {
      const label = pieSegment._model.label
      this.applyFilter(label.toLowerCase())
    }
  }

  onLabelClick(e) {
    e.preventDefault()

    const target = e.target.closest(".pie-label")
    const label = target.dataset.value
    this.applyFilter(label)
  }

  removeFilter(e) {
    e.preventDefault()

    const filter = e.target.dataset.value
    const currentParams = new URLSearchParams(window.location.search)
    const currentFilters = currentParams.getAll("filters[]") || []
    if(currentFilters.includes(filter)) {
      currentFilters.splice(currentFilters.indexOf(filter), 1)
    }

    currentParams.delete(`filters[${filter}]`)
    currentFilters.forEach(filter => { currentParams.append(`filters[${filter}]`, 1) })

    location.search = currentParams.toString()
  }

  applyFilter(filter) {
    const currentParams = new URLSearchParams(window.location.search)
    const currentFilters = currentParams.getAll("filters[]") || []
    if(currentFilters.includes(filter)) {
      return
    }

    currentFilters.push(filter)
    currentParams.delete(`filters[${filter}]`)
    currentFilters.forEach(filter => { currentParams.append(`filters[${filter}]`, 1) })
    location.search = currentParams.toString()
  }
}
