import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(e) {
    if (this.disabled) {
      e.preventDefault()
    } else {
      // Done in timeout because changing it immediately affects loading
      // the turbo handling
      $(this.element).tooltip("hide")
      setTimeout(() => this.element.innerHTML = "Auto-mapping...", 0)
      this.element.setAttribute("disabled", "disabled")

      let integrationType = this.data.get("integrationType")
      window.hj("event", `automap-clicked:${integrationType}`)
    }
  }

  get disabled() {
    return this.element.getAttribute("disabled") == "disabled"
  }
}
