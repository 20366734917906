import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "quickSelect", "quickSelectContainer", "submit", "textArea", "type", "processPathInput", "processPidInput"]

  connect() {
    $("body").on("shown.bs.modal", ".modal", this.showFieldsForType.bind(this))

    this.showFieldsForType()
  }

  disconnect() {
    $("body").off("shown.bs.modal", ".modal", this.showFieldsForType.bind(this))
  }

  quickSelectTargetConnected() {
    this.quickSelectChanged()
  }

  showFieldsForType() {
    if(!this.hasTypeTarget)
      return

    $("#add-assisted-remediation-modal .parameters").hide()

    let value = this.type
    if (value === "") return

    $("#" + value).show()

    $("#add-assisted-remediation-modal .parameters input").prop("disabled", true)
    $("#" + value + " input").prop("disabled", false)

    if (this.isProcessRemediation) {
      this.quickSelectChanged()
      this.quickSelectContainerTarget.classList.remove("hidden")
    } else {
      this.quickSelectContainerTarget.classList.add("hidden")
    }

    if (this.isRebootRemediation) {
      $(".infection_report_assisted_remediations_details_reboot_message input").val(this.data.get("defaultRebootMessage"))
    }
  }

  quickSelectChanged() {
    let array = this.quickSelectTarget.value.split(" - ")

    if (array.length > 1) {
      this.processPidInputTarget.value = array[0]
      this.processPathInputTarget.value = array[1]
    }
  }

  removeRemediationFromQuickSelect() {
    let pid = $("#remediation_pid").val()
    let path = $("#Remediations-Process #remediation_path").val()
    let submission = `${pid} - ${path}`

    for (let i = 0; i < this.quickSelectTarget.length; i++) {
      if (this.quickSelectTarget.options[i].value === submission) {
        this.quickSelectTarget.remove(i)
      }
    }
  }

  get type() {
    return this.typeTarget.value.replace(/::/g, "-")
  }

  get isRebootRemediation() {
    return this.type === "Remediations-Reboot"
  }

  get isProcessRemediation() {
    return this.type === "Remediations-Process"
  }
}
