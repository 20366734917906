import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "category",
    "endpoints",
    "form",
    "ignoreAcknowledge",
    "input",
    "organizations",
    "submitButton",
    "validate",
  ]

  connect() {
    this.toggleSubmitButton()

    // We have to add the listener here instead of using a Stimulus HTML data
    // attribute because that doesn't work with select2 inputs
    if (this.hasEndpointsTarget)
      $(this.endpointsTarget).on("change", this.toggleSubmitButton.bind(this))

    // We have to add the listener here instead of using a Stimulus HTML data
    // attribute because that doesn't work with select2 inputs
    if (this.hasOrganizationsTarget)
      $(this.organizationsTarget).on("change", this.toggleSubmitButton.bind(this))
  }

  validateOnCategoryChange(event) {
    const category = event.currentTarget
    const input = category.nextElementSibling
    this.debouncedSubmit(input, category)
  }

  validateOnInputChange(event) {
    const input = event.currentTarget
    const category = input.previousElementSibling
    this.debouncedSubmit(input, category)
  }

  debouncedSubmit(input, category) {
    this.toggleSubmitButton()

    // This conditional check makes sure we only auto-submit exclusions for validation when we have both a category
    // and a input, otherwise validation doesn't make sense yet and creates many View<>Controller params edge cases.
    if (category.value.trim() === "" || input.value.trim() === "") {
      return
    }

    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }

    this.debounceTimeout = setTimeout(() => this.submitForInputValidation(this), 750)
  }

  toggleSubmitButton() {
    let disabled = false
    let total = this.categoryTargets.length

    if (this.hasEndpointsTarget)
      disabled = this.endpointsTarget.selectedOptions.length === 0

    if (this.hasOrganizationsTarget)
      disabled = this.organizationsTarget.selectedOptions.length === 0

    this.categoryTargets.forEach((categoryTarget, index) => {
      const inputTarget = this.inputTargets[index]
      const hasCategory = categoryTarget.value.trim() !== ""
      const hasValue = inputTarget.value.trim() !== ""

      // We want to disable an empty form. This does not apply to an empty
      // last row if there is more than one rows.
      if (total === 1 || index !== total - 1)
        if (!hasCategory && !hasValue)
          disabled = true

      if (hasCategory !== hasValue)
        disabled = true
    })

    if (this.hasIgnoreAcknowledgeTarget)
      disabled = disabled || !this.ignoreAcknowledgeTarget.checked

    this.submitButtonTarget.disabled = disabled
  }

  remove(event) {
    event.currentTarget.parentElement.remove()
    this.submitForInputValidation()
  }

  disableInputs() {
    this.categoryTargets.forEach((element) => { element.disabled = true })
    this.inputTargets.forEach((element) => { element.disabled = true })
    this.submitButtonTarget.disabled = true
  }

  submitForInputValidation() {
    this.formTarget.requestSubmit(this.validateTarget)
    this.disableInputs()
  }
}
