import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from "../../../helpers/moneyHelpers"

export default class extends Controller {
  static targets = [
    "quantity",
    "msrpPerUnit",
    "subPerUnit",
    "annualMsrp",
    "subAnnual"
  ]

  static values = {
    currency: String,
    product: String,
    prices: Array,
    subscription: { type: Object, default: {} }
  }

  connect() {
    if (this.subscriptionValue && Object.keys(this.subscriptionValue).length) {
      const minimum = this.subscriptionValue.minimum
      let price = this.pricesValue.findLast(price => price.tier <= minimum)
      this.setTotal(price, minimum)
      return
    }

    this.setTotal()
  }

  onSetSelectedPlan(e) {
    const { product } = e.detail
    if (this.productValue != product) return

    const { price, minimum } = e.detail

    if (price) {
      this.setTotal(price, parseInt(minimum))
      this.element.hidden = false
    } else if (minimum == 0) {
      this.removeProduct()
    }
  }

  removeProduct(e = null) {
    e?.preventDefault()
    this.element.hidden = true
    this.setTotal()
    this.dispatch("productRemoved", { detail: { product: this.productValue }})
  }

  setTotal(price, minimum = 0) {
    if (price) {
      let pricePer = price.amount
      let planMSRP = pricePer / (price.priceType == "reseller_managed" ? 0.5 : 0.8)
      let annualMSRP = (planMSRP * minimum * 12)
      let subAnnual = (pricePer * minimum * 12)

      this.msrpPerUnitTarget.innerHTML = formatCurrency(this.currencyValue, planMSRP)
      this.subPerUnitTarget.innerHTML = formatCurrency(this.currencyValue, pricePer)
      this.annualMsrpTarget.innerHTML = formatCurrency(this.currencyValue, annualMSRP)
      this.subAnnualTarget.innerHTML = formatCurrency(this.currencyValue, subAnnual)
    } else {
      this.msrpPerUnitTarget.innerHTML = ""
      this.subPerUnitTarget.innerHTML = ""
      this.annualMsrpTarget.innerHTML = ""
      this.subAnnualTarget.innerHTML = ""
    }

    this.quantityTarget.innerHTML = parseInt(minimum).toLocaleString()
    this.element.hidden = !(minimum > 0 && price.amount > 0)
  }
}
