import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#infected-hosts").DataTable({
      lengthMenu: [10, 25, 50, 100, 200, 500],
      processing: true,
      stateSave: true,
      stateDuration: 300,
    })
  }
}
