import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "binaryHash"
  ]

  binaryHashLookup() {
    let hash = this.binaryHashTarget.value
    window.location.href = Routes.sha256_admin_binaries_path(hash)
  }
}
