import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emails", "checkbox", "replyEmails"]

  connect() {
    $(this.emailsTarget).on("change", () => {
      this.setReplyEmails()
    })
  }

  checkboxToggled(evt) {
    this.setReplyEmails()
  }

  setReplyEmails() {
    if (this.checkboxTarget.checked) {
      $(this.replyEmailsTarget).tokenfield("disable")
      $(this.replyEmailsTarget).tokenfield("setTokens", this.emailsTarget.value)
    } else {
      $(this.replyEmailsTarget).tokenfield("enable")
    }
  }
}
