import { Controller } from "@hotwired/stimulus"
import { useTimeRangeFilter } from "useTimeRangeFilter"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    $(this.formTarget).find(".dropdown-menu").on("click", ev => {
      ev.stopPropagation() // keep dropdown menu open
    })

    useTimeRangeFilter(this)
  }
}
