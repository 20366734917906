import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const options = {
      theme: "bootstrap",
      placeholder: "Select an option...",
      width: "100%",
      cache: true,
      ajax: this.ajaxOptions
    }
    $(this.element).select2(options)
  }

  get ajaxOptions() {
    let companiesUrl = this.data.get("companiesUrl")
    let ajax_options = {
      url: companiesUrl,
      dataType: "json",
      delay: 150,
    }
    if(companiesUrl.includes("/autotask/")) {
      let last_id = 0
      ajax_options = {
        url: companiesUrl,
        dataType: "json",
        delay: 50,
        data: function (params) {
          if(params.page)
            params.page = last_id
          return params
        },
        processResults: function (data) {
          if(data.results && data.results.length > 0)
            last_id = data.results[data.results.length -1].id
          return data
        }
      }
    }
    return ajax_options
  }
}
