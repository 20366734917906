import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filter"]

  connect() {
    let url = this.data.get("url")

    this.registerEventHandlers()

    this.dataTable = $("#datatable table").DataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
      ],
      order: [[0, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: url
    })
  }

  registerEventHandlers() {
    $("#datatable table")
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })
  }

  refreshTable(event) {
    let url = event.target.getAttribute("data-url")
    this.filterTargets.forEach(target => target.classList.remove("active"))
    this.dataTable.ajax.url(url).load()
  }
}
