import { Controller } from "@hotwired/stimulus"
export const DATEPICKER_SETTINGS = { format: "yyyy-mm-dd" }

export default class extends Controller {
  static values = {
    settings: { type: Object, default: {} },
  }

  connect() {
    // Use default settings and combine with custom settings if explicitly defined in Stimulus data map
    const settings = { ...DATEPICKER_SETTINGS, ...this.settingsValue }
    $(this.element).datepicker(settings)
  }
}
