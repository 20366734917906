import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["info"]

  connect() {
    this.modal = this.element.closest(".modal-content")
  }

  generateSignal(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    const { srcElement: link } = event
    let {href: url} = link
    let method = link.dataset.method

    this.toggleInfoVisibility()

    $.ajax({
      method: method,
      url: url,
      dataType: "json",
      success: ({info, update, update_component}) => {
        this.toggleInfoType("success")
        
        this.infoTarget.textContent = info
        this.toggleInfoVisibility()

        this.updateDOM(update)
        this.updateComponent(update_component)
      },
      error: ({ responseJSON }) => {
        if (responseJSON) {
          $(this.infoTarget).addClass("text-danger")
          this.toggleInfoType("error")
          this.toggleInfoVisibility()
          this.infoTarget.textContent = responseJSON.info
        }
      }
    })
  }

  updateDOM(update) {
    if (!update || !update.selector || !update.replace) { return }

    const replaceElt = $(`${update.selector}`)
    if (replaceElt) {
      replaceElt.html(this.eltFromString(update.replace))
    }
  }

  updateComponent(update) {
    if (!update || !update.selector || !update.replace) { return }

    // Only search within the component
    const replaceElt = this.modal.querySelector(`${update.selector}`)
    if (replaceElt) {
      $(replaceElt).html(this.eltFromString(update.replace))
    }
  }

  toggleInfoType(type) {
    $(this.infoTarget).removeClass(["text-danger", "text-success"])

    if (type === "error") {
      $(this.infoTarget).addClass("text-danger")
    } else {
      $(this.infoTarget).addClass("text-success")
    }
  }

  toggleInfoVisibility() {
    if ($(this.infoTarget).hasClass("info-visible")) {
      $(this.infoTarget).removeClass("fadeOut")
      $(this.infoTarget).addClass("fadeIn")
      $(this.infoTarget).removeClass("info-visible")
    } else {
      $(this.infoTarget).removeClass(["fadeOut", "fadeIn"])
      $(this.infoTarget).addClass("fadeOut")
      $(this.infoTarget).addClass("info-visible")
    }
  }

  eltFromString(str) {
    const template = document.createElement("template")
    template.innerHTML = str

    return template.content.firstChild
  }
}
