import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

const HEADER_ROW_INDEX = 0
const PATH_COLUMN_INDEX = 0

export default class extends Controller {
  static targets = [
    "enforceAntivirusPolicyAgentInput",
    "exclusionTab",
    "extensionExclusionsInheritedTable",
    "extensionExclusionsInput",
    "form",
    "ignoreAcknowledge",
    "ignoreExtensionRecommendations",
    "ignorePathRecommendations",
    "ignoreProcessRecommendations",
    "pathExclusionsInheritedTable",
    "pathExclusionsInput",
    "processExclusionsInheritedTable",
    "processExclusionsInput",
    "saveButton",
  ]

  exclusionTabClicked() {
    this.debouncedSubmit()
  }

  debouncedSubmit() {
    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }

    this.debounceTimeout = setTimeout(() => {
      if (!this.saveButtonTarget) {
        // Return early if Save button is missing; do not want to submit form.
        // Save button is missing when current user cannot update AntivirusPolicy/Antivirus
        return
      }

      const debouncedSubmitHiddenInput = document.createElement("input")
      debouncedSubmitHiddenInput.type = "hidden"
      debouncedSubmitHiddenInput.name = "submission_type"
      debouncedSubmitHiddenInput.value = "validate_exclusions"
      this.element.appendChild(debouncedSubmitHiddenInput)

      this.element.requestSubmit()

      debouncedSubmitHiddenInput.remove()
    }, 500)
  }

  connect() {
    this.toggleSaveButton()
    this.setupFormValidation()

    $(".exclusions-input").on("scroll", (e) => {
      const textarea = $(e.target)
      const container = textarea.parents(".exclusion-highlight-container")
      const highlights = container.find(".exclusion-highlights")
      this.handleScroll(textarea, highlights)
    })
  }

  toggleSaveButton() {
    const checkboxes = []

    if (this.hasIgnoreExtensionRecommendationsTarget)
      checkboxes.push(this.ignoreExtensionRecommendationsTarget)

    if (this.hasIgnorePathRecommendationsTarget)
      checkboxes.push(this.ignorePathRecommendationsTarget)

    if (this.hasIgnoreProcessRecommendationsTarget)
      checkboxes.push(this.ignoreProcessRecommendationsTarget)

    if (this.hasIgnoreAcknowledgeTarget)
      checkboxes.push(this.ignoreAcknowledgeTarget)

    const allChecked = checkboxes.every(checkbox => !checkbox || checkbox.checked)

    this.saveButtonTarget.disabled = !allChecked
  }

  check() {
    this.toggleSaveButton()
  }

  setupFormValidation() {
    const fv = formValidation(this.formTarget, {
      plugins: {
        bootstrap3: new Bootstrap3(),
        trigger: new plugins.Trigger({
          event: "input",
          delay: {
            [this.pathExclusionsInputTarget.name]: 1,
            [this.extensionExclusionsInputTarget.name]: 1,
            [this.processExclusionsInputTarget.name]: 1
          }
        }),
      },
      fields: this.formValidationFields
    })

    // check validation when page loads to begin with proper form state
    let validationInputs = [
      this.pathExclusionsInputTarget,
      this.extensionExclusionsInputTarget,
      this.processExclusionsInputTarget
    ]

    if (this.hasEnforceAntivirusPolicyAgentInputTarget) {
      validationInputs.push(this.enforceAntivirusPolicyAgentInputTarget)
    }

    validationInputs.forEach( (input) => {
      fv.revalidateField(input.name)
    } )

    return fv
  }

  get formValidationFields() {
    let fieldsHash = this.exclusionValidationFields

    if (this.hasEnforceAntivirusPolicyAgentInputTarget) {
      fieldsHash[this.enforceAntivirusPolicyAgentInputTarget.name] = {
        validators: {
          callback: {
            callback: () => {
              return {
                valid: this.enforcePolicyAgentSelectionValid,
                message: "This policy can be changed but this host will not be fully set to Enforce until the Huntress agent is updated."
              }
            }
          }
        }
      }
    }

    return fieldsHash
  }

  get exclusionValidationFields() {
    return {
      [this.pathExclusionsInputTarget.name]: {
        validators: {
          callback: {
            callback: () => {
              return {
                valid: this.allPathExclusionsAreUnique(),
                message: "Path exclusions on Windows hosts are case insensitive. Duplicate values are present and will be de-duplicated prior to saving."
              }
            }
          }
        }
      },
      [this.extensionExclusionsInputTarget.name]: {
        validators: {
          callback: {
            callback: () => {
              return {
                valid: this.allExtensionExclusionsAreUnique(),
                message: "Extension exclusions on Windows hosts are case insensitive. Duplicate values are present and will be de-duplicated prior to saving."
              }
            }
          }
        }
      },
      [this.processExclusionsInputTarget.name]: {
        validators: {
          callback: {
            callback: () => {
              return {
                valid: this.allProcessExclusionsAreUnique(),
                message: "Process exclusions on Windows hosts are case insensitive. Duplicate values are present and will be de-duplicated prior to saving."
              }
            }
          }
        }
      },
    }
  }

  allPathExclusionsAreUnique() {
    if (this.hasPathExclusionsInheritedTableTarget) {
      return this.allExclusionsAreUnique(this.pathExclusionsInputTarget, this.pathExclusionsInheritedTableTarget)
    } else {
      return !this.anyDuplicatedInInput(this.pathExclusionsInputTarget)
    }
  }

  allExtensionExclusionsAreUnique() {
    if (this.hasExtensionExclusionsInheritedTableTarget) {
      return this.allExclusionsAreUnique(this.extensionExclusionsInputTarget, this.extensionExclusionsInheritedTableTarget)
    } else {
      return !this.anyDuplicatedInInput(this.extensionExclusionsInputTarget)
    }
  }

  allProcessExclusionsAreUnique() {
    if (this.hasProcessExclusionsInheritedTableTarget) {
      return this.allExclusionsAreUnique(this.processExclusionsInputTarget, this.processExclusionsInheritedTableTarget)
    } else {
      return !this.anyDuplicatedInInput(this.processExclusionsInputTarget)
    }
  }

  allExclusionsAreUnique(inputTarget, tableTarget) {
    if (this.anyDuplicatedInInput(inputTarget) || this.anyDuplicatedByInheritance(inputTarget, tableTarget)) {
      return false
    }
    return true
  }

  anyDuplicatedInInput(inputTarget) {
    return this.findDuplicates(this.exclusionsFromInput(inputTarget)).length != 0
  }

  anyDuplicatedByInheritance(inputTarget, tableTarget) {
    const inheritedExclusions = this.exclusionsFromInheritedTable(tableTarget)
    const inputExclusions = this.exclusionsFromInput(inputTarget)
    for (let i = 0; i < inputExclusions.length; i++) {
      if (inheritedExclusions.includes(inputExclusions[i])) {
        return true
      }
    }
    return false
  }

  findDuplicates(collection) {
    return collection.filter((item, index) => collection.indexOf(item) != index)
  }

  exclusionsFromInput(inputTarget) {
    return inputTarget.value.toLowerCase().split("\n")
  }

  exclusionsFromInheritedTable(tableTarget) {
    let inheritedExclusionsCollection = []
    for (let i = HEADER_ROW_INDEX + 1; i < tableTarget.rows.length; i++) {
      inheritedExclusionsCollection.push(tableTarget.rows[i].cells[PATH_COLUMN_INDEX].innerHTML.toLowerCase())
    }
    return inheritedExclusionsCollection
  }

  applyRecommendedSettings() {
    let selects = this.element.querySelectorAll("body select")
    selects.forEach(select => this.selectRecommendedOption(select))
  }

  selectRecommendedOption(select) {
    select.options.forEach((option, i) => {
      if (this.isRecommended(option)) {
        select.selectedIndex = i
        return
      }
    })
  }

  isRecommended(option) {
    return option.innerHTML == "Inherit Huntress Recommended"
  }

  get enforcePolicyAgentSelectionValid() {
    return !this.enforcePolicyAgentSelectionInvalid
  }

  get enforcePolicyAgentSelectionInvalid() {
    return this.overrideWithEnforceSelected && !this.antivirusPolicyEnforceable
  }

  get overrideWithEnforceSelected() {
    return this.hasEnforceAntivirusPolicyAgentInputTarget && this.enforceAntivirusPolicyAgentInputTarget.value === "true"
  }

  get antivirusPolicyEnforceable() {
    return this.data.get("antivirusEnforceable") === "true"
  }

  handleScroll(target, highlights) {
    this.syncScroll(target.get(0), highlights.get(0))
  }

  syncScroll(scrolledEle, ele) {
    const scrolledPercent = scrolledEle.scrollTop / (scrolledEle.scrollHeight - scrolledEle.clientHeight)
    const top = scrolledPercent * (ele.scrollHeight - ele.clientHeight)

    const scrolledWidthPercent = scrolledEle.scrollLeft / (scrolledEle.scrollWidth - scrolledEle.clientWidth)
    const left = scrolledWidthPercent * (ele.scrollWidth - ele.clientWidth)

    ele.scrollTo({
      behavior: "instant",
      top,
      left,
    })
  }
}
