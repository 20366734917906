import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).colorpicker({
      format: this.format,
      horizontal: this.horizontal,
      hexNumberSignPrefix: this.hexNumberSignPrefix
    })
    if(this.data.get("previewOnly") === "1") {
      $(this.element).colorpicker("disable")
    }
  }

  show() {
    $(this.element).colorpicker("show")
  }

  get format() {
    return this.data.get("format") || "hex"
  }

  get horizontal() {
    return this.data.get("horizontal") !== null
  }

  get hexNumberSignPrefix() {
    return this.data.get("hexNumberSignPrefix") !== null
  }
}
