import BulkActionsController from "../components/bulk_actions_controller"

const REQUIRE_ENABLED_ANTIVIRUS_TYPES = ["full-scan", "quick-scan", "signature-update"]
const REQUIRE_ENFORCEABLE_ANTIVIRUS_TYPES = ["enforce", "inherit"]

export default class extends BulkActionsController {
  bulkUpdate(e) {
    this.bulkActionType = $(e.target).data("bulk-action-type")
    let selector = `#bulk-${this.bulkActionType}-modal`
    $(this.agentIdsInput(selector)).val(this.selectedActionableIds)
    this.addUpdateDetailsToModal(selector, this.bulkActionType)
    $(selector).modal()
  }

  set bulkActionType(type) {
    this._bulkActionType = type
  }

  get bulkActionType() {
    return this._bulkActionType
  }

  get selectedActionableIds() {
    if (this.requiresEnforceableAntivirus) {
      return this.selectedEnforceableIds
    } else if (this.requiresEnabledAntivirus) {
      return this.selectedAntivirusEnabledIds
    } else {
      return this.getSelectedIds()
    }
  }

  get requiresEnabledAntivirus() {
    return REQUIRE_ENABLED_ANTIVIRUS_TYPES.includes(this.bulkActionType)
  }

  get requiresEnforceableAntivirus() {
    return REQUIRE_ENFORCEABLE_ANTIVIRUS_TYPES.includes(this.bulkActionType)
  }

  agentIdsInput(modalId) {
    return `${modalId} input[name="bulk_update[agent_ids]"]`
  }

  addUpdateDetailsToModal(modalId) {
    let html = ""
    const categorizedAgents = this.selectedAgentsCategorized
    let actionableAgents = []
    let unactionableAgents = []
    if (this.requiresEnabledAntivirus) {
      actionableAgents = categorizedAgents.antivirusEnabled
      unactionableAgents = categorizedAgents.antivirusDisabled
    } else if (this.requiresEnforceableAntivirus) {
      actionableAgents = categorizedAgents.enforceable
      unactionableAgents = categorizedAgents.unenforceable
    } else {
      actionableAgents = this.selectedAgents
    }
    if (unactionableAgents.length > 0) {
      html += this._addUnactionableAgentsMessage(unactionableAgents)
    }

    if (actionableAgents.length > 0 ) {
      html += this._addActionableAgentsMessage(actionableAgents)
      if (this.bulkActionType === "full-scan") {
        html += this._addFullScanWarning()
      }
      this.enableUpdateButton(modalId)
    } else {
      this.disableUpdateButton(modalId)
    }

    $(`${modalId} div[class="modal-body"]`).html(html)
  }

  _addUnactionableAgentsMessage(unactionableAgents) {
    let heading = ""

    if (this.requiresEnforceableAntivirus) {
      heading = "The following agents will not change because their status is \"Incompatible\":"
    } else {
      heading = "The following agents will not perform the action because Microsoft Defender is disabled:"
    }

    return this.formattedAgentDetails(heading, unactionableAgents, "text-danger")
  }

  _addActionableAgentsMessage(actionableAgents) {
    let heading = ""
    switch (this.bulkActionType) {
    case "enforce":
      heading = "The following agents will have their mode set to Enforce:"
      break
    case "audit":
      heading = "The following agents will have their mode set to Audit:"
      break
    case "inherit":
      heading = "The following agents will now inherit their mode from their parent settings:"
      break
    default:
      heading = "The following agents will perform the action:"
    }

    return this.formattedAgentDetails(heading, actionableAgents, "text-primary")
  }

  _addFullScanWarning() {
    return `
      <p class="text-danger">
        <b>Please note: Running a full scan may take several hours and consume significant host resources. Would you like to continue?</b>
      </p>
    `
  }

  formattedAgentDetails(heading, agents, cssClass) {
    let agentListHtml = this.agentDetails(agents).map(
      agent => `<li class="padding-left ${cssClass}">Hostname: <b>${agent.hostname}</b>, OS: <b>${agent.os}</b>, Agent Version: <b>${agent.version}</b></li>`
    ).join("\n")

    return `
      <p class="${cssClass}">
        <b>${heading}</b>
        ${agentListHtml}
      </p>
    `
  }

  agentDetails(agents) {
    return agents.map( checkbox => {
      return {
        hostname: checkbox.dataset.hostname,
        os: checkbox.dataset.os,
        version: checkbox.dataset.version,
      }
    })
  }

  enableUpdateButton(modalId){
    $(`${modalId} input[type="submit"]`).prop("disabled", false)
  }

  disableUpdateButton(modalId){
    $(`${modalId} input[type="submit"]`).prop("disabled", true)
  }

  get selectedAgentsCategorized() {
    return [...this.selectedAgents].reduce((obj, current) => {
      if (current.dataset.antivirusEnforceable === "true") {
        obj.enforceable.push(current)
      } else {
        obj.unenforceable.push(current)
      }
      if (current.dataset.antivirusEnabled === "true") {
        obj.antivirusEnabled.push(current)
      } else {
        obj.antivirusDisabled.push(current)
      }
      return obj
    }, { enforceable: [], unenforceable: [], antivirusEnabled: [], antivirusDisabled: [], })
  }

  get selectedAgents() {
    return [...this.element.querySelectorAll(".bulk-select:checked")]
  }

  get selectedEnforceableIds() {
    return this.selectedAgentsCategorized.enforceable.map(selection => selection.dataset.id).join(", ")
  }

  get selectedAntivirusEnabledIds() {
    return this.selectedAgentsCategorized.antivirusEnabled.map(selection => selection.dataset.id).join(", ")
  }
}
