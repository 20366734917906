import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "value"]

  handleAuthenticationChoice(ev) {
    ev.preventDefault()
    this.valueTarget.value = $(ev.currentTarget).data("factor")
    $(this.submitTarget).submit()
  }
}
