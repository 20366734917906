import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "directModal",
    "form",
    "mapToText",
    "useExistingOrgText",
    "createOrganizationText",
    "organizationSelect",
    "organizationCreateForm",
    "microsoftOauthButtonEnabled",
    "microsoftOauthButtonDisabled",
    "organizationNameInput",
  ]

  connect() {
    this.initializeSelect2()
    this.setupFormValidation()
    this.showingOrganizationSelect = true
  }

  setupFormValidation() {
    const validationObject = (message, callback = (input) => input.value !== "") => ({
      validators: { callback: { message, callback } }
    })

    const pluginsObject = () => ({
      bootstrap3: new Bootstrap3(),
      icon: new plugins.Icon({
        valid: "glyphicon glyphicon-ok",
        invalid: "glyphicon glyphicon-remove",
        validating: "glyphicon glyphicon-refresh"
      }),
      trigger: new plugins.Trigger({ event: "input blur" })
    })

    this.organizationSelectFv = formValidation(this.formTarget, {
      plugins: pluginsObject(),
      fields: {
        "#organization_id": validationObject("Organization is required."),
      }
    })

    this.organizationCreateFv = formValidation(this.formTarget, {
      plugins: pluginsObject(),
      fields: {
        [this.organizationNameInputTarget.name]: validationObject("Organization name is required"),
      }
    })
  }

  initializeSelect2() {
    const select2 = $(this.organizationSelectTarget).select2({
      theme: "bootstrap",
      placeholder: "Select Huntress Organization",
      width: "100%",
      allowClear: true
    })
    select2.val("").trigger("change")
    select2.on("select2:select", this.toggleMicrosoftButton.bind(this, true))
    select2.on("select2:unselect", this.toggleMicrosoftButton.bind(this, false))
  }

  submitForm() {
    const callback = (result) => { if (result === "Valid") this.formTarget.submit() }
    if (this.showingOrganizationSelect) {
      this.organizationSelectFv.validate().then(callback.bind(this))
    } else {
      this.organizationCreateFv.validate().then(callback.bind(this))
    }
  }

  toggleMicrosoftButton(enable) {
    const enabledBtn = this.microsoftOauthButtonEnabledTarget
    const disabledBtn = this.microsoftOauthButtonDisabledTarget

    if (enable) {
      enabledBtn.style.display = "block"
      disabledBtn.style.display = "none"
    } else {
      enabledBtn.style.display = "none"
      disabledBtn.style.display = "block"
    }
  }

  toggleBetweenForms() {
    [
      this.useExistingOrgTextTarget,
      this.organizationSelectTarget,
      this.organizationCreateFormTarget,
      this.createOrganizationTextTarget,
      this.mapToTextTarget,
    ].forEach(target => {
      if (target.classList.contains("hidden")) {
        target.classList.remove("hidden")
      } else {
        target.classList.add("hidden")
      }
    })

    this.toggleMicrosoftButton(false)
  }

  showOrganizationSelect() {
    this.formTarget.method = "get"
    this.formTarget.action = Routes.new_account_integrations_cpv_mapping_path()
    this.showingOrganizationSelect = true

    this.initializeSelect2()
    this.toggleBetweenForms()
  }

  showOrganizationCreate() {
    this.formTarget.method = "post"
    this.formTarget.action = Routes.create_direct_tenant_organization_account_integrations_cpv_mapping_path()
    this.showingOrganizationSelect = false

    this.organizationNameInputTarget.value = ""
    $(this.organizationSelectTarget).select2("destroy")
    this.toggleBetweenForms()
  }

  inputChange() {
    this.organizationCreateFv.validate().then(result => {
      this.toggleMicrosoftButton(result === "Valid")
    })
  }
}
