import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "huntressOrganizationMapping",
    "mappedOrganizationsDatatable",
    "unmappedOrganizationsDatatable",
    "form",
    "submitButton"
  ]

  static values = {
    initialMappings: { type: Object, default: { mapped: [], unmapped: [] } },
    curriculaAccountsById: { type: Object, default: {} },
    optionsPath: String,
  }

  connect() {
    this.huntressOrganizationMappingTargets.forEach(target => {
      $(target).select2({
        placeholder: "Select an option...",
        allowClear: true,
        include_blank: true,
        theme: "bootstrap",
        width: "100%",
        ajax: {
          url: this.optionsPathValue,
          dataType: "json",
          data: this.select2AjaxData.bind(this),
          delay: 150,
        }
      })
        .on("select2:select", this.handleMappedSelect.bind(this))
        .on("select2:unselect", this.handleMappedUnselect.bind(this))
    })

    this.mappedDatatable = $(this.mappedOrganizationsDatatableTarget).dataTable({ searching: true })
    this.unmappedDatatable = $(this.unmappedOrganizationsDatatableTarget).dataTable({ searching: true })

    this.setInitialMappings()
  }

  select2AjaxData(params) {
    const mappingValues = Object.values(this.updatedMappings)
    const disabledCurriculaIds = mappingValues.map(mapping => mapping.curricula_id)

    return {
      term: params.term,
      page: params.page,
      disabled_curricula_ids: disabledCurriculaIds
    }
  }

  setInitialMappings() {
    this.initialMappings = {}
    this.updatedMappings = {}
    this.touchedOrgIds = new Set()

    const { mapped, unmapped } = this.initialMappingsValue

    const callback = organization => {
      this.initialMappings[organization.id] = Object.assign({}, organization)
      this.updatedMappings[organization.id] = Object.assign({}, organization)
    }
    mapped.forEach(callback)
    unmapped.forEach(callback)
  }

  handleMappedSelect(e) {
    const { dataset, value } = e.target

    this.updatedMappings[dataset.organizationId] = this.curriculaAccountsByIdValue[value]
    this.touchedOrgIds.add(dataset.organizationId)
    this.toggleButtonDisable()
  }

  handleMappedUnselect(e) {
    this.updatedMappings[e.target.dataset.organizationId].curricula_id = null
    this.updatedMappings[e.target.dataset.organizationId].curricula_name = null
    this.touchedOrgIds.add(e.target.dataset.organizationId)
    this.toggleButtonDisable()
  }

  toggleButtonDisable() {
    const disable = Object.keys(this.initialMappings).every(organizationId => {
      return this.initialMappings[organizationId] === this.updatedMappings[organizationId]
    })

    this.submitButtonTarget.disabled = disable
  }

  handleSubmit(e) {
    this.appendValuesToForm()
  }

  appendValuesToForm() {
    this.touchedOrgIds.forEach(orgId => {
      const curriculaId = this.updatedMappings[orgId].curricula_id
      const mapping = this.curriculaAccountsByIdValue[curriculaId]

      const curriculaIdInput = document.createElement("input")
      curriculaIdInput.type = "hidden"
      curriculaIdInput.name = `organization_mapping[${orgId}][curricula_id]`
      curriculaIdInput.value = curriculaId

      const curriculaNameInput = document.createElement("input")
      curriculaNameInput.type = "hidden"
      curriculaNameInput.name = `organization_mapping[${orgId}][curricula_name]`
      curriculaNameInput.value = mapping?.curricula_name

      const learnerCountInput = document.createElement("input")
      learnerCountInput.type = "hidden"
      learnerCountInput.name = `organization_mapping[${orgId}][active_learner_count]`
      learnerCountInput.value = mapping?.active_learner_count || null

      this.formTarget.append(curriculaIdInput)
      this.formTarget.append(curriculaNameInput)
      this.formTarget.append(learnerCountInput)
    })
  }
}
