import { Controller } from "@hotwired/stimulus"
import { initTableWithChildren } from "../../../helpers/huntressHelpers"

export default class extends Controller {
  static targets = ["datatable"]

  connect() {
    $(this.datatableTarget)
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    const options = {
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      serverSide: true,
      stateSave: true,
      stateDuration: 300,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [3, -1, 8], orderable: false }
      ],
      searching: false,
      deferRender: true,
      responsive: true,
      ajax: {
        url: huntressData.needs_review_admin_threatops_path,
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader("X-CSRF-Token", $("meta[name=\"csrf-token\"]").attr("content"))},
      }
    }

    initTableWithChildren($(this.datatableTarget), options, ".row-expand")
  }
}
