import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]

  connect() {
    this.setSubmitState()
    this.addEventListeners()
  }

  addEventListeners() {
    this.requiredFields.forEach(element => {
      ["change", "input"].forEach(event => {
        element.addEventListener(event, this.setSubmitState.bind(this))
      })
    })
  }

  setSubmitState() {
    if (this.requiredFieldsPresent) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  get requiredFields() {
    return [...this.element.querySelectorAll(".form-control.required")]
  }

  get requiredFieldsPresent() {
    return !this.requiredFields.find(field => {
      return !(field.value && field.value.length > 0)
    })
  }
}
