import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "usernameField",
    "usernameLabel",
    "passwordField",
    "passwordLabel"
  ]

  connect() {
    this.setupFormValidation()

    this.usernameFieldTarget.addEventListener("input", this.validateField.bind(this, "username"))
    this.passwordFieldTarget.addEventListener("input", this.validateField.bind(this, "password"))
  }

  setupFormValidation() {
    this.fv = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3(),
          trigger: new plugins.Trigger({
            event: "blur focus"
          })
        },
        fields: {
          username: {
            selector: '[name="integrations_autotask[username]"]',
            validators: {
              notEmpty: { message: "Autotask username is required." },
              emailAddress: { message: "Autotask username must be a valid email address." }
            }
          },
          password: {
            selector: '[name="integrations_autotask[password]"]',
            validators: {
              notEmpty: { message: "Autotask password is required." },
            }
          }
        }
      }
    )

    this.fv.on("core.field.invalid", (field) => this.toggleLabel(field, true))
    this.fv.on("core.field.valid", (field) => this.toggleLabel(field, false))
  }

  validateField(field) {
    // Trigger the validate so the `core.field.valid/invalid`
    // callback can run and update the label color
    this.fv.validateField(field)
    this.toggleButtonDisable()
  }

  toggleLabel(field, invalid) {
    // Same red used by `formValidation`
    this[`${field}LabelTarget`].style.color = invalid ? "#E74C3C" : null
  }

  toggleButtonDisable() {
    // Not running this.fv.validate here to prevent the
    // form fields from all being validated and turning red
    const allFilledOut = (
      this.usernameFieldTarget.value.length > 0 &&
      this.passwordFieldTarget.value.length > 0
    )

    this.submitButtonTarget.disabled = !allFilledOut
  }
}
