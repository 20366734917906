import { Controller } from "@hotwired/stimulus"
import { formValidation, plugins } from "formvalidation"
import Bootstrap3 from "formvalidation/plugins/Bootstrap3"

export default class extends Controller {
  static targets = ["form", "reasonSelect"]

  connect() {
    $(this.reasonSelectTarget).select2({
      theme: "bootstrap",
      placeholder: "Select reason",
      width: "100%",
      allowClear: true
    })

    this.fieldsWithErrors = new Set()
    this.formHasErrors = false
    this.formValidator = this.setupFormValidation()

    $(this.reasonSelectTarget).on("select2:select select2:clear", function () {
      let event = new Event("change", { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  get doValidationOnLiveInput() {
    return this.formHasErrors
  }

  disconnect() {
    if (this.formValidator) {
      this.formValidator.destroy()
      this.formValidator = null
    }
  }

  revalidateReason(e) {
    this.formValidator.revalidateField("reason")
  }

  setupFormValidation() {
    let validator = formValidation(
      this.formTarget,
      {
        plugins: {
          bootstrap3: new Bootstrap3({ rowValidClass: "" }),
          trigger: new plugins.Trigger({
            event: "input click blur change focus"
          }),
          submitButton: new plugins.SubmitButton(),
          defaultSubmit: new plugins.DefaultSubmit()
        },
        fields: {
          name: {
            selector: '[name="remediation_rejection[from_name]"]',
            validators: {
              notEmpty: {
                message: "First and last name required.",
              },
            }
          },

          email: {
            selector: '[name="remediation_rejection[from_email]"]',
            validators: {
              notEmpty: {
                message: "Valid contact email address required.",
              },
            }
          },

          phoneNumber: {
            selector: '[name="remediation_rejection[from_phone_number]"]',
            validators: {
              notEmpty: {
                message: "Phone number, including area code required.",
              },
            }
          },

          reason: {
            selector: '[name="remediation_rejection[reason]"]',
            validators: {
              notEmpty: { message: "Choose a reason from the drop-down list." },
            }
          },

          explanation: {
            selector: '[name="remediation_rejection[explanation]"]',
            validators: {
              notEmpty: {},
              regexp: { regexp: /\w+\s+\w+/ },
            }
          },

          feedback: {
            selector: '[name="remediation_rejection[feedback]"]',
            validators: {
              notEmpty: {},
            }
          },
        }
      }
    )
    return validator
  }
}
