import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    specifiedId: String
  }

  triggerFromId(event) {
    event.preventDefault()

    if (this.specifiedIdValue) { 
      event.target.classList.add("disabled")
      event.target.classList.add("hidden")

      this.runFromId(event.target.href, this.specifiedIdValue)
    }
  }

  runFromId(url, specifiedId) {
    fetch(url)
      .then(response => response.text())
      .then(body => {
        document.getElementById(specifiedId).outerHTML = body
      })
      .catch(error => {
        alert("Unable to load content. Please contact support if this continues")
      })
  }
}
