import {Controller} from "@hotwired/stimulus"
import formatHighlight from "json-format-highlight"


export default class extends Controller {
  static targets = ["configView"]

  connect() {
    const configView = $(this.configViewTarget)
    configView.html("<pre>" + formatHighlight(configView.text()) + "</pre>")
  }
}
