import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["categoryManagementModal", "categoryManagementModalFormContent", "categoryManagementModalLoading",
    "categoryAssignHeader", "categorySelect", "categoryFormSubmit", "updateCategoryForm", "newCategoryForm", "typeSelect",
    "severitySelect"]
  connect() {
    $("#datatable table")
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $("#datatable table").dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
      ],
      order: [[0, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.admin_antivirus_threats_path({format: "json"}),
    })

    $("#datatable table").on("click", "a.huntress-category", this.loadCategoryManagementModal.bind(this))
  }

  loadCategoryManagementModal(event) {
    //Start by popping up modal and showing the loading tab
    $(this.categoryManagementModalFormContentTarget).hide()
    $(this.categoryManagementModalLoadingTarget).show()
    //Ensure they cannot submit before the form is present
    this.categoryFormSubmitTarget.setAttribute("disabled", "disabled")
    if(event.target.dataset.categoryId){
      this.categoryAssignHeaderTarget.textContent = "Edit Assigned Huntress Category"
    } else {
      this.categoryAssignHeaderTarget.textContent = "Assign Huntress Category"
    }
    $(this.categoryManagementModalTarget).modal("show")
    //Load in the form specific to antivirus threat row selected
    $.get(Routes.edit_admin_antivirus_threat_path(event.currentTarget.dataset.id), (data) => {
      //Replace the html of the form section, this ensures if the user canceled from anther category edit, the old is replaced.
      this.categoryManagementModalFormContentTarget.innerHTML = data
      $(this.categorySelectTarget).select2({
        theme: "bootstrap",
        placeholder: "Choose A Category...",
        width: "100%",
      })
      $(this.typeSelectTarget).select2({
        theme: "bootstrap",
        placeholder: "Choose A Type...",
        width: "100%",
      })
      $(this.severitySelectTarget).select2({
        theme: "bootstrap",
        placeholder: "Choose A Severity...",
        width: "100%",
      })
      //After form loaded enable the submit button
      this.categoryFormSubmitTarget.removeAttribute("disabled")
    }).fail(() => {
      this.categoryManagementModalFormContentTarget.innerHTML = "An error occurred while loading the Antivirus Threat category."
    }).always(() => {
      //After ajax completed toggle off the loading
      $(this.categoryManagementModalFormContentTarget).show()
      $(this.categoryManagementModalLoadingTarget).hide()
    })
  }

  toggleNewCategory(event) {
    $(this.newCategoryFormTarget).show()
    $(this.updateCategoryFormTarget).hide()
    this.categoryAssignHeaderTarget.textContent = "New Huntress Category"
  }

  categoryFormSubmit(event) {
    //ensure we are only sending up one of the category builders
    if(window.getComputedStyle(this.newCategoryFormTarget).display === "none") {
      this.newCategoryFormTarget.remove()
    } else {
      this.updateCategoryFormTarget.remove()
    }
    $(this.categoryManagementModalFormContentTarget).find("form").submit()
  }
}
