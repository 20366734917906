import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "form", "label"]

  confirmResolution(event) {
    let targetData = event.target.dataset
    if (this.hasLabelTarget && targetData.modalLabel) {
      this.labelTarget.textContent = targetData.modalLabel
    }
    if (this.hasFormTarget && targetData.modalUrl) {
      this.formTarget.action = targetData.modalUrl
    }

    $(this.modalTarget).modal("show")
  }
}
