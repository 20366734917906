import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loading", "datatable", "table"]

  connect() {
    this.userEntityId = this.element.dataset.userEntityId
    $(this.tableTarget).on("init.dt", this.tableInitialized.bind(this))
    this.setupDataTable()
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }

  setupDataTable() {
    $(this.tableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [-1, -2], orderable: false }
      ],
      order: [[1, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: this.element.dataset.datatablesUrl,
    })
  }
}
