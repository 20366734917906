import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll(".dropdown-menu").forEach(menu => {
      menu.addEventListener("click", event => {
        event.stopPropagation() // keep dropdown menu open
      })
    })
  }
}
