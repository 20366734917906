import { Controller } from "@hotwired/stimulus"

const ClipboardJS = require("clipboard")

export default class extends Controller {
  static values = {
    success: { type: String, default: "Copied!"},
    error: { type: String, default: "Failed!"}
  }
  connect() {
    this.clipboard = new ClipboardJS(this.element)
      .on("success", (event) => {
        this.showTooltip(this.successValue)
      })
      .on("error", (event) => {
        this.showTooltip(this.errorValue)
      })

    this.element.dataset.placement = "bottom"
    this.element.dataset.trigger = "manual"
  }

  showTooltip(message) {
    $(this.element).attr("data-original-title", message).tooltip("show")
    setTimeout(() => { $(this.element).tooltip("hide") }, 1000)
  }
}
