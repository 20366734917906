import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "usersSelect2",
    "userSatReadinessFrame"
  ]

  static values = {
    sourcePath: String
  }

  connect() {
    const select2 = $(this.usersSelect2Target).select2({
      placeholder: "Select a user...",
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
    })

    select2.on("select2:select", () => {
      const userId = this.usersSelect2Target.value
      this.userSatReadinessFrameTarget.src = `${this.sourcePathValue}?user_id=${userId}`
      this.userSatReadinessFrameTarget.loading = "eager"
    })
  }
}
