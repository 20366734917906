import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customStartDate", "customEndDate"]

  onTimeFrameChanged(evt) {
    const {startDate, endDate} = evt.detail

    this.startDate = startDate
    this.endDate = endDate
  }

  set startDate(date) {
    // pass true to keep the time zone offset
    this.customStartDateTarget.value = date.toISOString(true)
  }

  set endDate(date) {
    // pass true to keep the time zone offset
    this.customEndDateTarget.value = date.toISOString(true)
  }
}
